.policies{
    /* grid-template-rows: 48px 500px 48px;
    gap: 8px;
    overflow: auto;
    background-color: white; */
}
.policiesHub{
    grid-template-rows: 36px calc(100% - 44px);
    gap: 8px;
    overflow: auto;
    background-color: white;
}

.policies .reportTopBarWrap{
    height:36px;
}

.policies .report {
    gap: 8px;
    grid-template-rows: 1fr 36px;
    /* overflow-y: auto; */
}

.policies .report > :first-child {
    overflow-x: auto;
    border: solid 2px #E2E2E2;
    border-radius: 5px;
    /* grid-template-rows: 50px repeat(auto-fill, 48px); */
    z-index: 0;
    padding:8px;
}

.policies .report .list{
    gap: 8px;
}

.policies .report .list .item{
    gap:4px;
}

.policies .list > .header{
    height: 48px;
}

.policies .list .columnHeader{
    grid-template-columns: repeat(6, 1fr) !important;
    position: sticky;
    top:0;
    background-color: white;
    border: solid 1px gainsboro;
    box-shadow: 0 0 8px #E2E2E2;
    border-radius: 5px;
}

.policies .list .columnHeader form, .policies .list .columnHeader form *{
    width: 100% !important;
}

.policies .list{
    color:dimgray;
}

.policies .list .columnHeaders :first-child{
    padding-left:40px;
}

.policies .list .block .header{
    grid-template-columns: 24px 32px 32px 32px 1fr auto 32px 24px;
}

.policies .list .block:not(:hover) .activityStatus{
    color:#E2E2E2;
}

.policies .list .block:not(:hover) .activityStatus.active{
    color:#42a5f5;
}


.policies .list{
    grid-auto-rows: min-content;
    background-color: #f3f4f6;
    border: solid 1px gainsboro;
}

.policies .list .block{
    background-color: white;
    border:solid 1px gainsboro;
    grid-template-rows: auto auto auto;
    color:dimgray;
}

.policies .list .block:hover{
    background-color: #e3f2fd;
    outline:solid 1px #2196f3;
    border-color:#2196f3;
    color:#2196f3;
}

.policies .list .block:hover .divider{
    background-color:#2196f3;
}

.policies .list .block .content{
    grid-template-columns: repeat(6, 1fr);
}