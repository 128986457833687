@media (min-width:769px) and (max-width:1012px) {
    .sizeMin{
        display:block;
    }

    html {
        scroll-behavior: smooth;
    }
    .loginBoard{
        grid-template-columns: 100% !important;
    }
    .splashMessage{
        display:none !important;
    }
    .splashWelcomeMessage{
        width:calc(100% - 200px) !important;
        display: inline-block !important;
        vertical-align: middle !important;
        text-align: left;
    }
    .splashMessageHeadline{
        font-size:31px !important;
        width:100% !important;
        text-align: left !important;
    }
    .splashMessageSmallAlt{
        font-size: 20px !important;
        width:100% !important;
        text-align: left !important;
    }
    .splashMessageSmall{
        font-size: 17px !important;
        width:100% !important;
        text-align: left !important;
    }
    .splashMessagMediumAlt{
        font-size:27px !important;
        width:100% !important;
        text-align: left !important;
    }
    .splashMessageMedium{
        font-size:29px !important;
        width:100% !important;
        text-align: left !important;
    }
    .splashStatusData{
        width:200px !important;
        display:inline-block !important;
        vertical-align: middle !important;
        margin: unset !important;
    }
    .statusTotalActive, .statusTotalClosed{
        margin: unset !important;
        padding: unset !important;
    }
    /* Template */

    .navBar{
        grid-template-columns: auto 1fr auto !important;  
    }

    .notificationBoard{
        width: 100% !important;
        position:fixed !important;
    }
    .notificationBoardInner.moduleBlock{
        width:95%;
    }

    .sideBarAccBtnSection{
        position: absolute !important;
        bottom: 0 !important;
        width: 170px !important;
        height: 55px !important;
    }

    .sideBar p{
        margin:auto;
    }
    .mobileAltOptions{
        display: inline-block;
        width: 55px;
        height: 55px;
        position: relative;
        color:#104735;
    }
    .mobileMoreOptions{
        z-index: 2;
        background-color: inherit;
        width: calc(100% - 90px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    /* Pages */

    /* Single Policy */

    .policiesDetailsBoard{
        width:calc(100% - 44px) !important;
    }
    .noPolicyResults{
        top:108px !important;
        left:8px !important;
        right:8px !important;
    }
    .policyResultsData{
        display: none !important;
    }
    .policiesFiltersModule{
        height:calc(100vh - 190px) !important;
        width: 95% !important;
        position:fixed !important;
        z-index: 4 !important;
        top:10px !important;
        bottom: 0 !important;
    }
    .policiesFiltersModuleInner{
        overflow:auto !important;
    }
    .policiesFiltersToggleSection{
        width:calc(100% - 10px) !important;
    }
    .singlePolicyTimelineTrackerInnerWrap{
        margin-bottom:10px;
    }
    .activityTimelineHeaderBtn{
        outline: none !important;
    }
    .listAltercationOption{
        outline: none !important;
    }
    .singlePolicyCurrentActivity{
        width:100% !important;
    }
    .policiesDetailsBlock .listBar{
        display: none !important;
    }
    .policyDetailsCardFaceInner{
        height:auto !important;
        overflow: hidden !important;
    }
    .singlePolicyTimelineTracker{
        position: relative !important;
    }
    .closingTabSection, .closingTabSectionHeader{
        width:calc(100% - 24px) !important;
    }
    .closingTabSectionHeader{
        padding-left:5px !important;
    }
    .bidPanel{
        min-height: calc(100vh - 238px) !important;
    }
    .policiesFiltersRangeSliderSection{
        width: calc(100% - 10px) !important;
        left: 5px !important;
    }
    .policyRowInner{
        position:relative;
        width:100%;
        height:100%;
        text-align: center;
        display: grid;
    }
    .listAltercations{
        right:5px !important;
        width:100% !important;
    }
    .activityTimelineHeader{
        width:100% !important;
        left:0 !important;
    }
    .requestCounterPrompt{
        float:unset !important;
        text-align: center !important;
    }

    .statusDetailBlock{
        position:fixed !important;
        width:90% !important;
        height: auto !important;
    }
    .statusDetailInnerWrap{
        position:relative !important;
    }
    .bidPanelDetailValue.date{
        font-size:14px;
        font-weight: 100;
    }
    .bidPanelDownloadBtn{
        position:relative !important;
        bottom: unset !important;
        right: unset !important;
        margin:2px auto !important;
    }
    .statusDefinition{
        display:block !important;
        position:relative !important;
        height:auto !important;
    }
    .statusDefinitionTitle{
        top: 0 !important;
        font-size: 18px !important;
    }
    .statusBodyIcon svg{
        width:75px !important;
        height:75px !important;
    }
    .emptyRequestsMessage{
        margin-top:10px;
        padding:10px;
    }
    .emptyRequests{
        min-height: calc(100vh - 440px) !important;
    }

    .timeStampSeparator{
        margin-bottom:20px !important;
    }
    .summaryFunderStatement{
        grid-template-rows: 50% 50%;
        grid-template-columns: 100% !important;
        row-gap: 8px;
    }

    /* Home Page */

    .homePageTotalBreakdown{
        transition: 0s !important;
    }
    .overViewDataTotalValOption{
        visibility: hidden !important;
    }
    .totalBreakdownRow{
        grid-template-columns: calc(100% / 2 - 2px) 0 calc(100% / 2 - 2px) !important;
    }

    .missedFollowUp svg{
        display: none !important;
    }
    .missedFollowUpCount span{
        display:none;
    }
    .followUpRemainingTime svg{
        display:none !important;
    }

    /* Leads */
    .leadBlockColumn:not(.closed){
        display:inline-block !important;
    }

}

