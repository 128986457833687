.navBar{
    position:relative;
    text-align: left;
    height:100%;
    grid-template-columns: 1fr repeat(2, auto);
}
  
nav{
    width:100%;
    height:100%;
    text-align:center;
}

.navPathHandler{
    grid-template-columns: 90px auto;
    overflow: hidden;
}
.navHardButtons{
    /* grid-template-columns: repeat(2, 90px); */
    grid-template-columns: 90px;
}
.mainHeader {
    font-weight: bold;
    font-size: 300%;
}
  
.logo{
    background-color:white;
    width:100%;
    height:100%;
    overflow:hidden;
    color:dimgray;
    z-index: 1;
}
.logo img{
    width: auto;
    height: 100%;
}

.notificationsBtn.inactive{
    position:relative;
    color: dimgray;
    z-index: 3;
}

.notificationsBtn.inactive:hover{
    color: #d2ab66;
}


.notificationsBtn.active{
    position:relative;
    color: #d2ab66;
    z-index: 3;
}

.notificationsBtn svg{
    width:36px;
    height:36px;
    border-radius:50%;
    cursor:pointer;
}

.currentPageHandler{
    width: 100%;
    height: 100%;
    text-align: left;
}

.abacusHeader{
    color: #104735;
    vertical-align: middle;
    font-weight: 100;
    font-size:24px;
    text-align: center;
}
.abacusHeader:hover{
    cursor:default;
}
.currentPageHandlerPath{
    color: #104735;
    border-radius:5px;
    font-weight: 700;
    font-size:24px;
    text-align: center;
}
.currentPageHandlerPath:first-of-type{
    font-weight: 100;
}
.currentPageHandlerPath:hover{
    background-color:#EFEFEF;
    color:gray;
    cursor:default;
}
.navBar .notificationBubble{
    display:block;
    position:absolute;
    width:20px;
    height:20px;
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    color:white;
    background-color:#f44336;
    border-radius: 50%;
    bottom:8px;
    right:8px;
    overflow:hidden;
}
.notificationBubbleCount{
    font-size:12px;
    position: relative;
    width:20px;
    height:20px;
}
.notificationBubbleCountInner{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height:20px;
    width:20px;
    line-height: 20px;
}
.notificationsBtnInner svg:hover{
    color:#E8C666;
}

.pageSelection{
    height:100%;
    width:100%;
}

.navBar .path{
    grid-template-columns: repeat(3, auto);
    gap: 8px;
}

.expandedPages{
    width: 300px;
    height: auto;
    z-index: 4;
    grid-template-rows: 1fr 2px auto;
    gap: 8px;
}

.expandedPages .sideBarBtn{
    grid-template-rows: unset;
    grid-template-columns: 64px 1fr;
}

.logout > div:hover{
    color:#f44336 !important;
}

.pages{
    grid-template-rows: auto;
    grid-template-columns: 100%;
}