.filtersBoard{
    width:50%;
    height:90%;
    background-color: white;
    grid-template-rows: repeat(2, 48px) 1fr 48px;
  }
  
  .filtersBoard .header{
    grid-template-columns: 48px 1fr 48px;
    color:dimgray;
  }
  
  .filtersBoard .body{
    overflow-y: auto;
    gap: 8px;
  }
  
  .filtersBoard .actionBar{
    grid-template-columns: auto auto 1fr;
    color:dimgray;
  }
  
  .filtersBoard .actionBar > .checkBox{
    grid-template-columns: 1fr 36px;
    gap:8px;
  }
  .filtersBoard .category{
    background-color: white;
    border:solid 1px gainsboro;
    border-radius: 5px;
  }
  
  .filtersBoard .category.range{
    grid-template-rows: 36px auto;
    gap: 8px;
    color: dimgray;
  }
  
  .filtersBoard .category .alters{
    grid-template-columns: auto 1fr auto 36px;
    color:dimgray;
  }
  .filtersBoard .sortDisplay{
    grid-template-columns: auto 36px;
    font-weight: 500;
    color:dimgray;
  }
  .filtersBoard .sortDisplay:hover{
    color:gray;
    background-color: #E2E2E2;
  }
  
  .filtersBoard .rangeHeader{
    grid-template-columns: 36px auto 1fr auto 36px;
  }
  .filtersBoard .rangeHeader .inactive, .filtersBoard .alters .inactive{
    color:#E2E2E2;
  }
  .filtersBoard .section{
    border-radius: 5px;
    background-color: #F3F4F6;
    overflow-y: auto;
    border: solid 1px gainsboro;
    height: 100%;
    gap: 8px;
  }
  
  .filtersBoard .section .body{
    height: min-content;
    width: 100%;
    overflow: auto;
    display: grid;
    gap: 8px;
  }