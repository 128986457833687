.filtersHub{
    border: solid 2px #E2E2E2;
    grid-auto-rows: min-content;
    background-color: #f3f4f6;
}

.filtersHub * {
    transition: ease .125s;
}

.filtersHub .category{
    grid-template-columns: auto 1fr;
}

.filtersHub .category .vDivider{
    height: calc(100% - 16px);
}
.filtersHub .category > div > .vDivider{
    background-color:#0d47a1;
}

.filtersHub .block{
    border:solid 1px #E2E2E2;
    background-color: #EFEFEF;
}

.filtersHub .block > .header{
    height: 48px;
    grid-template-columns: 1fr 48px;
    background-color:white;
}

.filtersHub .block > .header .dataPoint{
    background-color: #e1f5fe;
    color:#0d47a1;
    border:2px solid #42a5f5;
    padding:4px;
}
.filtersHub .block > .header:hover .dataPoint{
    background-color: #0d47a1;
    color:#e1f5fe;
    border:2px solid #0d47a1;
    padding:4px;
}

.filtersHub .group{
    grid-template-rows: 48px;
    background-color: white;
    border: solid 2px #E2E2E2;
}

.filtersHub .group > .header{
    grid-template-columns: 24px 24px 1fr 24px 24px;
}


.filtersHub .group > .header > .activeStatus.all{
    color:#4caf50;
}
.filtersHub .group > .header > .activeStatus.none{
    color:#f44336;
}
.filtersHub .group > .header > .activeStatus.partial{
    color:#ffc107;
}

.filtersHub .group .list{
    grid-template-columns: 24px 1fr;
}

.filtersHub .group .list .item{
    grid-template-columns: 24px 1fr;
    color:dimgray;
}

.filtersHub .group .list .item.off{
    color:#E2E2E2;
}

.filtersHub .group .list .item:hover{
    background-color: #EFEFEF;
    color:gray;
}

.filtersHub .group .list .item.on .activeStatus{
    color:#4caf50;
}
.filtersHub .group .list .item.off .activeStatus svg{
    color:#E2E2E2;
}

.filtersHub .group .list .item.on:hover svg{
    color:#E2E2E2;
}

.filtersHub .group .list .item.off:hover .activeStatus svg{
    color:inherit;
}