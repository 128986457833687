.pieSlice{
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
    height: 100%;
    position: absolute;
    width: 100%;
    pointer-events:none;  
}
.sliceProgress{
    border-radius: 50%;
    clip: rect(0px, 100px, 200px, 0px);
    height: 100%;
    position: absolute;
    width: 100%;
    font-family: monospace;
    font-size: 1.5rem;
    pointer-events: auto;
}

.small .pieSlice{
    clip:rect(0px, 150px, 150px, 75px);
}
.small .sliceProgress{
    clip:rect(0px, 75px, 200px, 0px);
}

.pieTotalCountContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: #F3F4F6;
    border-radius: 50%;
    pointer-events:none;
}
.pieTotalCountWrap{
    position:relative;
    width:100%;
    height:100%;
}

.pieTotalCount{
    position: absolute;
    margin: auto;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#F3F4F6;
    font-size:24px;
    text-align: center;
    z-index: 2;
    background-color: white;
    width:80%;
    height:80%;
    border-radius: 50%;
    box-shadow: 0 0 5px #ccc;
}

.small * .pieTotalCount{
    width:90%;
    height:90%;
}

.pieTotalCount div{
    position: relative;
    width:100%;
    height:100%;
}
.pieTotalCount div .pieCountNumber{
    position: absolute;
    top:calc(50% - 6px);
    left:50%;
    transform: translate(-50%, calc(-50% - 6px));
    width:100%;
    height:36px;
    line-height: 36px;
    text-align: center;
    color:dimgray;
    font-size:36px;
    font-weight: 700;
}
.pieTotalCount div .pieCountMessage{
    position: absolute;
    top: calc(50% + 10px);
    left: 50%;
    transform: translate(-50%, calc(-50% + 18px));
    width: 80%;
    height: auto;
    text-align: center;
    color: gray;
    font-size: 14px;
    font-weight: 100;
}

.pieChartSummary{
    background-color: #F3F4F6;
    border: solid 1px gainsboro;
    grid-template-columns: repeat(3, 1fr);
}

.pieChartSummary.small{
    max-height: 160px;
    height: auto;
    display: inline-block;
    width: calc(100% - 30px);
    position: absolute;
    right: 15px;
    left: auto;
    top: 42px;
    vertical-align: middle;
    transform: none;
    z-index: 3;
    margin-top: 3px;
    background-color: transparent;
    box-shadow: none;
}

.pieChartSummary.large{
    height:160px;
}

.summaryInfoBlock{
    grid-template-rows: 1fr 2px 1fr;
}

.summaryInfoBlockInner{
    position: relative;
    width:100%;
    height:100%;
}

.summaryInfoTop{
    grid-template-columns: 32px 1fr 32px;
}
.summaryInfoBlockIdentifier{
    width:16px;
    height:16px;
    border-radius: 50%;
}
.summaryInfoBlockBubble{
    border-radius: 50%;
    height:32px;
    width:32px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.summaryInfoBlockBubbleInner{
    position: relative;
    width:100%;
    height:100%;
}
.summaryInfoBlockBubbleInner svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.summaryInfoBlockName{
    font-size: 14px;
    color: gray;
    text-overflow: ellipsis;
    grid-template-rows: repeat(2, 1fr);
}
.summaryInfoBlockPercentage{
    font-size: 20px;
    color: dimgray;
    font-weight: 700;
}

.chartData{
    width:100%;
    height: auto;
    min-height:100%;
    grid-template-rows: 1fr 96px;
}

.pieSummarySection{
    overflow:hidden;
}
.pieSummarySection.small{
    width: 50%;
    vertical-align: middle;
    height: 100%;
    display: inline-block;
    position:relative;
    top:0;
}

.summarySectionTitle{
    position:absolute;
    top:32px;
    left:50%;
    transform: translate(-50%, 0);
    color:gray;
}
.pieTotalCountContainer * ::first-letter{
    text-transform:capitalize;
}