.uploadPage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}
.uploadOverview{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    position:relative;
}

.uploadModule{
    display: inline-block;
    width: 100%;
    height: calc(100% - 46px);
    background-color: inherit;
    position: relative;
    overflow: hidden;
    text-align: left;
    padding: 15px;
    overflow:auto;
}

.uploadWrapper{
    height:100%;
    width:100%;
}

.newPolicyDetails{
    display: inline-block;
    width: 100%;
    height: auto;
    min-height: 100%;
    vertical-align: top;
    padding-bottom: 1px;
}

.documentsUploadBlock{
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    left:0;
    width: auto;
    height: auto;
    vertical-align: top;
}
.documentsUploadBlock iframe, .documentPrompt{
    width:100%;
    height:100%;
    border:none;
    position:relative;
}

.documentPromptBtn{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #EFEFEF;
    box-shadow: inset 0 0 0 2px #efefef;
    color: gray;
    border-radius: 20px;
    width: 150px !important;
    height: 32px;
    line-height: 32px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}
.documentPromptBtn.active{
    color: #00BFFF;
    background-color: white;
    box-shadow: inset 0 0 0 2px #00bfff;
}
.documentPromptBtn.active:hover{
    cursor:pointer;
    background-color:#00bfff;
    color:white;
}

.saveUploadData{
    background-color: #EFEFEF;
    box-shadow: inset 0 0 0 2px #efefef;
    color: gray;
    border-radius: 5px;
    width: 150px !important;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 24px;
    width: 47.5%;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    position:sticky;
    left:calc(100% - 15px);
    right:15px;
    bottom:15px;
}
.saveUploadData.active{
    color:#00BFFF;
    background-color:white;
    box-shadow:inset 0 0 0 2px #00bfff;
}
.saveUploadData.active:hover{
    color:white !important;
    background-color:#00BFFF !important;
    box-shadow:inset 0 0 0 2px #00bfff;
    cursor:pointer;
}
.saveUploadData.active::after{
    content:'';
    display:none;
}
.saveUploadData::after{
    content:"Required fields remain*";
    position:absolute;
    top:calc(100% + 4px);
    left:50%;
    transform: translate(-50%, 0);
    width:calc(100% + 8px);
    height:16px;
    line-height:16px;
    font-size:14px;
    color:#EF5350;
}
.formBtn{
    height:32px;
    line-height:26px;
    border:2px solid #00bfff;
    background-color:inherit;
    border-radius: 5px;
    vertical-align: middle;
    color:#00bfff;
    font-weight: 500;
    text-align: center;
    cursor:pointer;
    position: relative;
}
.formBtn.error{
    color:#EF5350;
    border-color: #EF5350;
}
.formBtn svg{
    position:absolute;
    right:8px;
    top:50%;
    transform: translate(-50%, -50%);
}
.formBtn:hover{
    background-color:#00bfff;
    color:white;
}
.formBtn.error:hover{
    background-color: #EF5350;
    color:white;
}
.uploadModuleBoard{
    z-index: 999;
    position: absolute;
    text-align: center;
    right: 0;
    top: auto;
    width: 100%;
    display: inline-block;
    padding: 1.25%;
    height: 100%;
}
.uploadLeadsListSearchContainer{
    display: inline-flex;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    margin-bottom:8px;
}
.uploadLeadsListSearchBar{
    height: 32px;
    border-radius: 5px;
    border-style: none;
    background-color: #F3F4F6;
    padding: 8px;
    width: calc(100% - 16px);
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
    left: 0;
    border: solid 1px gainsboro;
}
.uploadLeadsListSearchBar:focus{
    outline-color: gray !important;
}
.uploadLeadsListContainer{
    background-color: #f3f4f6;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    color: gray;
    display: inline-block;
    overflow: auto;
    position: relative;
    width: calc(100% - 16px);
    margin: auto;
    overflow: hidden;
    min-height:400px;
}
.uploadLeadsList{
    height:auto;
    width:100%;
    max-height: 500px;
    overflow-y: auto;
}

.selectedLeadInfo{
    float: left;
    height: 36px;
    width: auto;
    max-width:calc(2 / 3);
    line-height: 36px;
    margin: 8px;
    margin-top: 8px !important;
    text-align: center;
    font-weight: 500;
    color: dimgray;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    border-bottom: solid 2px gray;
}

.success-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e8f5e9;
    color: #4caf50;
    outline: solid 2px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
    z-index: 1;
}

  
  .success-message.show {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%);
  }
  
  .success-message.hide {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) translateY(-100%);
  }

  .uploadPage .singlePolicyBlockOptionBar{
    width:50%;
    margin:auto;
  }

  .uploadPage .singlePolicyBlockOptionBar .switchViewBtnOption{
    max-width: unset;
  }