.caseOptions{
    width: 50%;
    height: 50%;
    background-color: white;
    grid-template-rows: 48px 1fr 48px;
}

.caseOptions .header{
    grid-template-columns: 48px 1fr 48px;
    color: dimgray;
}

.caseOptions .body{
    grid-template-columns: minmax(120px, auto) 1fr;
}

.caseOptions .actionBar{
    grid-template-columns: auto repeat(3, 1fr);
    color: dimgray;
}
.caseOptions .optionsNav{
    grid-template-rows: repeat(auto-fill, 32px);
}
.caseOptions .optionHeader{
    color:dimgray;
}

.caseOptions .content .list{
    grid-template-rows: 32px 1fr;
}

.caseOptions .content .listOptions{
    background-color: #f3f4f6;
    color: gray;
    overflow: auto;
    position: relative;
    border: solid 1px gainsboro;
}

.caseOptions .content .listOptions .rows{
    height: fit-content;
    gap:8px;
}
.caseOptions .content .listOptions .row{
    display: inline-grid;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    border: solid 2px #E2E2E2;
    grid-template-columns: repeat(2, 1fr) 50px;
    color: dimgray;
    background-color:#E2E2E2;
}

.caseOptions .content .listOptions .row:hover{
    background-color: white;
    color:gray;
}

.caseOptions .content .listOptions .row.active{
    background-color: white;
}

.caseOptions .content .listOptions .row.active:hover{
    background-color: #E2E2E2;
    color:gray;
}

.caseOptions .content .listOptions .row.active svg{
    color:#66bb6a;
}

.caseOptions .btn:not(.loading){
    grid-template-columns: 1fr 32px;
}