.bidsOverview{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    grid-template-rows: 48px 1fr;
}
.bidsOverview.loading{
    grid-template-rows: unset;
}
.bidsPage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}

.bidCenterPage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}

.bidCenterOverview{
    grid-template-rows: 72px 1fr;
    row-gap: 8px;
}

.bidCenterOverview .moduleBlock{
    background-color: white;
    overflow: hidden;
}

.bidsList{
    grid-auto-flow: row;
    grid-template-columns: repeat(3, calc((100% / 3) - 6px));
    grid-auto-rows: auto;
    gap: 8px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }

.bidListing{
    background-color: white;
    height:210px;
}

.bidsSearchBar{
    height: 32px;
    border-radius: 5px;
    border-style: none;
    background-color: #F3F4F6;
    padding: 8px;
    width: 100%;
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
    left: 0;
    border: solid 1px gainsboro;
}
/* 
.bidBlock{
    width:calc(100% - 36px);
    height: auto;
    background: white;
    border-radius: 5px;
    margin:auto;
    vertical-align: middle;
    border: solid 1px gainsboro;
    position: relative;
    padding:5px;
    font-weight: 500;
    text-align: center;
    color:gray;
    grid-template-rows: 24px 8px 24px 24px 24px 24px 24px;
    grid-template-columns: 100%;
    overflow: hidden;
    gap:4px;
} */

.bidBlock .data{
    grid-template-columns: 32px calc(100% - 32px);
}

.bidBlockColumn{
    padding: 16px 0;
    gap:16px;
    height: fit-content;
    width: calc(100% - 8px);
    display: inline-grid;
    border-radius: 5px;
    margin: auto;
    margin-top: 8px;
    overflow: overlay;
    overflow-x: hidden;
    max-height: calc(100% - 20px);
}

.kanbanBlock .leadInfoBar{
    grid-template-columns: 32px 1fr auto;
}

.kanbanBlock .leadInfoBar > div > div{
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

/* .kanbanBlock .data > div{
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.bidBlockColumn.newCase{
    outline: 2px solid #c8e6c9;
}
.bidBlockColumn.underReview{
    outline: 2px dashed #b3e5fc;
}
.bidBlockColumn.bidding{
    outline: 2px dashed #f3e5f5;
}
.bidBlockColumn.closed{
    outline: 2px solid #e2e2e2;
}

.newCase .kanbanBlock, .newCase.kanbanBlock{
    border:solid 2px #2e7d32;
    background-color:white;
}
.underReview .kanbanBlock{
    border: solid 2px #0277bd;
    background-color: white;
}
.bidding .kanbanBlock{
    border: solid 2px #6a1b9a;
    background-color: white;
}
.bidding .kanbanBlock.winning{
    border: solid 2px #6a1b9a;
    background-color: #e1bee7;
    color:#6a1b9a;
}

.kanbanBlock.result{
    width:100%;
    height:auto;
}
.newCase.result{
    border:solid 2px #2e7d32;
    background-color:white;
}
.underReview.result{
    border: solid 2px #0277bd;
    background-color: white; 
}
.bidding.result{
    border: solid 2px #6a1b9a;
    background-color: white;
}
.closed.result{
    border:solid 2px #ef5350;
    background-color: white;
}
.closedWon.result{
    border:solid 2px #004d40;
    background-color: white;
    color:gray;
}
.closedInContracts.result{
    border: solid 2px #ff9800;
    background-color: white;
}
.closedWon.result:hover{
    border:solid 2px #004d40;
    background-color: #e0f2f1;
}
.closedInContracts.result:hover{
    border: solid 2px #ff9800;
    background-color:#fff3e0;
}
.declined.result{
    border:solid 2px #ef5350;
    background-color: white;
}

.newCase .kanbanBlock:hover{
    background-color: #e8f5e9;
}
.underReview .kanbanBlock:hover{
    background-color: #e3f2fd;
}
.bidding .kanbanBlock:hover{
    background-color: #f3e5f5;
}

.newCase .emptyKanbanMessage{
    color:#2e7d32;
}
.underReview .emptyKanbanMessage{
    color: #01579b;
}
.bidding .emptyKanbanMessage{
    color: #6a1b9a;
}

.newCase .divider{
    background-color:#2e7d32;
}
.underReview .divider{
    background-color:#01579b;
}
.bidding .divider{
    background-color:#6a1b9a;
}
.closedWon .divider{
    background-color:#004d40;
}

.kanbanBlock *{
    text-overflow: ellipsis;
    white-space: nowrap;
}

.kanbanBlock.closedWon:not(.result){
    background-color: #e0f2f1;
    border: 2px solid #004d40;
    color: #004d40;
    grid-template-rows: repeat(2, 1fr);
    width:calc(100% - 32px);
}

.kanbanBlock.closedInContracts:not(.result){
    border: solid 2px #ff9800;
    background-color: #fff3e0;
    color: #ff9800;
    grid-template-rows: repeat(2, 1fr);
    width:calc(100% - 32px);
}


.kanbanBlock.closedLost:not(.result){
    background-color: #ffebee;
    border: 2px solid #ef5350;
    color: #ef5350;
    grid-template-rows: repeat(2, 1fr);
    width:calc(100% - 32px);
    gap:8px;
}

.kanbanBlock.closedLost:not(.result) .vDivider{
    background-color: #ef5350;
} */

.kanbanBlock.closedLost:not(.result) .subGroups{
    grid-template-columns: 16px 1fr 16px;
    gap:8px;
}

.kanbanBlock.closedLost:not(.result) .container{
    gap: 8px;
}

.kanbanBlock.closedLost:not(.result) .subGroup svg{
    color:#ef5350;
}

.kanbanBlock.closedLost:not(.result) .subGroup{
    grid-template-columns: 24px 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap:8px;
    /* border: 2px solid #ef5350; */
    color:#ef5350;
}

.kanbanBlock.closedLost:not(.result):hover .subGroup svg{
    color:gray;
}

.kanbanBlock.closedLost:not(.result):hover .subGroup{
    color:gray;
}

.kanbanBlock.closedLost:not(.result):hover .vDivider, .kanbanBlock.closedLost:not(.result):hover .divider{
    background-color: gray;
}

.kanbanBlock.closedWon:not(.result):hover, .kanbanBlock.declined:not(.result):hover, .kanbanBlock.closedLost:not(.result):hover,  .kanbanBlock.closedInContracts:not(.result):hover{
    background-color:white;
    color:gray;
    border-color:gray;
}

.bidsPage .policyDetailsCardTitle{
    height:auto;
    min-height: unset;
}

.LEWrap{
    background-color: #f3f4f6;
    color: gray;
    border: solid 1px gainsboro;
}

.LETable{
    grid-template-rows: 36px 2px repeat(auto-fill, 48px);
    gap:8px;
}

.LETableRow{
    grid-template-columns: repeat(4, 1fr);
    background-color: white;
    height:auto;
    border: solid 2px gainsboro;
}
.LETableRow *{
    width:100%;
    height:100%;
}
.LETableRow .data:first-of-type{
    grid-template-columns: 32px 1fr;
}

.bidSummary{
    color:gray;
}

.bidSummary .list{
    grid-template-columns: repeat(2, calc(100% / 2));
}

.bidTimelineActionBar{
    grid-template-columns: 1fr 2px repeat(3, 1fr);
    min-height:48px;
    width: 100%;
    gap:8px;
}

.bidTimelineActionBar .btn{
    outline:solid 2px #00BFFF;
    color:#00BFFF;
    font-weight:500;
    background-color: white;
    grid-template-columns: 1fr 32px;
    height: 100%;
    width:100%;
}

.bidTimelineActionBar .btn.loading{
    grid-template-columns: 100%;
    background-color: #00BFFF !important;
}

.bidTimelineActionBar .btn.loading span{
    height:32px !important;
    width: 32px !important;
    color:white !important;
}

.bidTimelineActionBar .btn.loading:hover{
    background-color: white !important;
}

.bidTimelineActionBar .btn.loading:hover * {
    color:#00BFFF !important;
}

.bidTimelineActionBar .vDivider{
    background-color: dimgray;
}

.singleTimeline .bidTimelineActionBar .btn:nth-child(3), .wrap .bidTimelineActionBar .btn:nth-child(3){
    outline-color:#01579b;
    color: #01579b;
}
.singleTimeline .bidTimelineActionBar .btn:nth-child(4),
.wrap .bidTimelineActionBar .btn:nth-child(4){
    outline-color:#6a1b9a;
    color: #6a1b9a;
}
.singleTimeline .bidTimelineActionBar .btn:nth-child(5), .wrap .bidTimelineActionBar .btn:nth-child(5){
    outline-color:#ef5350;
    color: #ef5350;
}

.wrap .bidTimelineActionBar .btn:nth-child(1):hover,
.bidTimelineActionBar .btn.selected:nth-child(1),
.singleTimeline .bidTimelineActionBar .btn:nth-child(1):hover{
    background-color: #e3f2fd;
}

.wrap .bidTimelineActionBar .btn:nth-child(3):hover,
.bidTimelineActionBar .btn.selected:nth-child(3),
.singleTimeline .bidTimelineActionBar .btn:nth-child(3):hover{
    background-color: #e3f2fd;
}
.wrap .bidTimelineActionBar .btn:nth-child(4):hover,
.bidTimelineActionBar .btn.selected:nth-child(4),
.singleTimeline .bidTimelineActionBar .btn:nth-child(4):hover{
    background-color: #f3e5f5;
}
.wrap .bidTimelineActionBar .btn:nth-child(5):hover,
.bidTimelineActionBar .btn.selected:nth-child(5),
.singleTimeline .bidTimelineActionBar .btn:nth-child(5):hover,
.statusAlterBlock .declined .btn:hover,
.statusAlterBlock .declined .btn.selected{
    background-color: #ffebee;
}

.bidAlterPanel{
    z-index: 999;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 50%;
    min-height: 50%;
    background-color: white;
    transform:translate(-50%, -50%);
    max-height:80%;
    grid-template-rows: 48px 1fr 48px;
}
.bidAlterPanel .header{
    color:dimgray;
    font-weight: 500;
    grid-template-columns: 48px 1fr 48px;
    width:100%;
    height: 100%;
}
.bidAlterPanel .header .btn{
    border-radius: 50%;
    height: 36px;
    width: 36px;
}
.bidAlterPanel .header .btn:hover{
    color:dimgray;
    background-color: whitesmoke;
}

.bidAlterPanel .wrap{
    grid-template-rows: 48px 1fr;
}

.bidAlterPanel .statusAlterBlock{
    box-sizing: border-box;
}

.statusAlterBlock.split{
    grid-template-columns: repeat(2, 1fr);
}

.statusAlterBlock.split .contentBox{
    width:calc(50% - 16px);
    right: 8px;
}

.statusAlterBlock .underReview{
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    color:gray;
}
.statusAlterBlock .underReview .content{
    grid-template-rows: 84px 1fr;
}
.statusAlterBlock .underReview .icon{
    background-color:#e3f2fd;
    border-radius: 50%;
    border:solid 2px #01579b;
}
.statusAlterBlock .underReview .icon svg{
    color:#01579b;
    font-size: 64px;
}

.statusAlterBlock .bidding{
    grid-template-rows: 48px 48px 48px 1fr;
    gap: 8px;
}

/* .statusAlterBlock .bidding input{
    font-weight: 600;
    color:dimgray;
    font-size: 24px;
} */
.statusAlterBlock .bidding .inputField .fieldName{
    font-weight: 600;
    color:gray;
}

.statusAlterBlock .bidding .biddingIcon svg{
    color:#6a1b9a;
    font-size: 56px;
    background-color:#f3e5f5;
    padding:8px;
    border-radius: 50%;
    outline:#6a1b9a solid 2px;
}

.statusAlterBlock .declined{
    grid-template-rows: 1fr 2px repeat(4, 1fr);
    font-weight: 500;
    color:gray;
}

.statusAlterBlock .declined :first-child{
    grid-template-columns: repeat(3, calc(100% / 3))
}

.statusAlterBlock .declined .btn{
    outline:solid 2px #ef5350;
    color:#ef5350;
    font-weight:500;
    background-color: white;
}

/* .kanbanBlock.result.newCase:hover{
    background-color: #e8f5e9;
}
.kanbanBlock.result.underReview:hover{
    background-color: #e3f2fd;
}
.kanbanBlock.result.bidding:hover{
    background-color: #f3e5f5;
}
.kanbanBlock.result.closed:hover{
    background-color: #ffebee;
} */
