.policiesPage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}

.policiesDetailsBlockTitle .header{
    grid-template-columns: 120px 1fr 120px;
}
.policiesDetails{
    position: relative;
    width: 100%;
    height:100%;
    text-align: center;
}

.policiesDetailsBlock{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    position: relative;
    overflow:hidden;
    grid-template-rows: 48px calc(100% - 48px);
}

.policiesOverview{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    position: relative;
    overflow:hidden;
    grid-template-rows: 108px calc(100% - 148px) 40px;
}

.mobile .policiesOverview{
    height:auto;
}

.policiesDetailsBoardWrap{
    background-color: #F3F4F6;
    text-align: center;
    border-radius: 5px;
    overflow-y: auto;
    border: solid 1px gainsboro;
}
.singlePolicysDetailsBoardWrap{
    overflow: overlay;
    position: relative;
    background-color: inherit;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 100%;
}

.policyResultsData{
    height: 40px;
    width: 95%;
    text-align: center;
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 2.5%;
    background-color: inherit;
}

.noPolicyResults{
    background-color: #F3F4F6;
    border-radius: 5px;
    position: absolute;
    left: 44px;
    right: 44px;
    bottom: 6px;
    top: 2px;
    box-shadow: 0 0 5px #ccc;
}

.noPolicyResultsMessage{
    width: auto;
    height: auto;
    max-height: 46px;
    min-width: 100%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 100%;
    color:gray;
}

.policiesSearchBar{
    height: 32px;
    border-radius: 5px;
    border-style: none;
    background-color: #F3F4F6;
    padding: 8px;
    width: 100%;
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
    left: 0;
    border: solid 1px gainsboro;
}
.policiesSearchBar:focus{
    outline-color:gray;
}
.policiesSearchBarWrapper{
    height: 32px;
    border-radius: 20px;
    border-style: none;
    background-color: #F3F4F6;
    width: 80%;
    display: block;
}

.policiesSearchBarInnerWrap{
    width:100%;
    height:100%;
    position: relative;
}

.policiesSearchBarFilters{
    z-index: 2;
    width: auto;
    position: absolute;
    top: 5px;
    right: 0;
    text-align: center;
}

.policiesFilteredSearchBlock{
    width: auto;
    height: calc(100% - 10px);
    display: inline-block;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #00bfff;
    color: white;
    font-weight: 100;
    line-height: 22px;
    padding: 0 10px;
    text-align: center;
}

.policiesFilteredSearchBlock:hover{
    cursor:pointer;
}

.policiesFilterSearchBlockString{
    width: auto;
    height: 100%;
    float: left;
    line-height: 22px;
    font-weight: 500;
}
.resetSearchBarFilterBtn{
    width: 22px;
    height: 22px;
    float:right;
}

.policiesAnalytics{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 5px;
    font-weight: 100;
    text-align: left;
    line-height: 18px;
    font-size: 14px;
    border-radius: 44px;
    color: gray;
    text-align: center;
}
.policyActive{
    position: absolute;
    top: 50%;
    right: 44px;
    transform: translate(0, -50%);
    min-width: 200px;
    padding: 5px;
    font-weight: 100;
    text-align: left;
    line-height: 18px;
    font-size: 14px;
    border-radius: 44px;
    color: gray;
}
.policyActive span:first-of-type{
    float:left;
}
.policyActive span:nth-of-type(2){
    margin: auto 10px;
}
.policyActive span:nth-of-type(3){
    float:right;
}

.policiesFiltersModule{
    background-color: white;
    width: 50%;
    height:75%;
    z-index: 3;
    position: absolute;
    margin: auto;
    display: block;
    bottom: 0px;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    text-align: center;
    overflow:hidden;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}

.policiesFiltersModuleInner{
    width:100%;
    height:100%;
    overflow:overlay;
    grid-template-rows: 48px calc(100% - 96px) 48px;
}

.policiesFiltersHeaderWrap{
    height:48px;
    width:100%;
    position:sticky;
    top:0;
    background-color: white;
}
.policiesFiltersHeader{
    width:calc(100% - 144px);
    height:48px;
    z-index: 3;
    text-align: center;
    line-height: 46px;
    grid-template-columns: repeat(2, 50%);
    margin:auto;
}
.policiesFiltersHeader .switchViewBtnOption{
    max-width: 100%;
}
.policiesFiltersFooter{
    display: inline-block;
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 46px;
    background-color: white;
    z-index: 2;
    text-align: center;
    line-height: 46px;
}

.policiesFiltersToggleSection{
    border-radius:5px;
    background-color: #F3F4F6;
    overflow-y: auto;
    border: solid 1px gainsboro;
    height:100%;
}
.policiesFiltersToggleSection hr{
    width:80%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: gray;   
}
.policiesFiltersToggleSection .toggleContainer{
    padding:5px;
    border-radius: 5px;
    background-color:white;
    border-radius: 5px;
    border: solid 1px gainsboro;
}

.policiesFiltersToggleSection .content{
    height:max-content;
    gap:8px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
}
.policiesFiltersRangeSliderSection{
    border-radius: 5px;
    background-color: #F3F4F6;
    overflow-y: auto;
    border: solid 1px gainsboro;
    height: 100%;
}
.policiesFiltersRangeSliderSection .content{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: flex-start;
    text-align: center;
}

.policiesRangeBoard{
    width:100%;
    gap: 8px;
    grid-template-rows:200px 56px auto;
}
.policiesFiltersModuleInner .toggleContainer{
    margin-top:unset;
}
.policiesRangeBoard .MuiBox-root{
    display:inline-block;
}
.policiesRangeBoard .MuiBox-root span span:nth-child(1){
    color:#4DD2FF;
}
.policiesRangeBoard .MuiBox-root span > span:last-child, .policiesRangeBoard .MuiBox-root span > span:nth-child(3){
    color:#0C77E8;
}
.policiesRangeBoard .MuiBox-root span > span:last-child *, .policiesRangeBoard .MuiBox-root span > span:nth-child(3) *{
    color:white;
}
.policiesRangeBoard .MuiBox-root span span:nth-child(2){
    color:#00bfff;
}
.policiesRangeBoard .MuiSlider-mark{
    width:4px;
    height:4px;
    border-radius: 50%;;
}

.policiesRangeBoardHeader{
    height:32px;
    width:100%;
    line-height:32px;
    margin-bottom:32px;
    font-weight:700;
    color:gray;
}

.policiesRangeBoardBlock{
    display:inline-block;
    width:100%;
    height:auto;
    background-color: white;
    border-radius: 5px;
    min-height:32px;
    position:relative;
    border: 1px solid #dcdcdc;
}

.policiesRangeBoardBlock:last-of-type{
    background-color:transparent;
    color:gray;
    border: none;
}
.policiesRangeMessage{
    width: 100%;
    position: absolute;
    margin: auto;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    font-weight: 700;
}

.policiesRangeCurrentValues{
    display: inline-block;
    width: 300px;
    height: 56px;
    line-height: 56px;
    font-weight: 700;
    color: gray;
    font-size: 32px;
}
.policiesRangeToggleBlock{
    padding:5px;
}
.policiesColumnHeader{
    border-style: none;
    width: calc(100% - 16px);
    grid-template-columns: 36px repeat(5, 1fr 2px) 36px
}

.mobile .policiesColumnHeader{
    grid-template-columns: 36px repeat(3, 1fr 2px) 36px
}

.toggleAllBtn{
    color: white;
    background-color: #00BFFF;
    border-radius: 5px;
    width: 150px !important;
    height: 32px;
    line-height: 32px;
    text-align: center;
    box-shadow: inset 0 0 0 2px #00bfff;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 7px;
    right: 24px;
    z-index: 2;
}
.toggleAllBtn:hover{
    cursor:pointer;
    color:#00BFFF;
    background-color:white;
    box-shadow:inset 0 0 0 2px #00bfff;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
}

.policyRow{
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    display: inline-table;
    height: 55px;
    margin-left: 28px;
    margin-top: 8px;
    position: relative;
    width: calc(100% - 42px);
}
.policyRow.managed{
    margin-top:24px;
}
.policyRow:hover{
    border:solid gray 1px;
}
.policyRow:hover, .policyRow:hover span{
    cursor:pointer;
    background-color:#E2E2E2;
    color:gray;
}
.policyRowAccountIdentifier{
    position:absolute;
    bottom:100%;
}
.inlineNotificationIcon{
    color: #00BFFF;
    position: absolute;
    left: 2px;
    top: 50%;
    height: 10px;
    width: 10px;
    transform: translate(0, -50%);
}
.inlineNotificationIcon svg{
    height:12px;
    width:12px;
    display: block;
}

.policyRowInner{
    position:relative;
    width:100%;
    min-height: 55px;
    text-align: center;
    display: grid;
    grid-template-columns: 50px calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px);
}

.policyRowInner > div:last-of-type{
    text-align: right;
    padding-right: 8px;
}

/* singlePolicy */
.singlePolicyCurrentActivity ::-webkit-scrollbar-thumb{
    border-color:#F3F4F6;
}
.singlePolicyCurrentActivity ::-webkit-scrollbar-track{
    background-color:#F3F4F6;
}
.policyDetails::-webkit-scrollbar-track{
    background-color: white;
}
.policyDetails::-webkit-scrollbar-thumb{
    border-color:transparent;
}
.singlePolicyBlockOptionBar{
    width: 100%;
    height: 48px;
    background-color: white;
    z-index: 3;
    text-align: center;
    grid-template-columns: 50% 50%;
}

.singlePolicyHeader{
    position:sticky;
    top:0;
    font-weight:700;
    text-align: center;
    z-index: 2;
    background-color:white;
    width:100%;
    padding-right:44px;
    padding-left:44px;
    left:32px;
    height:100px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}

.singlePolicyView{
    text-align:center;
}

.singlePolicyCurrentActivity{
    display: inline-block;
    width: calc(100% - 18px);
    height: auto;
    min-height:calc(100% - 7px);
    max-width: 100%;
    text-align: center;
    border-radius: 5px;
    position:relative;
    background-color:inherit;
}

.fund .policyDetailsCard{
    grid-template-rows: minmax(144px, auto) 1fr;
}

.policyDetailsCard{
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    grid-template-rows:auto 1fr;
}

.policyDetails{
    overflow: overlay;
}
.policyDetailsCardTitle{
    width: 100%;
    min-height: 112px;
    text-align: center;
    z-index: 2;
    background-color: white;
    grid-template-rows:1fr 24px;
}
.policyDetailsCardTitleSmall{
    position: absolute;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    top: 0;
    z-index: 2;
    background-color: inherit;
    grid-template-columns: 48px calc(100% - 96px) 48px;
}

.policyDetailsCardTitle span, .policyDetailsCardTitleSmall span{
    font-weight:700;
}

.fund .policyDetailsCardTitle{
    min-height: 72px;
}

.singlePolicyHeaderInnerWrap{
    text-align: left;
    position:relative;
    width:100%;
    height:100%;
    padding:10px;
}
.policyDetailsCardFace{
    background-color: #f3f4f6;
    color: gray;
    display: inline-block;
    height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;
    border:solid 1px gainsboro;
}
.policyDetailsCardFaceInner{
    width: 100%;
    height: 100%;
    padding-top: 10px;
    display: flex;
}

.activityTimelineHeader{
    height: auto;
    width: calc(90% - 10px);
    font-weight: 400;
    line-height: 36px;
    font-size: 15px;
    text-align: right;
    position: relative;
    top: 0;
    left: calc(5% + 5px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
    display: grid;
    grid-template-columns: calc(100% - 180px) 180px;
}
.activityTimelineHeaderBtn{
    display: inline-flex;
    width: auto;
    height:24px;
    background-color:#F3F4F6;;
    border-radius: 5px;
    padding: 0 7px;
    line-height: 24px;
    outline: solid 1px gainsboro;
    color:dimgray;
    font-weight: 400;
}
.activityTimelineHeaderBtn svg{
    position: relative;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin-left:5px;
}
.activityTimelineHeaderBtn:hover{
    cursor:pointer;
    background-color:#E2E2E2;
    color:darkgray;
}
.policyDetailData{
    display: inline-block;
    position: relative;
    width: 100%;
    font-weight:500;
}
.policyDetailData span{
    font-weight:700;
    color:gray;
}


.listAltercationOption{
    display: inline-block;
    border-radius: 5px;
    background-color: #F3F4F6;
    width: 100px;
    height: 24px;
    text-align: left;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    outline: solid 1px gainsboro;
    color:dimgray;
    font-weight: 400;
}
.listAltercationOption:hover{
    cursor:pointer;
    background-color:#E2E2E2;
    color:darkgray;
}
.listAltercationOptionString{
    float:left;
  }
.filterBtn{
    float:right;
}
.filterBtn:hover{
    cursor:pointer;
}

.listBar{
    width: calc(100% - 16px);
    height: 1px;
    background-color: #EFEFEF;
    position: absolute;
    left: 50%;
    box-shadow: 0 0 5px #ccc;
    z-index: 2;
    bottom: 0;
    transform: translate(-50%, 0);
}
  
.matchingText{
    font-weight:700;
}
.singlePolicyTimelineTracker.lead{
    grid-template-rows: 112px calc(100% - 112px);
}
.singlePolicyTimelineTracker.origination{
    grid-template-rows: minmax(150px,auto) 1fr;
}
.singlePolicyTimelineTracker{
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    grid-template-columns: 100%;
    grid-template-rows: 72px calc(100% - 72px);
}
.singlePolicyTimelineTrackerInnerWrap{
    width:100%;
    height:100%;
}

.singlePolicyTimelineBar{
    height: 36px;
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 36px;
}

.timelineTrackBar{
    width: 80%;
    background-color: #EFEFEF;
    text-align: left;
    border-radius: 16px;
    height: 16px;
    display: inline-block;
    overflow: hidden;
    border: solid 1px gainsboro;
}

.timelineTrackStatus{
    background-color:#00bfff;
    width:0;
    height:100%;
    transition: width 1s
}
.timelineTrackStatus.failed{
    background-color:#EF5350;
}
.timelineTrackStatus.completed{
    background-color: #00c853;
}
.singlePolicyTimeline{
    width: 100%;
    background-color: #F3F4F6;
    display: inline-block;
    height: auto;
    padding-bottom:8px;
    border-radius: 5px;
}

.singlePolicyTimelineInner{
    position: relative;
    height:100%;
    width:100%;
}

.singlePolicyTimelineInner div{
    border-radius: 50%;
    position: absolute;
    margin: auto;
    display: block;
    top: 50%;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
}

.groupIcon{
    height: 36px;
    font-size: 36px;
    width: auto;
    color: dimgray;
}

.groupIcon svg{
    width:100%;
    height:100%;
}

.timelineIssueDate{
    display: inline-block;
    width: 100% - 16px;
    height: auto;
    position: relative;
    color:gray;
    font-weight:700;
}

.idleStamp svg{
    width:1.5em;
    height:1.5em;
}
.statusBody.condense{
    grid-template-columns: 50px calc(100% - 150px) 100px;
}
.statusBody.VOC, .statusBody.Payment{
    grid-template-rows: calc(50% - 8px) 16px calc(50% - 8px);
    grid-template-columns:unset !important;
}

.policyDetailsTitle{
    width: 100%;
    display: inline-block;
    height: 36px;
    line-height: 32px;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
}

.policyDetailsBlock{
    width:100%;
    height:calc(100% - 36px);
}

.policyDetailsBlock.secondary{
    display:none;
}

.policyDetailsHolderTag{
    width: 24px;
    height: 24px;
    color:dimgrey;
}

.singlePolicyCurrentActivity * .profileDetails{
    width:95%;
}
.fund .overViewDataBlock{
    grid-template-rows: 1fr 32px;
    grid-template-columns: 100%;
}
.overViewDataBlock{
    text-align: center;
    grid-template-columns: 100%;
}
.overViewDataBlock.small{
    height: auto;
    display: inline-block;
    width: 100%;
}
.overViewDataTitle{
    width: 100%;
    font-weight:100;
    text-align: center;
    margin-bottom:0;
    font-size: 20px;
}
.overViewDataOption{
    width: 100%;
    height:14px;
    line-height:14px;
    font-size: 16px;
    font-weight:500;
    color:dimgray;
    text-align: center;
}
.overViewData{
    display:inline-block;
    width:100%;
    color:gray;
    font-size:32px;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
}
.primaryTitle, .secondaryTitle{
    width:50%;
    display: inline-block;
    color:gray;
}

.policyDetailsHeader{
    width:100%;
    height:36px;
    text-align: left;
    color:dimgray;
    grid-template-columns: repeat(3, auto) 1fr;
    gap:8px;
}

.policyDetailsHeader.bold{
    font-weight:700;
}

.policyDetailsHeader .source.abacusPays {
    height:28px;
    color: #1b5e20;
    background-color: #c8e6c9;
    grid-template-columns: 36px 1fr;
}

.policyDetailsHeader .source.direct {
    height:28px;
    color: #4a148c;
    background-color: #e1bee7;
    grid-template-columns: 36px 1fr;
}

.policyDetailsPolicy{
    display:inline-block;
    height:auto;
    width:100%;
    margin-bottom:15px;
}

/* Documents */

.documentsBlock{
    background-color: #F3F4F6;
    border-radius: 5px;
    color: gray;
    position: relative;
    text-align: left;
    overflow: hidden;
    /* border: solid 1px gainsboro; */
}
.documentsBlockInner{
    position: relative;
    height:100%;
    width:100%;
    overflow:auto;
}

.documentOption{
    display:inline-block;
    width: calc((100% / 4) - 2.5%);
    height: auto;
    padding-bottom:calc((100% / 4) - 2.5%);
    background-color: white;
    margin:1.25%;
    position:relative;
    color:#E2E2E2;
    border-radius: 5px;
    border: solid 1px gainsboro;
    text-align: center;
}
.documentOption:hover{
    color:dimgray;
    cursor:pointer;
}
.documentOptionInner{
    position:absolute;
    width:100%;
    height:100%;
}
.documentOptionIcon{
    position: relative;
    width: 100%;
    height:100%;
}
.documentOptionIcon svg{
    width: 50%;
    height: 50%;
    position: absolute;
    margin: auto;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#d2ab66;
}
.documentOptionName{
    height: 50%;
    position: absolute;
    margin: auto;
    display: block;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-height:32px;
    width: 100%;
    line-height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    color:gray;
}
.documentOptionStatus{
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    top: 5%;
    right: 5%;
    color:#FF3131;
    border-radius:50%;
    background-color:#EFEFEF;
}
.completed{
    color:#31D647;
    background: inherit;
}

.documentsTracker{
    height: 36px;
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 36px;
    color:gray;
}

.summaryStatement .loadingProgress *{
    color:#00bfff !important;
}

.summaryStatementEmpty{
    color:gray;
    width:100%;
    height:85px;
    line-height: 85px;
    position: relative;
}
.summaryStatementEmpty div{
    position: absolute;
    width:100%;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    line-height: 16px;
    text-align: center;
}

.singlePolicyDocuments{
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
    border:none;
}

.statusDetailBlock{
    position: absolute;
    width: 80%;
    max-width: 600px;
    height: auto;
    border-radius: 5px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}
.statusDetailInnerWrap{
    width: 100%;
    height: auto;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    display: block;
}
.statusDetailInner{
    position: relative;
    width: 100%;
    height: 100%;
}
.statusDetailBodyContainer{
    position: relative;
    width: 100%;
    height: auto;
    min-height:200px;
}
.statusDetailBody{
    width: 100%;
    height: auto;
}

.statusNoteBody{
    position: relative;
    width:100%;
    height:100%;
}
.statusNoteBody .statusContent{
    position:absolute;
    width:100%;
    height:calc(100% - 72px);
    bottom:0;
    text-align: left;
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
}
.statusCreationInfo{
    display: inline-block;
    position: absolute;
    left: 0;
    padding: 15px;
    text-align: left;
}

.statusNameOfSender{
    color:gray;
    font-size:14px;
}

.statusDetailBody .statusNameOfSender, .statusDetailBody .statusTimeStamp{
    text-align: right;
}

.policiesColumnHeader .option{
    grid-template-columns: 36px 1fr 36px;
    border-bottom:solid 2px transparent;
}

.policiesColumnHeader .option:last-of-type{
    grid-template-columns: 72px 1fr;
}

.policiesColumnHeader .option:hover{
    cursor:pointer;
    border-color:#00BFFF;
}

.policiesColumnHeader .option .arrows{
    grid-template-rows: repeat(2, 12px);
}

.policiesColumnHeader .option :nth-child(2){
    color:dimgray;
}

.statusTimeStamp{
    font-size: 14px;
    color: dimgray;
    font-weight: 100;
}

.statusBodyIconWrap{
    width: 150px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.statusBodyIconInnerWrap{
    position:relative;
    width:100%;
    height:100%;
}
.statusBodyIcon svg{
    width:65px;
    height:65px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}

.statusDefinition{
    width: calc(100% - 150px);
    padding: 15px;
    display: inline-block;
    height: auto;
    vertical-align: top;
    margin-left: 150px;
}

.statusDefinitionTitle{
    text-align: center;
    font-weight: 700;
    color: dimgray;
    font-size: 18px;
    position: absolute;
    left: 50%;
    top: calc(50% - 60px);
    transform: translate(-50%, -50%);
    width: 100%;
}
.statusDefinitionText{
    text-align: left;
    margin:10px auto;
    margin-bottom:24px;
}

.formCanvas{
    display: grid;
    gap: 8px;
    width: 100%;
    margin-bottom:16px;
}
.formCanvasHalf{
    display: grid;
    width: calc(50%);
    margin-bottom:16px;
    gap:8px;
}

.singleFormFieldRow{
    display:grid;
    width: 100%;
    gap: 8px;
    grid-template-columns: 1fr;
    justify-content: space-evenly;
    justify-items: inherit;
    align-content: space-evenly;
    align-items: end;
}
.doubleFormFieldRow{
    display:grid;
    width: 100%;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-evenly;
    justify-items: inherit;
    align-content: space-evenly;
    align-items: end;
}
.trioFormFieldRow{
    display: grid;
    width: 100%;
    gap: 8px;;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
    justify-items: inherit;
    align-content: space-evenly;
    align-items: end;
}
.quadFormFieldRow{
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-evenly;
    justify-items: inherit;
    align-content: space-evenly;
    align-items: end;
}
.summaryStatement.load{
    grid-template-columns: 100%;
}
.summaryStatement{
    position: relative;
    height: 100%;
    width: 100%;
    color:white;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100%;
}
.summaryStatement.alt{
    grid-template-columns: 1fr 8px repeat(2, 1fr);
}
.summaryStatement .data{
    grid-template-rows: 36px 1fr;
}
.summaryStatementTitle{
    width:100%;
    height:16px;
    color:gray;
    line-height: 16px;
}
.summaryStatementBidValue{
    height:48px;
    min-width: 48px;
    font-size:24px;
    font-weight: 700;
    background-color: #F3F4F6;
    border-radius: 5px;
    border: solid 1px gainsboro;
    color:gray;
}
.switchViewBtnContainer.tabCount3{
    grid-template-columns: repeat(3, calc(100% / 3));
}

.switchViewBtnContainer.tabCount4{
    grid-template-columns: repeat(4, calc(100% / 4));
}

.switchViewBtnContainer.tabCount5{
    grid-template-columns: repeat(5, calc(100% / 5));
}

.switchViewBtnContainer.tabCount6{
    grid-template-columns: repeat(6, calc(100% / 6));
}

.timelineRequest{
    width: calc(100% - 45px);
    min-height: 36px;
    color: gray;
    position: sticky;
    border-radius: 5px;
    border: solid 2px gainsboro;
    left: 36px;
    background-color:white;
    display: grid;
    grid-template-columns: 32px calc(100% - 64px) 32px;
}
.timelineRequest.VOC{
    width:calc(100% - 16px);
    grid-template-rows: calc(50% - 8px) 16px calc(50% - 8px);
    grid-template-columns: unset !important;
    padding-bottom:8px;
    left:8px;
}
.timelineRequest.VOC.completed{
    border:solid 1px gainsboro;
    background-color: white;
}
.timelineRequest div{
    display:inline-grid;
}

.timelineRequest.open{
    background-color: #ffebee;
    border: solid 2px #ffcdd2;
    color: #ef5350;
}
.timelineRequest.submitted{
    background-color: #fffde7;
    border-color: #ffeb3b;
    color: #f57f17;
}
.timelineRequestInnerWrap{
    display: inline-grid;
    position: relative;
    min-height: 36px;
    grid-template-columns:1fr auto;
}
.VOC .timelineRequestInnerWrap{
    grid-template-columns: unset !important;
}
.timelineRequestStatement{
    position: relative;
    height: auto;
    min-height: 36px;
    text-align: left;
}
.timelineRequestIcon{
    width: 30px;
    height: 100%;
}
.timelineRequestIcon svg{
    width: 22px;
    height: 22px;
    border-radius:50%;
}
.timelineRequestCheckbox{
    position: relative;
    width: 30px;
}
.completed .timelineRequestCheckbox svg:hover, .timelineRequestCheckbox .internal svg:hover{
    cursor:default;
}
.timelineRequestCheckbox svg:hover{
    cursor:pointer;
}
.timelineRequestCheckbox svg{
    width: 22px;
    height: 22px;
    border-radius:50%;
}
.timelineRequest.completed{
    background-color: inherit;
    border-color: transparent;
}
.timelineRequest.completed div:last-of-type svg{
    color:#00c853;
}
.timelineRequestTimestamp{
    position: absolute;
    left: 100%;
    width: 90px;
    height: 100%;
    color: gray;
    text-align: right;
}
.VOC .timelineRequestTimestamp{
    position: relative;
    left: unset;
    width: unset;
    height: 100%;
}
.bidsPanel{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    grid-template-rows: 48px 48px 1fr;
    row-gap: 8px;
}
.bidPanelHeader{
    display: block;
    width: 100%;
    height: 48px;
    border-bottom:solid 2px gray;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}
.bidPanelHeader .bidPanelDetail{
    background-color: transparent;
    border: none;
    font-weight: 100;
    box-shadow: none;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    display: grid;
}

.bidPanelTable{
    background-color: #F3F4F6;
    padding:3px;
    border: solid 1px gainsboro;
}
.bidPanelTableRow{
    display: grid;
    width: 100%;
    min-height: 52px;
    margin-top:1px;
    height: auto;
    grid-template-columns:repeat(3, calc(20% - 2px)) calc(40% - 2px);
    grid-template-rows: auto;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    text-align: center;
    gap:2px;
}

.bidPanelTableRow div:last-child .bidPanelDetailValue{
    text-align: left;
}
.bidPanelDetail{
    display: table;
    box-sizing: border-box;
    width: 100%;
    min-height: 52px;
    height: 100%;
    background-color: white;
    border: 1px solid gainsboro;
    border-bottom: 2px solid gainsboro;
    font-weight: 400;
    color: dimgrey;
}
.bidsActionBar{
    grid-template-columns: repeat(3, calc(100% / 3));
}
.bidPanelDownloadBtn{
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px #00bfff;
    color:#00bfff;
    background-color: white;
    font-weight: 400;
    border-radius: 5px;
    grid-template-columns: calc(100% - 28px) 28px;
}
.bidPanelDownloadBtn:hover{
    border:none;
    background-color: #00bfff;
    color:white;
    cursor:pointer;
}
.bidPanelDetailValue{
    position: relative;
    width: 100%;
    min-height: 48px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.bidPanelDetailValue.bold{
    font-weight: 500;
}
.bidPanelDetailValue div{
    display:block;
    text-overflow: ellipsis;
    overflow:hidden;
    width:60px;
    white-space: nowrap;
}
.bidPanelDetailValue svg{
    width:20px;
}
.timelineRequestTreeline{
    position:absolute;
    width:1px;
    height:calc(100% + 10px);
    top:50%;
    left:-15px;
    transform: translate(0, -50%);
    border: 1px gray;
    border-style:dashed;
}

.bidPanelTable .empty{
    color:gray;
}

.nextStepPost{
    display: inline-grid;
    width: calc(100% - 16px);
    height: auto;
    min-height: 60px;
    background-color: #29b6f6;
    border-radius: 5px;
    position: relative;
    text-decoration: none;
    grid-template-columns: 36px calc(100% - 36px);
    grid-template-rows: auto;
    color: white;
}
.nextStepPostInner{
    display:inline-grid;
    font-weight: 500;
    text-align:left;
    padding:0 8px;
    grid-template-columns: 75px calc(100% - 75px);
}
.nextStepPostIcon{
    display:inline-grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    font-weight: 500;
}

.nextStepIdentifier{
    display:inline-grid;
    text-align:left;
}
.nextStepPrompt{
    display:inline-grid;
    text-align:left;
}

.requestCounterPrompt{
    float:left;
    color:dimgray;
}

.emptyRequests{
    display:grid;
    height:100%;
}
.emptyRequestsMessage{
    display:inline-grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
    height:36px;
}
.emptyRequestsPrompt{
    text-align: center;
}
.emptyRequestsIcon{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height:auto;
    width:auto;
}
.emptyRequestsIcon svg{
    width:50px;
    height:50px;
}
.requestCompleteHeader span{
    vertical-align: middle;
    font-weight: 400;
}
.requestCompleteHeader svg{
    color:#4caf50;
    vertical-align: middle;
    margin-left:5px;
}

.requestLink{
    color: #00bfff;
    font-weight: 500;
    position: relative;
    vertical-align: middle;
    cursor:pointer;
}
.requestLink span:hover{
    cursor: pointer;
    color:#2196f3;
}
.submitted .requestInternalPrompt{
    color:#f57f17;
}
.completed .requestInternalPrompt{
    color:#4caf50;
}
.completed .servicing .requestInternalPrompt{
    color:gray;
}
.requestInternalPrompt{
    color: #ef5350;
    font-weight: 500;
    position: relative;
    cursor:default;
}

.requestInternalPrompt > *{
    width: auto !important;
}
.loadingDrive{
    position: relative;
    width:100%;
    height:100%;
}
.loadingDrivePrompt{
    position:absolute;
    top:calc(50% + 50px);
    left:50%;
    transform: translate(-50%, -50%);
    width:auto;
    height:50px;    
}
.loadingDrive svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:50px;
    height:50px;
}


.switchViewBtn .notificationBubble svg{
    color: #f44336;
}

.currentActivity{
    background-color: inherit;
    position: relative;
    border-radius: 5px;
}
.currentActivity::-webkit-scrollbar {
    background-color: transparent !important;
}
.currentActivity::-webkit-scrollbar-thumb {
    background: dimgray;
    border: 4px transparent solid;
    background-clip: padding-box;
    border-radius: 18px;
}
.currentActivity::-webkit-scrollbar-track {
    background: inherit;
}

.closingTabInnerWrap{
    position:relative;
    width:100%;
    height:auto;
}

.closingTabTrackline{
    position: absolute;
    width: 4px;
    height: 100%;
    left: -16px;
    background-color: gray;
    display: inline-block;
    border-radius: 3px;
}

.closingTabTrackline.completed, .closingTabTracklineDivider.completed{
    background-color:#4caf50 ;
}

.closingTabTrackline.progress, .closingTabTracklineDivider.progress{
    background-color:#fbc02d;
}

.closingTabTrackline.open, .closingTabTracklineDivider.open{
    background-color: #ef5350;
}
.closingTabTrackline.package, .closingTabTracklineDivider.package{
    background-color:#00BFFF;
}

.closingTabTracklineDivider{
    position: absolute;
    width: 4px;
    height: 4px;
    left: -16px;
    top:6px;
    background-color: gray;
    display: inline-block;
    border-radius: 3px;
}

.closingTabSectionHeader{
    color:gray;
    width:95%;
    height:18px;
    font-size: 18px;
    line-height: 18px;
    font-weight:500;
    text-align: left;
    padding-left:15px;
    margin-top:10px;
    margin-bottom:5px;
    position: relative;
    display:inline-block;
}
.closingTabSection{
    height:auto;
    min-height: 40px;
    width:95%;
    outline:dashed 2px #E2E2E2;
    border-radius: 5px;
    display:inline-block;
    position:relative;
}
.closingTabSection > div{
    gap:8px;
}

.closingTabSection .timelineRequest{
    width:calc(100% - 16px);
    left:1.25%;
}

.closingTabSection.open{
    outline-color:#ffcdd2;
}

.closingTabSection.progress{
    outline:2px #ffeb3b dashed;
}

.closingTabSection.completed{
    outline:solid #c8e6c9 2px;
}
.closingTabSection.package{
    outline:solid #b3e5fc 2px;
}
.closingTabSectionHeader.open{
    color:#ef5350;
}

.closingTabSectionHeader.progress{
    color:#fbc02d;
}

.closingTabSectionHeader.completed{
    color:#4caf50;
}
.closingTabSectionHeader.package{
    color:#00bfff;
}

.closingTabDownloadBtn{
    position: relative;
    height:36px;
    min-width: 100px;
    width: auto;
    background-color: #EFEFEF;
    line-height: 36px;
    border: solid 1px #E2E2E2;
    border-radius: 5px;
    color: gray;
    padding: 0 10px;
    cursor: default;
    display: inline-block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.closingTabDownloadBtn span{
    margin-top:-2px;
}

.closingTabDownloadBtn svg{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}

.closingTabDownloadBtn:hover svg{
    color:white;
}

.package .closingTabDownloadBtn{
    box-shadow: inset 0 0 0 2px #00bfff;
    color:#00bfff;
    background-color: transparent;
    border-color:transparent;
    font-weight: 500;
}

.package .closingTabDownloadBtn:hover{
    border:none;
    background-color: #00bfff;
    color:white;
    cursor:pointer;
}
.closingAssignment{
    display:inline-block;
    text-align: left;
    color:dimgray;
    width:95%;
    margin-top:5px;
    font-weight: 400;
}
.closingAssignment.space{
    margin-bottom:15px;
}
.closingAssignment b{
    font-weight: 500;
}

.closingAssignmentDivider{
    height:3px;
    width:75%;
    border-radius:3px;
    background-color:#E2E2E2;
    display: inline-block;
    border-radius: 3px;
    overflow: hidden;
}

.closingAssingmentDividerProgress{
    height:100%;
    background-color:#fbc02d;
    position:absolute;
    left:0;
    top:0;
    transition: 1s;
}

.closingAssignmentDivider.completed{
    background-color:#4caf50;
}

.closingAssignment.package{
    margin-top:15px;
    text-align: center;
    color:#00bfff;
}

.closingAssignment.completed{
    color:#4caf50;
    font-weight:500;
    text-align: center;
}

.closingAssignmentDivider.progress{
    background-color:#EFEFEF;
}
.closingAssignment.progress{
    color:#fbc02d;
    font-weight:500;
    text-align: center;
}

.closingAssignment.open{
    color:#ef5350;
    font-weight:500;
    text-align: center;
}
.closingAssignmentDivider.package{
    background-color:#00bfff;
}

.closingTab{
    height:100%;
    width:100%;
    overflow-y: auto;
}
.closingTab::-webkit-scrollbar-track{
    background: white;
}
.closingTab::-webkit-scrollbar-thumb{
    border-color: white;
}

.closingStatusPending{
    width:100%;
    height:40px;
    line-height: 40px;
    vertical-align: 100%;
    font-size:24px;
    color:gray;
    font-weight: 500;
    margin:10px auto;
    color:gray;
}

.closingProgressWrap{
    width:100%;
    height:100%;
    position:relative;
}

.closingTabEmptyInner{
    position: relative;
    height: 100%;
    width: 100%;
    background-color:gainsboro;
    color:dimgray;
    display: grid;
}

.closingTabEmptyInner div{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display: inline-grid;
}

.closingBtnContainer{
    width: calc(100% - 20px);
    min-height: 36px;
    margin: 5px auto;
    display:inline-block;
    color:#00bfff;
    text-align: center;
    display: grid;
    grid-template-columns: 36px calc(100% - 136px) 100px;
    grid-template-rows: auto;
}
.closingBtnContainer > div{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display: inline-grid;
}
.closingBtnContainer svg{
    color:#00bfff;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.downloadOptionIcon{
    width:36px;
    position: relative;
}
.downloadOption{
    width: auto;
    padding-left:15px;
    text-align: left;
    justify-content: unset !important;
    justify-items: unset !important;
    align-items: unset !important;
    display: inline-grid !important;
}
.closingTabSection hr{
    width:70%;
    border:0;
    border-top: solid 1px #b3e5fc;
}
.leadSourceIdentifier{
    display: inline-block;
    padding: 0 4px;
    border-radius: 16px;
    width: auto;
    height: 20px;
    font-weight: 500;
    margin-left: 10px;
    text-align: center;
    line-height: 1;
    color: #104735;
    background-color: white;
    vertical-align: text-bottom;
    outline: solid 1px #104735;
}
.leadSourceIdentifier > div{
    margin-left: 8px;
    vertical-align: middle;
    display: inline-block;
    height:16px;
}
.leadSourceIdentifier > svg{
    vertical-align: middle;
    display: inline-block;
    height:16px;
    width: 16px;
}
.policyRowLeadSourceIdentifier{
    border-radius: 5px;
    color: #104735;
    height: 100%;
    left: -28px;
    outline: 2px solid #104735;
    position: absolute;
    text-align: center;
    width: 20px;
}
.policyRowLeadSourceIdentifier.other{
    border-radius: 5px;
    color: gray;
    height: 100%;
    left: -28px;
    outline: 1px solid #E2E2E2;
    background-color:#E2E2E2;
    position: absolute;
    text-align: center;
    width: 20px;
    line-height:16px;
}
.policyRowLeadSourceIdentifier > div{
    width: auto;
    text-align: center;
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(270deg);
    font-weight:500;
}
.policyRowLeadSourceIdentifier svg{
    width:14px;
    margin-left:8px;
}

.premiumScheduleWrapper{
    background-color:#F3F4F6;
    overflow:auto;
    padding-top:3px;
    padding-bottom:3px;
    border:1px solid gainsboro;
    grid-template-rows: 100px auto;
}

.premiumSchedulePanelHeader{
    display: block;
    width: 100%;
    height: 48px;
    border-bottom: solid 2px gray;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}
.premiumSchedulePanelTableRow{
    display: grid;
    width: 100%;
    min-height: 52px;
    margin-top: 1px;
    height: auto;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    grid-template-rows: auto;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    text-align: center;
}
.premiumSchedulePanelDetail{
    border: solid #dcdcdc;
    border-width: 1px 1px 2px;
    color: dimgrey;
    display: table;
    height: 100%;
    background-color: #fff;
    font-weight: 400;
}
.premiumSchedulePanelDetailValue{
    position: relative;
    width: 100%;
    min-height: 48px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.premiumSchedulePanelDetailValue div{
    display:block;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
    color: dimgray;
}
.premiumSchedulePanelDetailValue svg{
    width:20px;
}
.premiumSchedulePanelTable{
    background-color: #F3F4F6;
    width: calc(100% - 6px);
    margin-left: 3px;
    position: relative;
}
.noPremiums{
    color: gray;
}
.premiumScheduleTableRow{
    display: grid;
    width: 100%;
    min-height: 52px;
    margin-top:1px;
    height: auto;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    grid-template-rows: auto;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    text-align: center;
}
.premiumSchedulePanelDetailValue{
    position: relative;
    width: 100%;
    min-height: 48px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.premiumSchedulePanelDetailValue.bold{
    font-weight: 500;
}
.premiumSchedulePanelDetailValue svg{
    width:20px;
}
.premiumSchedulePanelHeader .premiumSchedulePanelDetail{
    background-color: transparent;
    border: none;
    font-weight: 100;
    box-shadow: none;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    display: grid;
}
.premiumScheduleTabRow, .premiumSchedulePanelDetail{
    box-sizing: border-box;
    min-height: 52px;
    width: 100%;
}
.premiumScheduleActionBar{
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: calc(100% / 2) calc(100% / 2);
}
.premiumScheduleActionBar.alt{
    grid-template-columns: repeat(3, calc(100% / 3));
}
.premiumScheduleActionBar .btn{
    position: relative;
    box-sizing: border-box;
    outline: solid #00bfff 2px;
    color: #00bfff;
    background-color: white;
    font-weight: 400;
    border-radius: 5px;
    display: inline-grid;
    grid-template-columns: 1fr 32px;

}
.premiumScheduleActionBar .btn:hover{
    border:none;
    background-color: #00bfff;
    color:white;
    cursor:pointer;
}

.premiumScheduleHeader{
    width:calc(100% - 6px);
    border-radius: 5px;
    height:auto;
    outline:solid 2px #F3F4F6;
    overflow: hidden;
    margin:auto;
    margin-bottom:3px;
    background-color: white;
    position:sticky;
    top:0px;
    z-index: 2;
    border: solid #dcdcdc;
    border-width: 1px 1px 2px;
}
.summaryFunderStatement{
    width: 100%;
    height: auto;
    grid-template-columns: 35% 65%;
}
.summaryPremium, .summaryVOC{
    width:calc(100% - 4px);
    grid-template-rows: 16px 16px 48px;
    display:inline-table;
    border:solid 1px gainsboro;
    border-radius:5px;
}
.summaryPremiumPrompt, .summaryVOCPrompt{
    line-height:16px;
    color:dimgray;
    font-weight:400;
}
.summaryPremiumHeader{
    display:inline-grid;
    grid-template-columns: 50% 50%;
    color:gray;
    width:100%;
    height:16px;
    font-weight:500;
    line-height: 16px;
    padding: 0 8px;
}
.summaryPremiumValues{
    display: inline-grid;
    background-color: #f3f4f6;
    border-radius: 0 0 5px 5px;
    color: gray;
    font-size: 16px;
    font-weight: 400;
    height: 32px;
    line-height: 32px;
    width: 100%;
    grid-template-columns: 50% 50%;
    padding: 0 8px;
}
.summaryVOCHeader{
    display:inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    color:gray;
    width:100%;
    font-weight:500;
    line-height: 16px;
    padding: 0 8px;
}
.summaryVOCValues{
    display: inline-grid;
    background-color: #f3f4f6;
    border-radius: 0 0 5px 5px;
    color: gray;
    font-size: 16px;
    font-weight: 400;
    height: 32px;
    line-height: 32px;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0 8px;
}
.summaryPremiumValues :not(:first-child), .summaryVOCValues :not(:first-child){
    font-weight:500;
}

.summaryBookValue{
    width:calc(100% - 16px);
    text-align: left;
    display:inline-block;
    color:gray;
}
.summaryBookValuePrompt{
    font-weight:100;
    display: inline-block;
    margin-right:8px;
}
.summaryBookValueValue{
    font-weight:500;
    display: inline-block;
    margin-right:8px;
}

.statusBodyCenter{
    display:inline-grid;
    grid-template-columns: 100%;

}
.statusBodyCenter.Payment{
    grid-template-columns: 72px calc(100% - 72px);
}
.statusBodyCenter.VOC{
    grid-template-columns: 72px calc(100% - 72px);
}
.statusBodyCenter.Note{
    text-align: left;
}
.timelinePostTop{
    display: inline-grid;
    grid-template-columns: 32px calc(50% - 16px) calc(50% - 16px);
}
.VOC .timelinePostTop{
    display: inline-grid;
    grid-template-columns: 28px 50px calc(50% - 15px) calc(50% - 64px);
}
.timelinePostBottom{
    display: inline-grid;
    grid-template-columns: calc(100% - 120px) 120px;
}
.Payment .timelinePostBottom.alt{
    grid-template-columns: 50% 50%;
}
.alt .timelineRequestTimestamp{
    padding-right:8px;
}
.VOC .timelinePostTop.alt{
    grid-template-columns: 32px calc(50% - 164px) calc(50% + 102px) 28px;
}
.VOC .timelinePostBottom.alt{
    grid-template-columns: calc(100% / 4) calc(100% / 4) calc(100% / 4) calc(100% / 4);
    height:48px;
}

.VOC .timelinePostBottom.alt div{
    grid-template-rows: 50% 50%;
}
.timeinePostSeparator{
    width:80%;
    height:2px;
    margin:auto;
    background-color: inherit;
    border-radius: 2px;
}
.timeinePostSeparator{
    background-color: gray;
}
.submitted .timeinePostSeparator{
    background-color: #f57f17;
}
.open .timeinePostSeparator{
    background-color: #ef5350;
}

.dateApplied{
    font-weight:500;
}

.singlePolicyGraph{
    z-index: 999;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 80%;
    display: inline-block;
    height: 80%;
    background-color: white;
    transform:translate(-50%, -50%);
    max-height:80%;
}

.closingExtraResources{
    height:48px;
    width:100%;
    overflow: hidden;
    color:#00bfff;
}

.closingExtraResources.active{
    height:auto;
}

.closingExtraResourcesTop{
    line-height: 48px;
    display: grid;
    grid-template-columns: 48px calc(100% - 96px) - 48px;
}
.closingExtraResourcesTitle{
    grid-column: 2;
    height:48px;
    font-weight:500;
}
.closingExtraResourcesBtn{
    grid-column: 3;
    height:48px;
}
.closingExtraResourcesBtn svg{
    cursor:pointer;
}

.filterSection{
    height:48px;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 48px auto;
    background-color: white;
    outline:solid 1px gainsboro;
    gap:8px;
}

.filterSection .divider{
    background-color: dimgray;
}
.filterSection.active{
    height:auto;
}
.filterSectionHeader{
    display:grid;
    grid-template-columns: 120px 1fr 120px;
    color:dimgray;
}
.filterSectionTitle{
    height:48px;
    line-height:48px;
    grid-column: 2;
    font-weight: 500;
}
.filterSectionToggle{
    cursor:pointer;
}

.premiumOptionsPanelWrap{
    position:absolute;
    z-index: 10;
}

.premiumOptionsPanel{
    height:50%;
    width:50%;
    background-color: white;
    position:absolute;
    z-index: 2;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    grid-template-rows: 36px 1fr;
    gap:8px;
}

.mobile .premiumOptionsPanel{
    width:80%;
    height:80%;
}

.premiumOptionsPanel .header{
    color:dimgray;
    font-weight: 500;
    grid-template-columns: 48px 1fr 48px;
    width:100%;
    height: 100%;
}
.premiumOptionsPanel .header .btn{
    border-radius: 50%;
    height: 36px;
    width: 36px;
}
.premiumOptionsPanel .header .btn:hover{
    color:dimgray;
    background-color: whitesmoke;
}
.premiumOptionsPanel .list{
    grid-template-rows: repeat(auto-fill, 48px);
    background-color: #f3f4f6;
    color: gray;
    border: solid 1px gainsboro;
    gap:8px;
}

.premiumOptionsPanel .empty{
    background-color: #f3f4f6;
    color: gray;
    border: solid 1px gainsboro;
}

.premiumOptionsPanel .data{
    background-color: white;
    width:calc(100% - 16px);
    height:100%;
    outline: solid 1px gainsboro;
}
.premiumOptionsPanel .data:hover{
    background-color: gainsboro;
    color:white;
}


.policyRowBlock{
    height: auto;
    width:calc(100% - 16px);
    grid-template-rows: minmax(36px, auto) 2px minmax(36px, auto);
    background-color: white;
    border: solid 2px gainsboro;
    color:dimgray;
}

.policyRowBlock:hover{
    background-color: #E2E2E2;
    color:dimgray;
    border-color:dimgray;
}

.policyRowBlock:hover .divider{
    background-color: dimgray;
}

.policyRowBlock:hover .header svg:not(.source svg), .policyRowBlock:hover .header .data{
    color: dimgray !important;
}

.policyRowBlock .header{
    grid-template-columns: 36px 72px 72px 72px 36px 160px 1fr 96px;
    color:gray;
}

.policyRowBlock.alt .header{
    grid-template-columns: 36px 72px 160px 1fr 96px;
    color:gray;
}

/* .policyRowBlock.fund .header{
    grid-template-columns: 36px 72px 72px 36px 160px 1fr 96px;
} */

.mobile .policyRowBlock .header{
    grid-template-columns: 36px 36px 1fr 96px;
    color:gray;
}

.policyRowBlock .header svg:not(.source svg, .active > svg, .insuredCount > svg, .account svg), .policyRowBlock .header .alertStatus .data{
    color: #E2E2E2;
}

.policyRowBlock .header .alertStatus.active .data{
    color:gray;
}

.policyRowBlock .header .active > svg{
    color: #00BFFF;
}

/* .policyRowBlock .header > :last-child{
    padding-right:4px;
} */

.policyRowBlock .details{
    grid-template-columns: 36px repeat(5, 1fr);
}

.mobile .policyRowBlock .details{
    grid-template-columns: 36px repeat(3, 1fr);
}

.policyRowBlock .source{
    grid-template-columns: 36px 1fr;
    border-radius: 5px;
    font-weight: 500;
    line-height: 24px;
    height:28px;
}

.policyRowBlock .source.abacusPays{
    color:#1b5e20;
    background-color:#c8e6c9;
}

.policyRowBlock .source.direct{
    color:#4a148c;
    background-color:#e1bee7;
}

.policyRowBlock .source.other{
    color:#e65100;
    background-color:#fff3e0;
}


.policyRowBlock .alertStatus{
    grid-template-columns: repeat(2, 32px);
}

.filterSection .list{
    gap:8px;
}

.filterSection .list .item.on{
    height:48px;
    width: 100%;
    grid-template-columns: 36px 1fr 72px 72px;
    border:2px solid #E2E2E2;
    background-color: white;
    color: gray;
}

.filterSection .list .item.off{
    height:48px;
    width: 100%;
    grid-template-columns: 36px 1fr 72px 72px;
    border:2px solid gray !important;
    background-color: #EFEFEF !important;
    color: dimgray !important;
}

.filterSection .list .item.off svg{
    color:dimgray;
}

.filterSection .list .item.off:not(.abacusPays, .direct, .other):hover svg{
    color:inherit !important;
}

.filterSection .list .item.on:hover{
    background-color: #E2E2E2 !important;
    color:gray !important;
    border:2px solid gray !important;
}
.filterSection .list .item.on:hover svg{
    color: gray !important;
}   

.filterSection .list .item.off:hover{
    background-color: #c8e6c9;
    color: #1b5e20;
    border:2px solid #1b5e20;
}

.filterSection .list .item.off:hover svg{
    color: inherit;
}

.filterSection .list .header{
    grid-template-columns: 36px 1fr 72px;
    height:48px;
}

.filterSection .list .item.abacusPays{
    border-color: #1b5e20;
    color: #1b5e20;
    background-color: #c8e6c9;
}

.filterSection .list .item.abacusPays.off:hover{
    border-color: #1b5e20 !important;
    color: #1b5e20 !important;  
    background-color: #c8e6c9 !important;
}

.filterSection .list .item.direct{
    border-color: #4a148c;
    color: #4a148c;
    background-color: #e1bee7;
}

.filterSection .list .item.direct.off:hover{
    border-color: #4a148c !important;
    color: #4a148c !important;
    background-color: #e1bee7 !important;
}

.filterSection .list .item.serviced{
    border-color: #0d47a1;
    color: #0d47a1;
    background-color: #e3f2fd;
}

.filterSection .list .item.serviced.off:hover{
    border-color: #0d47a1 !important;
    color: #0d47a1 !important;
    background-color: #e3f2fd !important;
}

.filterSection .list .item.other{
    border-color: #e65100;
    color: #e65100;
    background-color: #fff3e0;
}

.filterSection .list .item.other.off:hover{
    border-color: #e65100 !important;
    color: #e65100 !important;
    background-color: #fff3e0 !important;
}

.filterSection .toggleStatus{
    width:48px;
    height:24px;
    outline: 1px solid;
}

.filterSection .toggleStatus.on{
    background-color: #e8f5e9;
    color:#1b5e20;
    outline-color:#1b5e20;
}

.filterSection .toggleStatus.off{
    background-color: #ffebee;
    color:#b71c1c;
    outline-color:#b71c1c;
}

.nestedCat{
    width:100%;
    height:auto;
    grid-template-columns: 36px 1fr 36px;
}

.nestedCat .list{
    grid-template-rows: auto;
}

.nestedCat .vDivider{
    background-color: dimgray;
    height: calc(100% - 16px);
}

.policyInfoBar{
    grid-template-columns: 24px 1fr;
    gap:8px;
}

/* 
.summaryStatement .data {
    grid-template-rows: 36px 8px 1fr;
} */

.summaryStatement .data.split {
    gap: 8px;
    grid-template-rows: 1fr 8px auto;
}

.summaryStatement .data.one {
    background-color: #e8f5e9;
    border: 2px solid #4caf50;
    color: #4caf50;
}
.summaryStatement .data.one .divider {
    background-color: #4caf50;
}

.summaryStatement .data.two {
    background-color: #ede7f6;
    border: 2px solid #6a1b9a;
    color: #6a1b9a;
}
.summaryStatement .data.two .divider {
    background-color: #6a1b9a;
}

.summaryStatement .data.three {
    background-color: #fff3e0;
    border: 2px solid #ff9800;
    color: #ff9800;
}
.summaryStatement .data.three .divider {
    background-color: #ff9800;
}

.summaryStatement  .section{
    grid-template-rows: 1fr 8px auto;
    border-radius: 5px;
    gap: 8px;
}
.summaryStatement  .section.split{
    grid-template-rows: 1fr 8px auto;
    gap:8px;
}
.summaryStatement  .section.split .icon{
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    font-size: 20px;
}
.summaryStatement  .section.split .icon > div:first-of-type :nth-child(1){
    font-weight: 400;
}

.summaryStatement  .section.split .icon > div:first-of-type :nth-child(2){
    font-weight: 500;
    font-size: 24px;
}

.summaryStatement  .section.split .icon > div:last-of-type :nth-child(1){
    font-weight: 100;
}

.summaryStatement  .section.split .icon > div:last-of-type :nth-child(2){
    font-weight: 300;
    font-size: 24px;
}

.summaryStatement  .section.one{
    background-color: #e8f5e9;
    border:solid 2px #4caf50;
}
.summaryStatement  .section.one .divider{
    background-color: #4caf50;
}
.summaryStatement  .section.two{
    background-color: #ede7f6;
    border:solid 2px #6a1b9a;
}
.summaryStatement  .section.two .divider{
    background-color: #6a1b9a;
}
.summaryStatement  .section.three{
    background-color: #fff3e0;  
    border:solid 2px #ff9800;
}
.summaryStatement  .section.three .divider{
    background-color: #ff9800;
}

.summaryStatement .icon.total{
    font-size:24px;
}

.summaryStatement  .data{
    color:dimgray;
}

.summaryStatement  .section.one .icon, .summaryStatement  .section.one .data{
    color:#4caf50;
}
.summaryStatement  .section.two .icon, .summaryStatement  .section.two .data{
    color:#6a1b9a;
}
.summaryStatement  .section.three .icon, .summaryStatement  .section.three .data{
    color:#ff9800;
}