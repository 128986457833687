input{
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    border:none;
    border-bottom: solid 2px #efefef;
    font-size: 18px;
    height: 32px;
    overflow: hidden;
    padding: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    outline:none;
    color:black;
}

input[type=date]{
    text-transform: uppercase;
}

:not(.inactive) > input.editable:active, :not(.inactive, .invalid) > input.editable:focus, :not(.inactive, .invalid) > input.editable:not(:placeholder-shown){
    border-bottom: solid 2px #42a5f5;
}

input:focus {
    outline: none;
}

.placeholder{
    color: gray;
    font-size: 18px;
    font-weight: 400;
    padding-left: 8px;
    z-index: 2;
}

.placeholder.condensed{
    font-size: 12px;
}

.form .row > div{
    grid-template-rows: 24px 32px;
}

.dateInput.blank input {
    color: gray;
}

.dateInput svg{
    position: absolute;
    bottom: 8px;
    right:8px;
    z-index: 1;
    pointer-events: none;
    color:#E2E2E2;
}

.dateInput.active svg{
    color:#2196f3;
}

.dateInput.invalid *, .dateInput.invalid input{
    color:#f44336 !important;
}

.dateInput.invalid input{
    border-bottom:solid 2px #f44336;
}