@media (min-width: 769px) and (max-width: 1024px) and (max-height: 768px) {
    .sizeMid{
        display:block;
    }

    /* Login */
    .splashMessage{
        left: 2vh !important;
    }
    .splashMessageHeadline{
        font-size:44px !important;
        width:100% !important;
    }
    .splashMessageSmall{
        font-size: 23px !important;
        width:100% !important;
    }
    .splashMessageSmallAlt{
        font-size:28px !important;
        width:100% !important;
    }
    .splashMessagMediumAlt{
        font-size:36px !important;
        width:100% !important;
    }
    .splashMessageMedium{
        font-size:39px !important;
        width:100% !important;
    }
    .splashStatusData{
        margin-bottom: unset !important;
        min-height: unset !important;
    }
    .loginBoardInnerWrap{
        right: 2vh !important;
    }


    /* Home Page */

    .notificationBoard{
        width: 100% !important;
        position:fixed !important;
    }
    .notificationBoardInner.moduleBlock{
        width:95%;
    }
    .sideBar p{
        margin:auto;
    }
    .mobileAltOptions{
        display: inline-block;
        width: 55px;
        height: 55px;
        position: relative;
        color:#104735;
    }
    .mobileMoreOptions{
        z-index: 2;
        background-color: inherit;
        width: calc(100% - 90px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }


    /* Pages */

    /* Single Policy */

    .policiesDetailsBoard{
        width:calc(100% - 44px) !important;
    }
    .noPolicyResults{
        top:108px !important;
        left:8px !important;
        right:8px !important;
    }
    .policyResultsData{
        height: 40px !important;
    }
    .policiesFiltersModule{
        height:calc(100vh - 190px) !important;
        width: 95% !important;
        position:fixed !important;
        z-index: 4 !important;
        top:10px !important;
        bottom: 0 !important;
    }
    .policiesFiltersModuleInner{
        overflow:auto !important;
    }
    .policiesFiltersToggleSection{
        width:calc(100% - 10px) !important;
    }
    .singlePolicyTimelineTrackerInnerWrap{
        margin-bottom:10px;
    }
    .activityTimelineHeaderBtn{
        outline: none !important;
    }
    .listAltercationOption{
        outline: none !important;
    }
    .policiesDetailsBlock .listBar{
        display: none !important;
    }
    .policyDetailsCardFaceInner{
        height:auto !important;
        overflow: hidden !important;
    }
    .singlePolicyTimelineTracker{
        position: relative !important;
    }
    .closingTabSection, .closingTabSectionHeader{
        width:calc(100% - 24px) !important;
    }
    .closingTabSectionHeader{
        padding-left:5px !important;
    }
    .bidPanel{
        min-height: calc(100vh - 238px) !important;
    }
    .policiesFiltersRangeSliderSection{
        width: calc(100% - 10px) !important;
        left: 5px !important;
    }
    .policyRowInner{
        position:relative;
        width:100%;
        height:100%;
        text-align: center;
        display: grid;
    }
    .activityTimelineHeader{
        width:100% !important;
        left:0 !important;
    }
    .requestCounterPrompt{
        float:unset !important;
        text-align: center !important;
    }

    .statusDetailBlock{
        position:fixed !important;
        width:90% !important;
        height: auto !important;
    }
    .statusDetailInnerWrap{
        position:relative !important;
    }
    .bidPanelDetailValue.date{
        font-size:14px;
        font-weight: 100;
    }
    .bidPanelDownloadBtn{
        position:relative !important;
        bottom: unset !important;
        right: unset !important;
        margin:2px auto !important;
    }
    .statusDefinition{
        display:block !important;
        position:relative !important;
        height:auto !important;
    }
    .statusDefinitionTitle{
        top: 0 !important;
        font-size: 18px !important;
    }
    .statusBodyIcon svg{
        width:75px !important;
        height:75px !important;
    }
    .emptyRequestsMessage{
        margin-top:10px;
        padding:10px;
    }
    .emptyRequests{
        min-height: calc(100vh - 440px) !important;
    }

    .timeStampSeparator{
        margin-bottom:20px !important;
    }

    /* Leads */
    .missedFollowUp svg{
        display: none !important;
    }
    .missedFollowUpCount span{
        display:none;
    }
    .followUpRemainingTime svg{
        display:none !important;
    }
}