.homePage{
    text-align: center;
    width: 100%;
    min-width: calc(100% - 12vh);
    display: inline-table;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}

.homePageOverview{
    width:100%;
    height:100%;
    text-align: center;
    position: relative;
    display: grid;
    gap:16px;
    grid-auto-rows: calc(100vh - 122px);
}

.origination .homePageOverview{
  grid-template-columns: minmax(450px, auto) 1fr;
}

.fund .homePageOverview{
  grid-template-columns: 1fr minmax(auto, 45%);
}

.homePageTimeline{
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.singlePolicyEmptyTimeline{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    border-radius: 5px;
    overflow: auto;
}

.homePageInfoPanel{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.homePageInfoPanel.fundView{
  grid-template-rows: 250px 1fr;
  gap:16px;
}

.origination .homePageInfoPanel{
  grid-template-rows: repeat(2, 1fr);
  display: grid;
  gap:16px;
}



.homePageInfoPanel > div{
    background-color: white;
    width: 100%;
    border-radius: 5px;
}

.policyPositionBlock{
    height:calc((100% - 365px) / 2 - 15px);
    overflow:hidden;
}
.breakdownBlock{
  overflow:hidden;
  height:100%;
  width:100%;
  grid-template-rows: 36px 16px 1fr;
}

.breakdownBlock.marketing{
  grid-template-rows: 36px 2px 1fr;
  gap:8px;
}

.breakdownBlock.marketing .graphContainer{
  /* background-color: #F3F4F6;
  border:solid 1px gainsboro; */
  overflow: auto;
}
.breakdownBlock.marketing .graph{
  grid-template-columns: 100%;
  grid-template-rows: repeat(4, 1fr);
  height: auto;
}
.breakdownBlock.marketing .graph .row{
  grid-template-columns: 36px 1fr 64px;
  grid-template-rows: auto 36px;
  gap:8px;
}

.breakdownBlock.marketing .subheader{
  color:dimgray;
}

.breakdownBlock.marketing .content{
  grid-template-columns: repeat(2, 1fr);
}
.fundView .chartBlock{
  height: 100%;
}

.fundView .breakdownBlock{
  height:100%;
  overflow: hidden;
}
.fundSummaryBlockWrap{
  height:100%;
  overflow-y: auto;
}
.fundSummaryBlock{
  height:100%;
  min-height: 100%;
  overflow-y:auto;
  grid-template-rows: 48px 2px 1fr;
  grid-template-columns: 100%;
  gap: 8px;
  display: grid;
}

.fundSummaryBlock.alt{
  grid-template-rows: 32px 32px calc(100% - 90px);
}
.fundSummaryBlock .overViewDataBottomSection{
  width:100%;
}

.homePageInfoPanel > div:nth-of-type(2){
    bottom:auto;
    height:100%;
}

.homePagePieChart{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.homePagePieChart.small{
    width: 50%;
    vertical-align: middle;
    height: 100%;
    display: inline-block;
    position: relative;
}
.homePagePie{
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    box-shadow: 0 0 15px #ccc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.homePagePie.small{
    margin-top: auto;
    width: 150px;
    height: 150px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.homePageActiveBlock, .homePageClosedBlock{
    width:100%;
    height:calc((100% - 288px) / 2);
    text-align: center;
        padding-top: 36px;

}
.homePageActiveBlock span, .homePageClosedBlock span{
    display:inline-block;
    font-size:16px;
    color:#00BFFF;
    width:100%;
    text-align: center;
    font-weight: 700;
    margin-top:10px;
}
.homePageActiveBlock div, .homePageClosedBlock div{
    display:inline-block;
    font-size:76px;
    color:gray;
    text-align: center;
    width:100%;
}

.noTimelineResults{
    height: calc(100% - 44px);
    width: calc(100% - 88px);;
    display: inline-block;
    background-color: #F3F4F6;
    border-radius: 5px;
    position:relative;
    box-shadow: 0 0 5px #ccc;
    overflow:hidden;
    margin-top: 2px;
}

.homePageTimeline.moduleBlock.fundView.noTimelineResults.loading{
  position:absolute !important;
  height:100% !important;
}

.noTimelineResultsMessage{
    font-size:32px;
    font-weight:700;
    color:gray;
}

.tempHomePageBlock{
  height:calc(50% - 4px) !important;
  width:100%;
  border-radius:5px;
  background-color:white;
  position:absolute;
}
.tempHomePageBlock:first-of-type{
  top:0;
}
.tempHomePageBlock:last-of-type{
  top:calc(50% + 4px) !important;
  bottom:0;
}
/* Timeline */
.timelineHeader{
  width: 100%;
  z-index: 3;
  background-color: white;
  grid-template-rows:48px 36px;
}
.timelineHeader .header{
  grid-template-columns: 160px 1fr 160px;
}

.homePageTimelineBlock{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    grid-template-rows: 84px 1fr;
}

.fundView .homePageTimelineBlock{
  grid-template-rows: 48px 1fr;
}

.marketing .homePageTimelineBlock{
  grid-template-rows: 48px 1fr;
}

.fundView .timelineHeader{
  grid-template-rows: 1fr;
}

.marketing .timelineHeader{
  grid-template-rows: 1fr;
}

.timeline{
    display: inline-block;
    background-color: #F3F4F6;
    border-radius: 5px;
    overflow: auto;
    border: solid 1px gainsboro;
}

.timelinePost{
  display: block;
  width: calc(100% - 16px);
  height: auto;
  min-height: 46px;
  background-color: white;
  border-radius: 5px;
  margin: auto;
  margin-top: 8px;
  position: relative;
  text-decoration: none;
  border: solid 1px gainsboro;
}

.timelinePost:hover{
    border:solid gray 1px;
}
.timelinePost:hover, .timelinePost:hover span{
    cursor:pointer;
    background-color:#E2E2E2;
    color:gray;
}
.timelinePost.interactive{
  cursor:default; 
  background-color: white;
  display:inline-grid;
  grid-template-columns: 50px calc(100% - 150px) 50px  50px;
}
.timelinePostInner{
    position:relative;
    width:100%;
    text-align: center;
    min-height:46px;
}
.statusBody{
  min-height:46px;
  display: grid;
  grid-template-columns: 50px calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px);
  grid-template-rows: auto;
  padding:8px;
}

.statusUpdateTime, .statusDeathBenefit{
    position: absolute;
    margin: auto;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    max-height: 100%;
    max-width: 100%;
}

.homePageColumnHeader{
    border-style: none;
    font-weight: 500;
    width: calc(100% - 16px);
    line-height: 16px;
    display: grid;
    grid-template-rows: auto;
}

.homePageColumnHeader.originationView{
  grid-template-columns: 36px repeat(4, 1fr) 120px 36px;
}

.homePageColumnHeader.fundView{
  grid-template-columns: calc(20% + 40px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px);
}
.homePageColumnHeader div{
    position: relative;
    color: gray;
}

/* Charts */
.pieContainer {
    height: 150px;
    position: relative;
  }
  
  .pieBackground {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  } 
  
  .pie {
    transition: all 1s;
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    clip: rect(0px, 75px, 150px, 0px);
  }
  
  .hold {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    clip: rect(0px, 150px, 150px, 75px);
  }
  
  #pieSlice1 .pie {
    background-color: #1b458b;
    transform:rotate(30deg);
  }

  .overViewDataTitle{
    width: 100%;
    font-weight: 700;
    text-align: center;
    margin-bottom:5px;
  }
  .overViewDataTotalValOption{
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 100;
    color: dimgray;
    text-align: center;
  }

  .overViewDataTotalAmountOption{  
    color: #4caf50;
    font-weight: 700;
  }

  .homePage * .overViewDataBlock.small{
    height:100%;
  }
  .homePage * .overViewDataTitle{
    margin-bottom:0;
  }

  .activePoliciesSummary{
    position:relative;
    width:50%;
    height:calc(100% - 32px);
    display:inline-block;
    top:32px;
  }
  .closedPoliciesSummary{
    position:relative;
    width:50%;
    height:calc(100% - 32px);
    display:inline-block;
    top:32px;
  }
  .policySummaryBlock{
    position:absolute;
    width:100%;
    height:auto;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
  }

  .policyPositionSummaryIcon{
    width:54px;
    height:54px;
    border-radius:50%;
    overflow: hidden;
    position:relative;
    display:inline-block;
  }
  .policyPositionSummaryIcon.Active{
    background-color:#01579b;
  }
  .policyPositionSummaryIcon.Closed{
    background-color:#e65100;
  }
  .policyPositionSummaryIcon svg{
    width:40px;
    height:40px;
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color:white;
  }
.policyPositionSummaryTitle{
    font-weight: 100;
    color:dimgray;
    height:24px;
    line-height: 24px;
}
.overViewDataBottomSection{
  position:relative;
  display: grid;
  overflow: auto;
  background-color: #F3F4F6;
  border:1px solid gainsboro;
}

.homePageTotalBreakdown{
  position: relative;
  text-align: center;
  width: calc(100% - 30px);
  height: 48px;
  line-height: 72px;
  background-color: #F3F4F6;
  border-radius: 5px;
  display: inline-block;
  padding-right:5px;
  border: solid 1px gainsboro;
}

.totalBreakdownRow{
  display: grid;
  width: 100%;
  height: 24px;
  grid-template-columns: calc(100% / 3 - 2px) calc(100% / 3 - 2px) calc(100% / 3 - 2px);
  grid-template-rows: auto;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
}

.activeTotalProgressContainer{
  width:90%;
  height:15px;
  border-radius: 15px;
  background-color: red;
  display: inline-grid;
}

.breakdownTimeline{
  height:min-content;
  grid-template-rows: repeat(auto, 36px);
  gap:8px;
}

.breakdownTimeline.loading{
  height:100%;
}

.breakdownTimeline.loading div{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
.breakdownTimelineRow{
  width: calc(100% - 16px);
  height: 48px;
  background-color: white;
  border-radius: 5px;
  outline: solid 2px gainsboro;
  color:dimgray;
}

.breakdownTimelineRowInner{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 36px calc(100% - 72px) 36px;
  grid-template-rows: auto;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
}
.breakdownTimelineValue{
  position: relative;
  width: 100%;
  height: 36px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  line-height: 36px;
}
.breakdownStatusIcon{
  width:28px;
  height:28px;
  border-radius: 50%;
  position:relative;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.breakdownStatusIcon svg{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height:18px;
}
.breakdownColumnContainer{
  color: dimgray;
  grid-template-columns: 48px repeat(2, 1fr) 16px;
  width:calc(100% - 32px);
}
.breakdownColumn{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 36px calc(100% - 172px) 136px;
  grid-template-rows: auto;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  padding-bottom:8px;
}
.breakdownTimelineValue.status{
  text-align: left;
  padding-left:5px;
}
.breakdownTimelineValue.count{
  text-align: right;
  padding-right:5px;
}

.fundSummaryTopSection{
  display:grid;
  grid-template-columns: 183px calc(100% - 366px) 183px;
  position:sticky;
  top:0;
}
.fundSummaryTitle{
  height:32px;
  line-height: 32px;
  font-weight: 500;
  grid-column: 2
}
.fundDataTapeDownload{
  border-radius: 5px;
  cursor: pointer;
  box-shadow: inset 0 0 0 2px #00bfff;
  color:#00bfff;
  background-color: transparent;
  font-weight: 500;
  left: 8px;
  background-color: white;
  grid-column: 3;
}
.fundDataTapeDownload:hover{
  background-color: #00BFFF;
  color:white;
}

.fundSummarySectionHeader{
  font-weight: 500;
  color:gray;
  text-align: left;
  width:100%;
}
.fundSummarySectionHeader:nth-child(4){
  margin-bottom:8px;
}

.fundSummaryContainer{
  display:inline-grid;
  gap:8px;
}
.fundSummaryContainer > div{
  grid-template-columns: repeat(4, 1fr);
  gap:8px;
}

.fundSummaryTable{
  width:100%;
  height:auto;
  min-height:100%;
}

.fundSummaryGroupSingle{
  height:100%;
  width:calc(100% - 8px);
  display:grid;
  grid-template-rows: 24px  36px 1fr;
  gap:8px;
}

.fundSummaryGroupDuo{
  height:100%;
  width:calc(100% - 8px);
  display:grid;
  grid-template-rows: 24px 36px repeat(2, 1fr);
  gap: 8px;
}

.fundSummaryGroupTrio{
  height:100%;
  width:calc(100% - 8px);
  display:grid;
  grid-template-rows: 24px 36px repeat(3, 1fr);
  gap:8px;
}

.fundSummaryGroupTitle{
  font-size:18px;
  line-height:18px;
  color: gray;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding:0 8px;
  display:block;
}
.fundSummaryCash{
  font-weight:500;
  font-size:24px;
  color:gray;
}
.fundSummaryCount{
  font-weight:500;
  font-size:24px;
  color:gray;
}

.fundSummaryGroupRow{
  display:grid;
  grid-template-columns: 50% 50%;
  font-size:16px;
  color:dimgray;
  white-space: nowrap;
}
.fundSummaryGroupRow > :last-child{
  font-weight:500;
}
.fundSumPremDueNML{
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: space-evenly;
  align-items: end;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fundSumPremDueNMR{
  display: flex;
  justify-content: end;
  justify-items: flex-start;
  align-content: space-evenly;
  align-items: end;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fundSumPremDue12ML{
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: space-evenly;
  align-items: start;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fundSumPremDue12MR{
  display: flex;
  justify-content: end;
  justify-items: flex-start;
  align-content: space-evenly;
  align-items: start;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fundSummaryGroupSingle svg, .fundSummaryGroupDuo svg{
  width:36px;
  height:36px;
}

.fundSummaryContainer > div:nth-child(1) div{
  overflow:hidden;
}
.fundSummaryContainer > div:nth-child(2) div{
  overflow:hidden;
}
.fundSummaryContainer > div:nth-child(3) div{
  overflow:hidden;
}
.fundSummaryContainer > div:nth-child(4) div{
  overflow:hidden;
}
.fundSummaryContainer svg{
  width:36px;
  height:36px;
}
.fundBreakdownTimeline{
  display:grid;
  grid-template-columns: calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5);
  height: 96px;
  width:100%;
  background-color: inherit;
  margin:auto;
  position: relative;
}
.fundBreakdownTimelineSection{
  position:relative;
  height: 100%;
  width: 100%;;
}
.fundBreakdownTimelineSection.icon{
  grid-template-rows: 20px calc(100% - 40px) 20px;
  display: grid;
}
.fundBreakdownTimelineBubble{
  border-radius: 50%;
  width: 30%;
  padding-bottom: 30%;
  display: inline-grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  max-width:48px;
  max-height:48px;
}
.fundBreakdownTimeline .fundBreakdownTimelineSection:nth-child(1) .fundBreakdownTimelineBubble svg{
  outline:solid 2px #ff3d00;
}
.fundBreakdownTimeline .fundBreakdownTimelineSection:nth-child(3) .fundBreakdownTimelineBubble svg{
  outline:solid 2px #0d47a1;
}
.fundBreakdownTimeline .fundBreakdownTimelineSection:nth-child(5) .fundBreakdownTimelineBubble svg{
  outline:solid 2px #5d4037;
}
.fundBreakdownTimelineSeperator{
  width:100%;
  height:8px;
  border-radius: 8px;
  background-color:#E2E2E2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fundBreakdownTimeline svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:48px;
  height:48px;
  border-radius:50%;
  padding:8px;
}
.fundBreakdownTimelineBubbleTitle{
  color:gray;
}
.fundBreakdownTimelineBubbleCount{
  color:gray;
}

.fundBreakdownClosed{
  display:grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: self-start;
  text-align: center;
}
.fundBreakdownClosedCategory{
  display:inline-grid;
  grid-template-rows: 48px calc(100% - 48px);
}
.fundBreakdownClosedCategoryTitle{
  height:48px;
  display:inline-grid;
  grid-template-columns: 48px calc(100% - 48px);
  font-weight: 500;
}
.fundBreakdownClosedCategoryTitle span{
  color:gray;
}
.fundBreakdownClosedCategoryTitle svg{
  height:36px;
  width: 36px;
}

.trackerTimeline{
  gap: 8px;
  width: 100%;
  height: auto;
  background-color: #F3F4F6;
}

.track .vDivider{
  width:8px;
  height:calc(100% - 16px);
}

.track .progressTrack{
  grid-template-rows: repeat(4, 48px auto) 16px 48px 200px;
  gap: 8px;
  width:100%;
}

.track .progressTrack > .category, .track .progressTrack > .header{
  grid-template-columns: 36px 1fr 36px;
  display: grid;
  width: 100%;
  gap: 8px;
}

.track .progressTrack > .header{
  font-size: 20px;
}

.track .progressTrack > .header .bold{
  grid-template-columns: 48px 96px 1fr 96px 48px;
}

.bullet{
  width:8px;
  height:8px;
  border-radius:50%;
  background-color:gray;
}

.progressTrack .category > :nth-child(2):not(.closedCat){
  display:grid;
  grid-template-rows: auto;
  gap:8px;
  border:solid 2px gray;
  padding: 8px;
  border-radius:5px;
  width: 100%;
}

.track .categoryBlock{
  border:solid 2px gainsboro;
  background-color: white;
  height:48px;
  width: 100%;
  grid-template-columns: 48px 1fr minmax(auto, 120px) 48px;
  color:dimgray;
}

.track .categoryBlock.inactive{
  border:dashed 2px gray;
  background-color: #F3F4F6;
}

.track .categoryBlock.inactive svg{
  color:dimgray;
}

/* First Section */
.track .first .vDivider,.track .first .bullet{
  background-color:#fdd835;
}

.track .first.header, .track .first.header a{
  color:#f57f17;
}

.track .first .catWrap{
  border-color:#fdd835 !important;
  background-color: #fffde7;
}
.track .first .categoryBlock{
  border-color:#fdd835;
}

.track .first .categoryBlock:not(.inactive):hover{
  background-color:#fff59d;
}

/* Second Section */
.track .second .vDivider,.track .second .bullet{
  background-color:#8bc34a;
}

.track .second.header, .track .second.header a{
  color:#33691e;
}

.track .second .catWrap{
  border-color:#8bc34a !important;
  background-color: #f1f8e9;
}

.track .second .categoryBlock{
  border-color:#8bc34a;
}

.track .second .categoryBlock:not(.inactive):hover{
  background-color:#c5e1a5;
}

/* Third Section */
.track .third .vDivider,.track .third .bullet{
  background-color:#4caf50;
}

.track .third.header, .track .third.header a{
  color:#1b5e20;
}

.track .third .catWrap{
  border-color:#4caf50 !important;
  background-color: #e8f5e9;
}

.track .third .categoryBlock{
  border-color:#4caf50;
}

.track .third .categoryBlock:not(.inactive):hover{
  background-color:#a5d6a7;
}

/* Fourth Section */
.track .fourth .vDivider,.track .fourth .bullet{
  background-color:#1e88e5;
}

.track .fourth.header, .track .fourth.header a{
  color:#1565c0;
}

.track .fourth .catWrap{
  border-color:#1565c0 !important;
  background-color: #e3f2fd;
}

.track .fourth .categoryBlock{
  border-color:#1565c0;
}

.track .fourth .categoryBlock:not(.inactive):hover{
  background-color:#90caf9;
}

/* Fifth Section */
.track .fifth .vDivider,.track .fifth .bullet{
  background-color:dimgray;
}

.track .fifth.divider{
  height:4px;
  border-radius: 4px;
  background-color:dimgray;
}

.track .fifth.header{
  color:dimgray;
}

.track .fifth .catWrap{
  border-color:#1565c0;
  background-color: #e3f2fd;
}

.track .fifth .categoryBlock{
  border-color:#1565c0;
}

.track .fifth .categoryBlock:not(.inactive):hover{
  background-color:#90caf9;
}

.category.fifth{
  grid-template-columns: 36px repeat(3, 1fr) 36px !important;
  gap:8px;
}

.fifth .closedCat{
  display:grid;
  grid-template-rows: auto 1fr auto;
  gap:8px;
  padding: 8px;
  border-radius:5px;
  width: 100%;
  background-color: white;
  color:dimgray;
  font-size: 18px;
}

.fifth .closedCat.inactive{
  border:dashed 2px gray;
  background-color: #F3F4F6;
}

.fifth .closedCat.inactive:hover{
  background-color: #F3F4F6;
}

.fifth .closedCat.inactive svg{
  color:dimgray;
}

.fifth .closedCat svg{
  width:48px;
  height:48px;
}

.closedCat.won{
  border:solid 2px #e65100;
}
.closedCat.won:hover{
  background-color: #fff3e0;
}

.closedCat.lost{
  border:solid 2px #ef5350;
}
.closedCat.lost:hover{
  background-color: #ffebee;
}

.closedCat.onHold{
  border:solid 2px #ff7043;
}
.closedCat.onHold:hover{
  background-color: #fbe9e7;
}

.breakdownBlock .graph .bar{
  height: 8px;
  width:100%;
  background-color:#EFEFEF;
  border-radius:3px;
  overflow: hidden;
  outline:solid 1px #E2E2E2;
}
.breakdownBlock .graph .progress{
  height: 8px;
}

.breakdownBlock .graph .first .bar .progress{
  background-color:#fdd835;
}
.breakdownBlock .graph .first .bold{
  color:#f57f17;
}

.breakdownBlock .graph .second .bar .progress{
  background-color: #8bc34a;
}
.breakdownBlock .graph .second .bold{
  color:#33691e;
}

.breakdownBlock .graph .third .bar .progress{
  background-color: #4caf50;
}
.breakdownBlock .graph .third .bold{
  color:#1b5e20;
}

.breakdownBlock .graph .fourth .bar .progress{
  background-color: #1e88e5;
}
.breakdownBlock .graph .fourth .bold{
  color:#1565c0;
}

.breakdownBlock.marketing .graph .row .icon{
  border-radius: 50%;
  box-sizing: border-box;
}

.breakdownBlock.marketing .graph .row.first .icon{
  background-color: #fffde7;
  border:solid 2px #fdd835;
}

.breakdownBlock.marketing .graph .row.second .icon{
  background-color: #f1f8e9;
  border:solid 2px #33691e;
}

.breakdownBlock.marketing .graph .row.third .icon{
  background-color: #e8f5e9;
  border:solid 2px #1b5e20;
}

.breakdownBlock.marketing .graph .row.fourth .icon{
  background-color: #e3f2fd;
  border:solid 2px #1565c0;
}

.chartContainer{
  overflow: auto;
}