.leadsPage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}
.leadsOverview{
    width:100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow:hidden;
    position:relative;
}
.leadsKanbanBoard{
    height: calc(100% - 48px);
    width: calc(100% - 16px);
    display: inline-block;
    border-radius: 5px;
    overflow: auto;
    background-color: inherit;
}
.leadsListBoard{
    height: calc(100% - 72px);
    width: calc(100% - 56px);
    display: inline-block;
    border-radius: 5px;
    background-color: #F3F4F6;
    text-align: left;
    padding:8px;
    padding-right: 0;
    overflow:auto;
    border: solid 1px gainsboro;
    overflow-x: hidden;;
}

.leadsListBoard::-webkit-scrollbar-track {
    background: transparent !important;
}
.leadsListBoard::-webkit-scrollbar {
    width: 18px;
}
  
.leadsListBoard::-webkit-scrollbar-thumb {
    background: dimgray;
    border: 4px transparent solid;
    background-clip: padding-box;
    border-radius: 18px;
}

.leadsTopSection{
    position: relative;
    height:48px;
    width:100%;
    display: grid;
    grid-template-columns: 300px calc(100% - 600px) 300px;
}
.leadsHeaderBar{
    height: 36px;
    line-height: 36px;
    width: 100%;
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    position:sticky;
    top:0;
    z-index: 2;
    background-color: inherit;
}
.leadsHeaderBar > div{
    display:inline-grid;
    font-weight: 500;
    position: relative;
    grid-template-columns: 67px calc(100% - 134px) 67px;
}
.categoryPrompt{
    text-align: left;
    margin-left:5px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.categoryValue{
    line-height: 0;
    text-align:right;
    margin-right:5px;
}
.categoryValue svg{
    position: relative;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
}
.categoryValueInt{
    position: relative;
    vertical-align: middle;
    display: inline-block;
}
.leadCategoryIcon{
    position:absolute;
    right:15px;
    top:50%;
    transform: translate(0, -50%);
    height:24px;
}
.leadsHeaderBar div.lead{
    color:#1b5e20;
}
.leadsHeaderBar div.contactAttempted{
    color: #01579b;
}
.leadsHeaderBar div.inProgress{
    color: #6a1b9a;
}
.leadsHeaderBar div.closed{
    color:gray;
}
.leadsDetailsBoardWrap{
    overflow: overlay;
    height: calc(100% - 72px);
    position: relative;
    bottom: 140px;
    top: 0;
    padding-bottom: 8px;
}
.leadsCategoryHeader{
    width: 100%;
    height: 36px;
    display: inline-grid;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    position:sticky;
    top:36px;
    z-index: 2;
    background-color: inherit;
}
.leadsCategoryHeaderInnerWrap{
    height: 36px;
    line-height: 36px;
    width: 100%;
    display: inline-grid;
    grid-template-columns: calc(25% - 6px) calc(25% - 6px) calc(25% - 6px) calc(25% - 6px);
    gap:8px;
}
.leadsCategoryHeaderInnerWrap > div{
    border-radius: 5px;
    width: 95%;
    height: calc(100% - 8px);
    margin: auto 2.5%;
    display: inline-grid;
    line-height: 0;
    font-weight: 500;
    position: relative;
    grid-template-columns: calc(100% - 100px) 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.leadsCategoryHeaderInnerWrap > div > *{
    vertical-align: middle;
    width:auto;
    height:24px;
    display: inline-block;
}
.leadsCategoryHeaderInnerWrap div.lead{
    border: solid 2px #2e7d32;
    color:gray;
    background-color: white;
}
.leadsCategoryHeaderInnerWrap div.contactAttempted{
    border: solid 2px #0277bd;
    color:gray;
    background-color: white;
}
.leadsCategoryHeaderInnerWrap div.inProgress{
    border: solid 2px #4527a0;
    color:gray;
    background-color: white;
}

.leadsCategoryHeaderInnerWrap div.closed{
    border: solid 2px gray;
    color:gray;
    background-color: white;
}

.leadsDeatilsBoard{
    display: inline-grid;
    width: 100%;
    height: calc(100% - 76px);
    max-width: 100%;
    border-radius: 5px;
    text-align: left;
    grid-template-columns: calc(25% - 6px) calc(25% - 6px) calc(25% - 6px) calc(25% - 6px);
    gap: 8px;
    vertical-align: top;
    position: sticky;
    top: 72px;
    overflow: hidden;
}
.leadBlockColumn{
    height: fit-content;
    width: calc(100% - 8px);
    display: inline-grid;
    border-radius: 5px;
    margin:auto;
    margin-top: 8px;
    overflow: overlay;
    overflow-x: hidden;
    max-height: calc(100% - 20px);
}
.leadBlockColumn.lead{
    outline: 2px solid #c8e6c9;
}
.leadBlockColumn.contactAttempted{
    outline: 2px dashed #b3e5fc ;
}
.leadBlockColumn.inProgress{
    outline: 2px dashed #f3e5f5;
}
.leadBlockColumn.closed{
    outline: 2px solid #e2e2e2;
}
.leadQuickOptionsCard .leadBlock{
    width:calc(100% - 8px);
    margin:auto;
}
.kanban .leadBlock{
    width:100%;
}
.leadBlock{
    width:auto;
    height: auto;
    background: white;
    border-radius: 5px;
    display: inline-block;
    margin:5px;
    vertical-align: middle;
    border: solid 1px gainsboro;
    position: relative;
    padding:5px;
    font-weight: 500;
    text-align: center;
    color:gray;
    grid-template-rows: 24px 8px 24px 24px 24px 24px 24px;
}
.kanban{
    display: inline-grid;
    text-align: center;
    width: 100%;
    /* justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    grid-template-columns: calc(100% - 36px); */
}
/* .kanban:first-child{
    margin-top:5px;
} */
.leadBlockHeader{
    display: inline-grid;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    color: gray;
    font-weight: 500;
    overflow: hidden;
    height: 32px;
    line-height: 28px;
    border:solid 2px transparent;
}
.kanban:hover .leadBlockHeader{
    border-color: #E2E2E2;
}
.lead .leadBlockHeader:hover + .leadBlock{
    background-color: #e8f5e9;
}
.contactAttempted .leadBlockHeader:hover + .leadBlock{
    background-color: #e3f2fd;
}
.inProgress .leadBlockHeader:hover + .leadBlock{
    background-color: #f3e5f5;
}
.leadBlockHeaderOption{
    cursor:pointer;
    vertical-align: middle;
    display: inline-block;
    position: relative;
}
.leadBlockHeaderOption svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.leadBlockHeaderOption:nth-child(1):hover{
    background-color: #ffebee;
    color: #ef5350;
}
.leadBlockHeaderOption:nth-child(2):hover{
    background-color: #e3f2fd;
    color:#0277bd;
}
.leadBlockHeaderOption:nth-child(3):hover{
    background-color: #f3e5f5;
    color:#6a1b9a;
}

.leadBlock.error{
    background-color: #EFEFEF !important;
    color:gray;
    border-color: gray !important;
}
.leadBlock.error *{
    color:gray;
}
.leadBlock.error .divider{
    background-color: gray;
}
.leadBlock.error::before{
    position:absolute;
    left:50%;
    top:50%;
    width:150px;
    height:32px;
    line-height: 32px;
    font-size: 20px;
    transform: translate(-50%, -50%);
    background-color:transparent;
    content:'Update Error';
    background-color:gray;
    color:white;
    border:solid 2px transparent;
    border-radius:5px;
    padding:5px;
    z-index: 2;
    box-shadow: 0 0 5px #ccc;;
}

.leadBlock.result{
    width:100%;
    height:210px;
    margin:0;
}
.leadBlock > div{
    text-align: left;
    font-weight: 400;
}
.leadBlock > div > svg, .leadData{
    position:relative;
    vertical-align: middle;
    display: inline-block;
    margin-right:5px;
}
.leadInfoBar{
    height:24px;
    line-height: 1;
    display:grid;
    grid-template-columns: auto auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.leadBlockColumn .abacus .leadInfoBar, .leadBlockColumn .insurance .leadInfoBar, .leadBlockColumn .lost .leadInfoBar{
    grid-template-columns: 100%;
}

.leadInfoBar > div{
    font-weight: 500 !important;
    display:inline-grid;
}
.leadTimestamp{
    text-align: right;
    height: 24px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    line-height: 20px;
}
.leadName, .leadHealth, .leadDeathBenefit, .leadPolicyType, .leadPhoneNumber{
    height:32px;
    line-height: 32px;
    position: relative;
}
.leadName > div, .leadHealth > div, .leadDeathBenefit > div, .leadPolicyType > div, .leadPhoneNumber > div{
    max-width:calc(100% - 36px);
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.leadStatusIcon{
    text-align: left;
    height: 24px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display:inline-grid;
    grid-template-columns: 26px calc(100% - 26px);
}

.leadStatusIcon > *{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: left;
    display: inline-grid;
}
.leadQuickOptionsCard .leadBlock{
    border:solid 2px gray;
    background-color:white;
}
.leadQuickOptionsCard .leadBlock:hover{
    background-color: transparent !important;
}
.lead .leadBlock, .lead.leadBlock{
    border:solid 2px #2e7d32;
    background-color:white;
}
.contactAttempted .leadBlock, .contactAttempted.leadBlock{
    border:solid 2px #0277bd;
    background-color:white;
}
.inProgress .leadBlock, .inProgress.leadBlock{
    border:solid 2px #6a1b9a;
    background-color:white;
}
.leadQuickOptionsCard.closed .leadBlock{
    border-color:#ef5350;
}
.closed .divider{
    background-color:#ef5350;
}
.leadQuickOptionsCard .divider{
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.lead .leadBlock .divider{
    background-color: #2e7d32;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.contactAttempted .leadBlock .divider{
    background-color: #0277bd;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.inProgress .leadBlock .divider{
    background-color: #6a1b9a;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.lead .leadBlock:hover, .leadBlock:hover + .leadBlockHeader{
    background-color: #e8f5e9;
}
.contactAttempted .leadBlock:hover{
    background-color: #e3f2fd;
}
.inProgress .leadBlock:hover{
    background-color: #f3e5f5;
}
.lost .leadBlock:hover, .abacus .leadBlock:hover, .insurance .leadBlock:hover{
    background-color:transparent !important;
}

.leadBlock.abacus{
    border:solid 2px #004d40;
    background-color: #e0f2f1;
    color:#004d40;
}
.leadBlock.insurance{
    border:solid 2px #ff9800;
    background-color: #fff3e0;
    color:#ff9800;
}
.leadBlock.lost{
    border:solid 2px #ef5350;
    color:#ef5350;
    background-color: #ffebee;
}
.leadBlock.abacus:hover, .leadBlock.insurance:hover, .leadBlock.lost:hover{
    background-color:white;
    color:gray;
    border-color:gray;
}
.lead.leadBlock.result:hover{
    background-color: #e8f5e9;
}
.contactAttempted.leadBlock.result:hover{
    background-color: #e3f2fd;
}
.inProgress.leadBlock.result:hover{
    background-color: #f3e5f5;
}


/* List View */
.leadsListFilterPanelWrap{
    width: 300px;
    height: 100%;
    background-color: white;
    outline: solid 1px gainsboro;
    border-radius: 5px;
    margin: auto;
    position: sticky;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 48px;
    font-weight: 500;
    color: gray;
    z-index: 2;
    display: inline-grid;
    vertical-align: top;
    overflow-y: hidden;
}
.leadsListFilterPanel{
    width: 100%;
    max-height: 100%;
    direction: rtl;
    overflow-y: overlay;
    background-color:inherit;
    padding-bottom:48px;
}
.leadsListFilterPanel > *{
    direction:ltr;
}
.leadListHeaderToggle{
    display:inline-block;
    border:solid 2px gray;
    border-radius:5px;
    height:36px;
    width:120px;
    line-height:36px;
    vertical-align: middle;
    margin:auto 10px;
    padding:0 4px;
    cursor:pointer;
}

/* Single Lead View */
.leadDetailsCardTitle{
    width: 100%;
    display: inline-block;
    min-height: 112px;
    line-height: 32px;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: inherit;
}
.leadDetailsCardTitle .activityTimelineHeader{
    text-align: center;
    left:unset;
    width: 100%;
    grid-template-columns: 50% 50%;
}
.leadDetailsCardTitle .activityTimelineHeader > *{
    display:inline-grid;
}
.leadDetailsCardTitle .activityTimelineHeader > .leadTimelineBtn{
    width:calc(100% - 16px);
}
.leadTimelineBtn{
    display:inline-block;
    border-radius: 5px;
    width:calc(100% / 3 - 10px);
    margin:auto 5px;
    text-align:center;
    font-weight: 500;
    cursor:pointer;
    box-shadow: inset 0 0 0 2px #00bfff;
    color: #00bfff;
    background-color: transparent;
    border-color: transparent;
    position: relative;
}
.leadTimelineBtn:hover{
    border:none;
    background-color: #00bfff;
    color:white;
}
.leadTimelineBtn svg, .transferBtn svg{
    position:absolute;
    left:5px;
    top:50%;
    transform: translate(0, -50%);
}
.leadTimelineBtn.inactive, .transferBtn.inactive{
    background-color: transparent;
    color: #E2E2E2;
    box-shadow: inset 0 0 0 2px #e2e2e2;
}
.leadTimelineBtn.inactive:hover, .transferBtn.inactive:hover{
    background-color: transparent;
    color: #E2E2E2;
    box-shadow: inset 0 0 0 2px #e2e2e2;
}
.transferBtn{
    display:inline-block;
    border-radius: 5px;
    width:calc(100% - 16px);
    margin:auto 5px;
    text-align:center;
    font-weight: 500;
    cursor:pointer;
    box-shadow: inset 0 0 0 2px #4caf50;
    color: #4caf50;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    height:36px;
    line-height: 36px;
}
.transferBtn:hover{
    border:none;
    background-color: #4caf50;
    color:white;
}
.leadDetailsCardFace{
    background-color: #F3F4F6;
    display: inline-block;
    color: gray;
    position: relative;
    box-shadow: 0 0 5px #ccc;
    overflow: auto;
    border: solid 1px gainsboro;
}

.leadBlock.abacus.result{
    border:solid 2px #004d40;
    background-color: white;
    color:gray;
}
.leadBlock.abacus.result .leadCallCount svg{
    color: #004d40;
}
.abacus .divider{
    background-color: #004d40;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.leadBlock.insurance.result{
    border:solid 2px #ff9800;
    background-color: white;
    color:gray;
}
.leadBlock.insurance.result .leadCallCount svg{
    color: #ff9800;
}
.insurance .divider{
    background-color: #ff9800;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}
.leadBlock.lost.result{
    border:solid 2px #ef5350;
    background-color: white;
    color:gray;
}
.leadBlock.lost.result .leadCallCount svg{
    color: #ef5350;
}
.lost .divider{
    background-color: #ef5350;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}

.leadBlock.lead .divider{
    background-color: #2e7d32;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}

.leadBlock.contactAttempted .divider{
    background-color: #0277bd;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}

.leadBlock.inProgress .divider{
    background-color: #6a1b9a;
    border-radius: 3px;
    display: block;
    height: 2px;
    overflow: hidden;
    width: 75%;
    margin: 5px auto;
}

.leadBlock.abacus.result:hover{
    background-color:#e0f2f1;
}
.leadBlock.insurance.result:hover{
    background-color:#fff3e0;
}
.leadBlock.lost.result:hover{
    background-color:#ffebee;
}
.leadFormBtnSection{
    width:100%;
    height:36px;
    text-align: right;
    line-height: 36px;
    position:relative;
    text-align: left;
    margin-top:15px;
}
.leadFormBtnSection div:last-of-type{
    float: right;
}
.leadFormBottomSection{
    position: absolute;
    bottom:8px;
    left:0;
    width:100%;
    height:36px;
    text-align: right;
    line-height: 36px;
}
.leadFormBottomSection .leadTimelineBtn{
    width:calc(100% / 2 - 10px);
}

.singleLeadActionBar{
    min-width: 200px;
    width: auto;
    height: 36px;
    display: inline-grid;
    grid-template-columns: 25px calc(100% - 50px) 25px;
    color:gray;
    border-radius:5px;
    line-height: 36px;
    padding:0 8px;
    cursor:pointer;
}
.singleLeadActionBar:hover{
    background-color:#EFEFEF;
    outline:solid 1px gray;
}
.assignedIcon{
    display:inline-grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.assignedToHandler{
    display:inline-grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.assignedToDropdown{
    display:inline-grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.timelineFollowUp{
    left: 35px;
    min-height: 36px;
    position: sticky;
    width: calc(100% - 44px);
    grid-template-rows: 36px 2px minmax(36px, auto);
}
.timelineFollowUp .header{
    grid-template-columns: 36px 1fr auto;
}
.timelineFollowUp .content{
    grid-template-columns: 50px calc(100% - 200px) 100px  50px;
}
.timelineFollowUp.completed{
    border:solid 2px #4caf50;
    background-color:#e8f5e9;
    color:#4caf50;
    left: 35px;
    min-height: 36px;
}
.timelineFollowUp.completed .divider{
    background-color: #4caf50;
}
.timelineFollowUp.followUp{
    border:solid 2px #6a1b9a;
    background-color:#f3e5f5;
    color:#6a1b9a;
    left: 35px;
    min-height: 36px;
}
.timelineFollowUp.followUp .divider{
    background-color: #6a1b9a;
}
.timelineFollowUp.missed{
    background-color: #ffebee;
    border: 2px solid #ef5350;
    color: #ef5350;
}
.timelineFollowUp.missed .divider{
    background-color: #ef5350;
}

.followUpIcon{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display:inline-grid;
}
.followUpPrompt{
    justify-content: left;
    justify-items: center;
    align-content: space-evenly;
    align-items: normal;
    display: inline-grid;
}
.followUpCheckBox{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display:inline-grid;
    cursor:pointer;
}
.showMoreFollowUp{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display:inline-grid;
    cursor:pointer;
    color:#00BFFF
}
.showMoreFollowUp:hover{
    color:#01579b;
}
/* leadOptionsBoard */
.leadUpdatePanelBoard{
    position: absolute;
}
.leadOptionsBoard{
    z-index: 999;
    position: absolute;
    text-align: center;
    right: 0;
    top: auto;
    width: 100%;
    display: inline-block;
    padding: 1.25%;
    height: 100%;
}
.leadOptionsBoardInnerWrap{
    position: relative;
    width: 500px;
    height:auto;
    border-radius: 5px;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}
.leadOptionsPanel{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
}
.leadQuickOptions{
    display: inline-block;
    height: calc(100% - 264px);
    width: calc(100% - 5px);
}
.leadQuickOptionsNav{
    border-radius: 5px;
    height: 48px;
    width: calc(100% - 5px);
    margin: auto;
    text-align: center;
    line-height: 48px;
}
.leadQuickTopBtn{
    width:calc(100% / 3 - 4px);
    height:36px;
    line-height: 32px;
    margin:auto 2px;
    border:solid 2px gray;
    color:dimgray;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
    cursor:pointer;
    font-weight:500;
}
.leadQuickChangeBtn{
    width:calc(100% - 4px);
    height:100%;
    line-height: 32px;
    margin:auto 2px;
    border:solid 2px gray;
    color:dimgray;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
    cursor:pointer;
    font-weight:500;
    position:relative;
}
.leadQuickChangeBtn > svg{
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    position:absolute;
    margin:auto;
}
.leadQuickChangeBtn.closed svg{
    color: #ef5350;
}
.leadQuickChangeBtn.closed:hover, .leadQuickChangeBtn.closed.selected{
    background-color: #ffebee;
    color: #ef5350;
    border-color: #ef5350;
}
.leadQuickChangeBtn.inProgress svg{
    color:#6a1b9a;
}
.leadQuickChangeBtn.inProgress:hover, .leadQuickChangeBtn.inProgress.selected{
    background-color: #f3e5f5;
    color: #6a1b9a;
    border-color: #6a1b9a;
}
.leadQuickTopBtn.selected:nth-child(1){
    background-color: #ffebee;
    color: #ef5350;
    border-color: #ef5350;
}
.leadQuickTopBtn:nth-child(1):hover{
    color: #ef5350;
    border-color: #ef5350;
}
.leadQuickTopBtn.selected:nth-child(2){
    background-color: #e3f2fd;
    color:#0277bd;
    border-color: #0277bd;
}
.leadQuickTopBtn:nth-child(2):hover{
    color:#0277bd;
    border-color: #0277bd;
}
.leadQuickTopBtn.selected:nth-child(3){
    background-color: #f3e5f5;
    color:#6a1b9a;
    border-color: #6a1b9a;
}
.leadQuickTopBtn:nth-child(3):hover{
    color:#6a1b9a;
    border-color: #6a1b9a;
}
.leadQuickOptionsActionsPanel{
    border-radius: 5px;
    height:100px;
    height:106px;
    width:calc(100% - 5px);
    border:solid 2px transparent;
    margin:auto;
    margin-top:2px;
    display: grid;
    grid-template-columns: calc(100% / 3 - 20px) 30px calc(100% / 3 - 20px) 30px calc(100% / 3 - 20px);
    transition: 0s;
}
.leadQuickOptionsActionsPanel div{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.leadQuickOptionsActionsBtn{
    border-radius: 50%;
    border:solid 3px #EFEFEF;
    color:#EFEFEF;
    width:100px;
    height:100px;
    display: inline-block;
    margin:auto;
    vertical-align: middle;
    display: inline-grid;
}
.leadQuickOptionsActionsBtn.available{
    color:gray;
    border:solid 3px gray;
    cursor:pointer;
}
.leadQuickOptionsActionsBtn.available:hover{
    color:#0277bd;
    border-color:#0277bd;
    cursor:pointer;
}
.leadQuickOptionsActionsBtn.selected.contactAttempted{
    background-color: #e3f2fd;
    color:#0277bd;
    border-color:#0277bd;
    cursor:pointer;
}
.leadQuickOptionsActionsBtn.selected:hover{
    background-color: #ffebee;
    color:#ef5350;
    border-color:#ef5350;
}
.leadQuickOptionsActionSeperator{
    border-radius: 3px;
    height:3px;
    width:30px;
    background-color:gray;
    margin:auto;
    vertical-align: middle;
    display: inline-grid;
}
.leadQuickOptionsActionSeperator.available{
    background-color: #EFEFEF;
}
.leadQuickOptionsActionSeperator.selected.contactAttempted{
    background-color: #0277bd;
}
.updateLeadQuickAccess{
    border-radius: 5px;
    height:36px;
    width: calc(100% / 3 - 10px);
    line-height: 36px;
    margin: 8px;
    margin-top:8px !important;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px #00bfff;
    color: #00bfff;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    float:right;
}
.updateLeadQuickAccess.inactive{
    color:#E2E2E2;
    box-shadow:inset 0 0 0 2px #E2E2E2;
}
.updateLeadQuickAccess:hover, .quickAccessAddNote:hover{
    border:none;
    background-color: #00bfff;
    color:white;
}
.updateLeadQuickAccess.inactive:hover{
    cursor: default;
    background-color:transparent;
    color:#E2E2E2;
    box-shadow:inset 0 0 0 2px #E2E2E2;  
}
.quickAccessAddNote.inactive{
    color:#00bfff;
    box-shadow:inset 0 0 0 2px #E2E2E2;  
}
.quickAccessAddNote.inactive:hover{
    cursor:pointer !important;
    box-shadow: inset 0 0 0 2px #00bfff;
    color:white;
}
.viewMoreLead{
    position:absolute;
    bottom:5px;
    right:5px;
    height:16px;
    line-height: 16px;
    color:gray;
    padding:0 5px;
    cursor:pointer;
}
.viewMoreLead:hover{
    color:#00bfff;
}

.emptyKanbanCategory{
    font-weight: 500;
    width: 100%;
    height: 190px;
    display:grid;
}
.emptyKanbanMessage{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display:inline-grid;
    text-align: center;
    padding:5px;
}
.lead .emptyKanbanMessage{
    color:#2e7d32;
}
.contactAttempted .emptyKanbanMessage{
    color: #01579b;
}
.inProgress .emptyKanbanMessage{
    color: #6a1b9a;
}
.kanbanHeaderCategory{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.leadNoteCreate{
    height:166px;
    width:100%;
    display:inline-block;
}
.leadNoteCreate .messageBox{
    position:relative;
    width:calc(100% - 36px);
    height:150px;
    max-height:150px;
    display:inline-block;
    margin:auto;
}
.defaultLeadprompt{
    position: relative;
    width: calc(100% - 8px);
    height:106px;
    border: solid 2px #E2E2E2;
    border-radius: 5px;
    display: inline-block;
    margin-top: 2px;
    color: gray;
    line-height: 100px;
    font-size:24px;
    font-weight:500;
}
.leadNoteIcon svg{
    width:48px;
    height:48px;
    display: inline-block;
}
.inlineLoading{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    display: inline-grid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: inherit;
}
.inlineLoading span{
    color:inherit;
}
.inlineLoading svg{
    height:28px;
    width:28px;
    color:inherit;
}
.followUpDateTimeSelector{
    width:calc(100% - 8px) !important;
    height:36px !important;
    line-height: 36px !important;
    float: left;
    margin-left: 8px !important;
    margin-top: 8px !important;
}
.followUpDateTimeSelector *{
    font-weight:500 !important;
    font-family: inherit !important;
}
.followUpDateTimeSelector input{
    color:gray !important;
    font-weight:500 !important;
    font-family: inherit !important;
    width: -webkit-fill-available !important;
}
.followUpDateTimeSelector.error input{
    color:#ef5350 !important;
}
.followUpDateTimeSelector.awaiting input{
    color:#E2E2E2 !important;
}
.followUpDateTimeSelector svg{
    color:#00bfff !important;
}
.followUpDateTimeSelector.error svg{
    color:#ef5350 !important;
}
.followUpDateTimeSelector > div{
    height:36px !important;
}
.followUpDateTimeSelector fieldset{
    border-color:#00bfff !important;
    border-width:2px !important;
}
.followUpDateTimeSelector.error fieldset{
    border-color:#ef5350 !important;
    border-width:2px !important;
}
.followUpDateTimeSelector.awaiting fieldset{
    border-color:#E2E2E2 !important;
    border-width:2px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    left: 8px !important;
    top: 50% !important;
    transform: translate(0, -50%) !important;
}
.Mui-focused{
    color:gray !important;
}
.followUpTimer{
    display: inline-block;
    width: auto;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #6a1b9a;
    color:white;
    padding: 0 8px;
    line-height: 24px;
    height: 24px;
    font-weight: 500;
}
.followUpRemainingTime{
    position: absolute;
    bottom: 8px;
    right: 5px;
    height: auto;
    line-height: 16px;
    color: gray;
    padding: 0 5px;
    cursor: default;
    display: grid;
    grid-template-columns: 16px auto;
    gap: 8px;
}
.followUpRemainingTime.inline{
    bottom:24px;
}
.missedFollowUp{
    position: absolute;
    bottom: 8px;
    right: 5px;
    height: auto;
    line-height: 16px;
    color: gray;
    padding: 0 5px;
    cursor: default;
    display: grid;
    grid-template-columns: 16px auto;
    gap: 8px;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
}
.missedFollowUp.detailOnly{
    bottom: 24px;
}
.missedFollowUp.inline{
    bottom: 40px;
}
.missedFollowUp.inlineX2{
    bottom: 56px;
}
.missedFollowUpCount{
   width: auto;
   margin-left: 5px;
   border-radius: 5px;
   background-color: #ef5350;
   color:white;
   padding: 0 8px;
   line-height: 24px;
   height: 24px;
   font-weight: 500;
   display: grid;
   justify-content: space-evenly;
   justify-items: center;
   align-content: space-evenly;
   align-items: center;
   text-align: center;
}
.quickAccessAddNote{
    border-radius: 5px;
    height: 36px;
    width: calc(100% - 8px);
    line-height: 36px;
    margin: 8px;
    margin-top: 8px !important;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px #00bfff;
    color: #00bfff;
    background-color: transparent;
    border-color: transparent;
    position: relative;
}
.quickAccessAddNote svg{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.leadQuickOptionsActionBar{
    display: block;
    width: 100%;
    height: 52px;
    grid-template-columns: 100%;
    text-align: right;
}
.leadQuickOptionsActionBar.extended{
    display: grid;
    grid-template-columns: 274px 60px calc(100% / 3 - 10px);
}
.leadQuickOptionsActionBar.extended > div{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.leadQuickOptionsActionBar.extended > .updateLeadQuickAccess{
    width:calc(100% - 8px);
}
.quickCreateNoteBlock{
    letter-spacing: inherit;
    border: solid 1px #EFEFEF;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    animation-duration: 10ms;
    resize: none;
    font-family: inherit;
    max-height: 210px;
    height:210px;
    width:calc(100% - 8px);
    margin:auto;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px #E2E2E2;
    position:relative;
    padding:8px;
}
.quickCreateNoteBlock:focus{
    box-shadow: inset 0 0 2px #ccc;
    outline:none;
}

.followUpTimeDetails{
    color:gray;
    margin-top:10px;
    text-align: left;
}
.followUpToggle{
    position:absolute;
    bottom:15px;
    right:15px;
    color:gray;
}

.leadsOverview .listAltercations{
    right:0;
    line-height:32px;
}
.leadsOverview .listAltercations > div{
    vertical-align: middle;
}
.leadTeamFilterPrompt{
    display:inline-block;
}
.kanbanNotifications{
    text-align: left;
    gap: 8px;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-template-rows: 100%;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
}
.missedFollowUpsBubble{
    background-color: #ef5350;
    color: white;
    height: 24px;
    min-width: 24px;
    padding: 0 4px;
    border-radius: 5px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    grid-column:1;
}
.missedFollowUpsBubble.active {
    background-color: inherit;
    color: #ef5350;
    outline: 3px solid #ef5350;
    box-sizing: border-box;
    height: 24px;
}
.activeFollowUpsBubble{
    background-color: #6a1b9a;
    color: white;
    height: 24px;
    min-width: 24px;
    padding: 0 4px;
    border-radius: 5px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    grid-column:2;
}
.activeFollowUpsBubble.active {
    background-color: inherit;
    color: #6a1b9a;
    outline: 3px solid #6a1b9a;
    box-sizing: border-box;
    height: 24px;
}
.leadsViewSelector{
    display:inline-grid;
    grid-template-columns:repeat(2, 50%);
    margin:auto;
    width: 100%;
}
.leadsViewFilters{
    display: inline-grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    margin: auto;
    width: 100%;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
}
.leadsSearchBarContainer{
    display: inline-flex;
    width: 100%;
    height: 100%;
    text-align: center;
    position:relative;
}
.leadsSearchBar{
    height: 32px;
    border-radius: 5px;
    border-style: none;
    background-color: #F3F4F6;
    padding: 8px;
    padding-left:132px;
    width: 100%;
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
    left: 0;
    border: solid 1px gainsboro;
}
.leadsSearchBar:focus{
    outline-color: gray !important;
}
.leadsSearchBarSwitch{
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    display: inline-grid;
    width: 120px;
    margin: auto;
    border-radius: 2.5px;
    background-color: white;
    height: 24px;
    text-align: left;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    color: #00bfff;
    outline:solid 1px #00bfff;
    font-weight: 500;
    cursor:pointer;
}
.leadsOverview .listAltercationOption{
    display: inline-grid;
    width: 120px;
    margin: auto;
}
.leadsFiltersHeader{
    display: inline-block;
    position: sticky;
    top: 0;
    width: 100%;
    height: 46px;
    background-color: white;
    z-index: 3;
    text-align: center;
    line-height: 46px;
}
.leadsListInfo{
    display: block;
    height:auto;
    position: sticky;
    top:0;
    background-color: inherit;
    z-index: 1;
}
.leadsListCategories{
    display:inline-block;
    width:95%;;
}
.leadsListResults{
    width: calc(100% - 316px);
    display: inline-grid;
    position: relative;
    top: 0;
    vertical-align: middle;
    grid-template-columns: calc(100% / 4 - 4px) calc(100% / 4 - 4px) calc(100% / 4 - 4px) calc(100% / 4 - 4px);
    gap: 4px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    margin-left: 8px;
}
.leadsListCategory{
    display: grid;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    font-weight: 400;
    text-align: left;
    padding-left: 8px;
    position: relative;
    margin: 4px auto;
    grid-template-columns: 24px calc(100% - 96px) 72px
}
.leadsListCategory span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
.leadsListCategoryTitle{
    width:calc(100%);
}

.leadsListCategories > div:hover{
    background-color:#EFEFEF;
    cursor:pointer !important;
}
.leadsListCategory svg{
    vertical-align: middle;
    margin-right:8px;
}
.leadsListCategorySection{
    width:90%;
    height:auto;
    display:inline-block;
    margin-top:16px;
}

.leadsListCategorySection.lead svg{
    color:#1b5e20;
}
.leadsListCategorySection.contactAttempted svg{
    color:#01579b;
}
.leadsListCategorySection.inProgress svg{
    color:#6a1b9a;
}
.leadsListCategorySection.lost svg{
    color:#ef5350;
}
.leadsListCategoryHeader.success svg{
    color:#00BFFF;
}
.leadsListCategorySection .abacus svg{
    color:#004d40;
}
.leadsListCategorySection .insurance svg{
    color:#ff9800;
}
.leadsListCategory.inactive:hover, .leadsListCategory.inactive:hover svg{
    color:gray !important;
}
.leadsListCategory.inactive svg, .leadsListCategory.inactive{
    color:#E2E2E2;
}

.leadsListCategoryHeader{
    display: block;
    width: 100%;
    height: 36px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    line-height: 36px;
    margin:auto;
}
.leadsListCategoryHeader.lead{
    color:#1b5e20;
}
.leadsListCategoryHeader.contactAttempted{
    color:#01579b;
}
.leadsListCategoryHeader.inProgress{
    color:#6a1b9a;
}
.leadsListCategoryHeader.lost{
    color:#ef5350;
}
.leadsListCategoryHeader.success{
    color:#00BFFF;
}
.leadsListCategorySection.inactive div, .leadsListCategorySection.inactive svg{
    color:#E2E2E2 !important;
}
.leadsListCategorySection.inactive div:hover, .leadsListCategorySection.inactive div:hover > svg{
    color:gray !important;
}
.leadsListCategorySection.inactive .leadListDivider{
    background-color:gray !important;
}
.inactive .leadsListCategoryHeader:hover svg{
    color:gray !important;
}
.leadListDivider{
    width:65%;
    border-radius:5px;
    height:3px;
    margin:auto;
}
.leadListDivider.lead{
    background-color:#2e7d32;
}
.leadListDivider.contactAttempted{
    background-color:#0277bd;
}
.leadListDivider.inProgress{
    background-color:#4527a0;
}
.leadListDivider.lost{
    background-color:#ef5350;
}
.leadListDivider.success{
    background-color:#00BFFF;
}
.leadsListAttrFilters{
    width: calc(100% - 72px);
    display: block;
    margin: auto;
}
.leadsListInfo .MuiBox-root{
    display:inline-block;
}
.leadsListInfo .MuiBox-root span span:nth-child(1){
    color:#4DD2FF;
}
.leadsListInfo .MuiBox-root span > span:last-child, .leadsListInfo .MuiBox-root span > span:nth-child(3){
    color:#0C77E8;
}
.leadsListInfo .MuiBox-root span > span:last-child *, .leadsListInfo .MuiBox-root span > span:nth-child(3) *{
    color:white;
}
.leadsListInfo .MuiBox-root span span:nth-child(2){
    color:#00bfff;
}
.leadsListInfo .MuiSlider-mark{
    width:4px;
    height:4px;
    border-radius: 50%;;
}

.leadsListCategoryCount{
    position:absolute;
    height: auto;
    right:8px;
    font-size:12px;
    top:50%;
    transform: translate(0, -50%);
    line-height: 12px;
    text-align: center;
}
.leadsListCategoryHeader .leadsListCategoryCount{
    position: absolute;
    left: 16px;
    right: unset;
    font-size: 12px;
}
.leadsListBtnSection{
    position:absolute;
    bottom:0;
    left: 8px;
    height:48px;
    width:calc(100% - 8px);
    background-color: inherit;
    grid-template-columns: 50% 50%;
}
.leadsListBtnSection .g{
    width:100%;
}

.toggleAllLeadFiltersBtn{
    color: white;
    background-color: #00BFFF;
    border-radius: 5px;
    width: 150px !important;
    height: 32px;
    line-height: 32px;
    text-align: center;
    box-shadow: inset 0 0 0 2px #00bfff;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 2;
    cursor: pointer;
    display: inline-grid;
}
.toggleAllLeadFiltersBtn:hover{
    color: #00BFFF;
    background-color: white;
    box-shadow: inset 0 0 0 2px #00bfff;
    width: 150px !important;
    height: 32px;
    line-height: 32px;
    text-align: center;
}

.leadsListIgnoreList{
    max-height:calc(100% - 96px);
    overflow-y: auto;
}
.leadsListIgnoreBtn{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 48px;
    background-color: inherit;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
}
.leadsListIgnoreBtn > div{
    width:100%;
}
.leadsFiltersToggleWrap{
    color: dimgray;
    height: auto;
    display: grid;
    margin-bottom: 36px;
    row-gap:8px;
    padding: 8px 0;
}
.leadsFiltersToggleWrap > div{
    display:inline-grid;
}
.leadsFiltersSection{
    width:80%;
    margin:auto;
    display:inline-block;
    margin-top:8px;
}
.toggleContainer{
    margin-top:8px;
}

.lead .toggleInnerWrap:not(.off){
    background-color: #4caf50;
    box-shadow: inset 0 0 0 2px #4caf50;
}
.lead .toggleBtn{
    border-color:#4caf50;
}

.contactAttempted .toggleInnerWrap:not(.off){
    background-color: #2196f3;
    box-shadow: inset 0 0 0 2px #2196f3;
}
.contactAttempted .toggleBtn{
    border-color:#2196f3;
}

.inProgress .toggleInnerWrap:not(.off){
    background-color: #6a1b9a;
    box-shadow: inset 0 0 0 2px #6a1b9a;
}
.inProgress .toggleBtn{
    border-color:#6a1b9a;
}

.kanbanSpace{
    height:24px;
    width:24px;
    display: inline-grid;
}

.leadsFiltersModuleInner{
    width: 100%;
    height: 100%;
    overflow: overlay;
    background-color: inherit;
    padding-bottom:8px;
}

.leadsFilterModule{
    background-color: white;
    width: 550px;
    height: 75%;
    z-index: 3;
    position: absolute;
    margin: auto;
    display: block;
    bottom: 0px;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.leadInlineTimelineBtnContainer {
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: calc(50% - 16px) calc(50% - 16px);
    gap: 32px;
    justify-items: center;
    align-items: center;
  }
  
  .leadInlineTimelineBtn{
    width: 100%;
    height: 28px;
    line-height: 20px;
    cursor: pointer;
    border: solid 2px transparent;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 500;
}
.leadInlineTimelineBtn:hover{
    color:#00BFFF;
}
.leadInlineTimelineBtn > *{
    vertical-align: middle;
}
.leadInlineTimelineSeparator{
    display:block;
    height:2px;
    width:80%;
    background-color:#E2E2E2;
    border-radius:3px;
    margin:auto;
}

.leadSendAppBtn, .leadDownloadAppBtn{
    border-radius: 5px;
    height: 36px;
    min-width: calc(100% / 3 - 10px);
    line-height: 32px;
    margin: 8px;
    margin-top: 8px !important;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px #00bfff;
    color: #00bfff;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    float: right;
}
.leadDownloadAppBtn{
    float:left;
}
.leadSendAppBtn.inactive:hover, .leadDownloadAppBtn.inactive:hover {
    cursor: default;
    background-color: transparent;
    color: #E2E2E2;
    box-shadow: inset 0 0 0 2px #e2e2e2;
}
.leadSendAppBtn:hover, .leadDownloadAppBtn:hover {
    border: none;
    background-color: #00bfff;
    color: white;
}
.leadSendAppBtn.inactive, .leadDownloadAppBtn.inactive {
    color: #E2E2E2;
    box-shadow: inset 0 0 0 2px #e2e2e2;
}
.leadSendAppBtn > *, .leadDownloadAppBtn > *{
    vertical-align: middle;
}
.leadSendAppBtn svg, .leadDownloadAppBtn svg{
    margin-right:8px;
}
.leadSendAppPrompt{
    width:100%;
    height:36px;
    line-height:36px;
    text-align: left;
    padding-left:8px;
    font-weight: 500;
    color:dimgray;
}
.leadSendAppBtn .inlineLoading *, .leadDownloadAppBtn .inlineLoading *{
    width:28px !important;
    height: 28px !important;
    margin:0;
}
.leadData input{
    background-color: inherit;
    outline:none;
    border: none;
    font-family:inherit;
    font-size: inherit;
    color:inherit;
    cursor:pointer;
    font-weight:500;
}

/* Lead Row */

.leadRow{
    border-radius: 5px;
    width: calc(100% - 8px);
    height: 36px;
    line-height: 32px;
    display: grid;
    grid-template-columns: 48px calc(100% - 240px) 96px 96px;
    margin: 8px auto;
    cursor: pointer;
    background-color: white;
    font-weight:500;
}

.leadRow.lead{
    border:solid 2px #2e7d32;
}
.leadRow.lead:hover, .leadRow.lead.selected{
    background-color:#e8f5e9;
}
.leadRow.contactAttempted{
    border:solid 2px #0277bd;
}
.leadRow.contactAttempted:hover, .leadRow.contactAttempted.selected{
    background-color: #e3f2fd;
}
.leadRow.inProgress{
    border:solid 2px #6a1b9a;
}
.leadRow.inProgress:hover, .leadRow.inProgress.selected{
    background-color:#f3e5f5;
}
.leadRow.lost{
    border:solid 2px #ef5350;
}
.leadRow.lost:hover, .leadRow.lost.selected{
    background-color:#ffebee;
}
.leadRow.abacus{
    border:solid 2px #004d40;
}
.leadRow.abacus:hover, .leadRow.abacus.selected{
    background-color: #e0f2f1;
}
.leadRow.insurance{
    border:solid 2px #ff9800;
}
.leadRow.insurance:hover, .leadRow.insurance.selected{
    background-color: #fff3e0;
}
.leadRow > div{
    display:inline-grid;
}
.leadRow > div:nth-child(2){
    overflow:hidden;
    white-space: nowrap;
}
.leadRow > div:last-of-type{
    padding-right:8px;
}
.toggleUnassignedLeads{
    border-radius: 5px;
    line-height: 32px;
    text-align: center;
    z-index: 2;
    cursor: pointer;
    color: dimgray;
    display: inline-grid;
    padding: 0 8px;
    width: 200px;
    height: 32px;
}
.toggleUnassignedLeads:hover{
    background-color:#EFEFEF;
}
.toggleUnassignedLeads .checkboxField{
    height:32px;
    line-height: 30px;
}
.toggleUnassignedLeads .fieldName{
    font-weight: 500;
    line-height: 24px;
    vertical-align: middle;
}

.selector .leadCategory{
    grid-template-columns: 60px calc(100% - 97px) 37px;
}
.selector .leadCategoryIcon{
    position: unset !important;
    right: unset !important;
    top: unset !important;
    transform: unset !important;
    height: unset !important;
}
.leadsHeaderBar.selector.lead{
    grid-template-columns: calc(100% - 144px) 48px 48px 48px !important;
}
.leadsHeaderBar.selector.contactAttempted{
    grid-template-columns: 48px calc(100% - 144px) 48px 48px !important;
}
.leadsHeaderBar.selector.inProgress{
    grid-template-columns: 48px 48px calc(100% - 144px) 48px !important;
}
.leadsHeaderBar.selector.closed{
    grid-template-columns: 48px 48px 48px calc(100% - 144px) !important;
}
.leadsCategoryHeaderInnerWrap .inactive{
    display:none;
}
.leadsDeatilsBoard .inactive{
    display:none;
}
.filtersTabBar{
    position: sticky;
    top: 48px;
    background-color: inherit;
    z-index: 3;
    grid-template-columns: repeat(2, 50%);
    width:calc(100% - 72px);
    margin: auto;
}
.leadsFiltersHeadbar{
    background-color: inherit;
    z-index: 3;
}

.leadsFilterAccountBlock{
    width:calc(100% - 16px);
    height:72px;
    display:grid;
    margin:8px auto;
}

.leadsAffiliateFilter{
    height: 36px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 48px) 48px;
    grid-template-rows: 100%;
}

.leadsAffiliateFilter .g{
    width:100%;
    height: 36px;
}


.singleLeadOptionBar{
    width: 100%;
    height: 46px;
    background-color: white;
    z-index: 3;
    text-align: center;
    grid-template-columns: 50% 50%;
}