.tertiaryPage{

}

.reportBuilder.tertiary{
    max-height:calc(100vh - 122px);
    grid-template-rows: 36px auto 1fr;
}

.tertiaryHub{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    grid-template-columns: 1fr 8px 1fr;
}

.tertiaryHub .activity{
    grid-template-rows: 48px 1fr;
}

.tertiaryHub .reportingBlock{
    grid-template-rows: 48px 1fr;
}

.tertiaryHub .topBar{
    grid-template-columns: 1fr 48px;
}

.tertiaryHub .currentActivity{
    background-color: #f3f4f6;
    color: gray;
    display: inline-block;
    height: 100%;
    max-height: calc(100vh - 185px);
    width: 100%;
    overflow: auto;
    position: relative;
    border: solid 1px gainsboro;
}

.tertiaryHub .alignmentContainer .dragline{
    width:1px;
}

.tertiaryHub .alignmentContainer .columnDrag{
    left: 50%;
    top: 50%;
    right: unset;
    transform: translate(-50%, -50%);
}

.tertiaryHub .alignmentContainer:hover .columnDrag{
    visibility:visible;
    cursor:col-resize;
}

.tertiaryHub .alignmentContainer .columnDrag .bar{
    height: 50%;
}

.queryBar{
    grid-template-rows: 32px auto;
    border: solid 2px #E2E2E2;
}

.queryBar .toggles{
    grid-template-columns: repeat(6, 1fr);
}

.queryBar.inactive .queryToggle{
    border-color:gray;
}

.queryToggle{
    grid-template-columns: 24px 1fr;
    border:solid 2px #E2E2E2;
    color:gray;
}

.queryToggle.alt{
    grid-template-columns: 1fr;
    border-radius: 50%;
    height: 32px;
    width: 32px;
}

.queryToggle .text{
    line-height: 1 !important;
}

.queryBar .summaryData{
    grid-template-columns: repeat(3, 1fr);
}