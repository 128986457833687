.currentActivity.premiums{
    grid-template-rows: auto 1fr;
}

.currentActivity.premiums.loading{
    grid-template-rows: auto 1fr;
}

.currentActivity.premiums .header{
    height: auto;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 2;
    grid-template-rows: repeat(3, 32px);
    border: solid #dcdcdc 1px;
    box-shadow: 0 0 8px #E2E2E2;
}

.currentActivity.premiums .header .data{
    grid-template-columns: repeat(2, 1fr);
}

.currentActivity.premiums .header .data .point{
    grid-template-columns: 24px 1fr;
}

.currentActivity.premiums .header .actionBar{
    grid-template-columns: repeat(3, 1fr);
}

.currentActivity.premiums .header.inactive{
    background-color: #f3f4f6;
    border-color: #E2E2E2;
}

.currentActivity.premiums .header.inactive svg{
    color:gray !important;
}

.currentActivity.premiums .header .columnHeaders{
    grid-template-columns: repeat(3, 1fr);
}

.currentActivity.premiums .header .columnHeaders .column{
    grid-template-columns: 32px 1fr;
}

.premiumSchedule .table{
    gap:2px;
    grid-auto-rows: 48px;
}

.premiumSchedule .table .row{
    grid-template-columns: repeat(3, 1fr);
    gap:2px;
}

.premiumSchedule .table .row .cell{
    border: solid #dcdcdc;
    border-width: 1px 1px 2px;
    color: dimgrey;
    background-color: #fff;
}
