.detailsTab{
    position: relative;
    direction: rtl; /* Right-to-left alignment */
    height: calc(100% - 8px);
}

.detailsTab *{
    direction: ltr; /* Right-to-left alignment */
}

.shadow {
    position: sticky;
    left: 0;
    right: 0;
    height: 0px; /* Very thin shadow container */
    pointer-events: none; /* Ensure the shadow doesn't block interactions */
    z-index: 1; /* Ensure shadow stays on top of content */
}

.top.shadow {
    top: 0;
}

.bottom.shadow {
    bottom: 0;
}

.shadow.bottom::before, .shadow.top::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    background: rgba(0, 0, 0, 0);
    border-radius: 5px;
}

.top.shadow::after {
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0));
}

.bottom.shadow::before {
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0));
}

.leadSourceTag{
    grid-template-columns: 24px auto;
}

.leadSourceTag.abacusPays{
    color:#1b5e20;
    background-color: #c8e6c9;
}

.leadSourceTag.direct{
    color:#4a148c;
    background-color: #e1bee7;
}

.leadSourceTag.serviced{
    color:#0d47a1;
    background-color: #e3f2fd;
}

.leadSourceTag.agent{
    color:#f57f17;
    background-color: #FFEFA9;
}

.leadSourceTag.clientDirect{
    color:#880e4f;
    background-color: #fce4ec;
}

.leadSourceTag.broker{
    color:#1a237e;
    background-color: #e8eaf6;
}