.actionBar.bid, .actionBar.lead{
    grid-template-columns: repeat(4, 1fr);
    background-color: white;
}

.actionBar.bid:not(.alt){
    top:0;
    box-shadow: 0 0 8px #E2E2E2;
    border:solid 1px gainsboro;
    position:sticky;
}

.actionBar.bid .action, .actionBar.lead .action{
    height: 36px;
    grid-template-columns: 36px 1fr;
    background-color: white;
    border:solid 2px;
}

.actionBar.bid .action:nth-child(1):not(.inactive){
    color: #00bfff;
    outline-color: #00bfff;
}
.actionBar.lead .action:nth-child(1):not(.inactive){
    color: #ef5350;
    outline-color: #ef5350;
}

.actionBar.bid .action:nth-child(1):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(1):not(.inactive){
    background-color: #e3f2fd;
}

.actionBar.lead .action:nth-child(1):not(.inactive):hover, .actionBar.lead .action.selected:nth-child(1):not(.inactive){
    background-color: #ffebee;
}

.actionBar.bid .action:nth-child(2):not(.inactive), .actionBar.lead .action:nth-child(2):not(.inactive){
    color: #01579b;
    outline-color: #01579b;
}

.actionBar.bid .action:nth-child(2):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(2):not(.inactive),
.actionBar.lead .action:nth-child(2):not(.inactive):hover, .actionBar.lead .action.selected:nth-child(2):not(.inactive)
{
    background-color: #e3f2fd;
}

.actionBar.bid .action:nth-child(3):not(.inactive), .actionBar.lead .action:nth-child(3):not(.inactive){
    color: #6a1b9a;
    outline-color: #6a1b9a;
}

.actionBar.bid .action:nth-child(3):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(3):not(.inactive),
.actionBar.lead .action:nth-child(3):not(.inactive):hover, .actionBar.lead .action.selected:nth-child(3):not(.inactive){
    background-color: #f3e5f5;
}

.actionBar.bid .action:nth-child(4):not(.inactive){
    color: #ef5350;
    outline-color: #ef5350;
}

.actionBar.lead .action:nth-child(4):not(.inactive):hover, .actionBar.lead .action.selected:nth-child(4):not(.inactive){
    background-color: #e8f5e9;
}

.actionBar.lead .action:nth-child(4):not(.inactive){
    color: #66bb6a;
    outline-color: #66bb6a;
}

.actionBar.bid .action:nth-child(4):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(4):not(.inactive){
    background-color: #ffebee;
}

.actionBar.bid .action.inactive{
    color: dimgray;
    outline-color: #EFEFEF;
    background-color: #EFEFEF;
    border-color: #E2E2E2;
}

.timelineActivities{
    grid-template-rows: auto;
}

.newTimelinePost:hover, .newTimelinePost.inactive:hover{
    background-color: #e2e2e2;
    border-color: dimgray;
    color: dimgray;
}

.newTimelinePost:hover .divider, .newTimelinePost:hover .vDivider{
    background-color: dimgray;
}

.newTimelinePost{
    border: solid 2px gainsboro;
}

.newTimelinePost{
    grid-template-rows: 36px auto minmax(36px, auto);
    grid-template-columns: 100%;
    background-color: white;
}

.newTimelinePost.voc, .newTimelinePost.payment,
.newTimelinePost.offer, .newTimelinePost.request,
.newTimelinePost.note{
    grid-template-rows: 36px auto minmax(36px, auto);
}

.newTimelinePost.note > .content{
    grid-template-columns: 36px 1fr;
    grid-auto-rows: 36px auto;
}

.newTimelinePost.status > .header, .newTimelinePost.offer > .header,
.newTimelinePost.request > .header, .newTimelinePost.payment > .header,
.newTimelinePost.voc > .header, .newTimelinePost.note > .header,
.newTimelinePost.followUp > .header{
    grid-template-columns: 36px auto 1fr;
}

.newTimelinePost.detailed{
    grid-template-rows: minmax(36px, auto) auto minmax(36px, auto);
}

.newTimelinePost.detailed.status{
    grid-template-rows: minmax(36px, auto) auto minmax(36px, auto) auto;
}

.newTimelinePost.status > .content, .newTimelinePost.offer > .content,
.newTimelinePost.request > .content, .newTimelinePost.followUp > .content{
    grid-template-columns: 36px 1fr;
    grid-auto-rows: 36px auto;
}

.newTimelinePost.servicing.payment > .content{
    grid-template-columns: 1fr;
    grid-auto-rows: 36px auto;
}

.newTimelinePost.voc > .content{
    grid-template-columns: 1fr;
    grid-auto-rows: 36px auto;
}

.newTimelinePost.bids.offer > .content{
    grid-template-rows:36px auto 36px;
}
.newTimelinePost.followUp > .content{
    grid-template-rows:36px auto;
}

.newTimelinePost.status > .content .subHeader{
    grid-template-columns: 1fr auto;
}

.newTimelinePost.servicing.payment > .content .subHeader, .newTimelinePost.servicing.voc > .content .subHeader{
    grid-template-columns: auto 1fr 136px;
}

.treelinePost{
    grid-template-columns: 16px 1fr;
}

.treelinePost .treeline{
    height: calc(100% + 6px);
    width: 1px;
    border: 1px gray dashed;
}

.newTimelinePost.offer.Open, .newTimelinePost.followUp.Open{
    color: #6a1b9a;
    border-color: #6a1b9a;
    background-color: #f3e5f5;
}

.newTimelinePost.offer.Completed, .newTimelinePost.offer.Closed,
.newTimelinePost.followUp.Completed{
    color: #43a047;
    border-color: #43a047;
    background-color: #e8f5e9;
}

.newTimelinePost.offer.Denied, .newTimelinePost.request.Denied,
.newTimelinePost.followUp.Missed{
    color: #ef5350;
    border-color: #ef5350;
    background-color: #ffebee;
}

.newTimelinePost.action.Open:hover{
    background-color: #e1bee7;
}

.newTimelinePost.offer.Completed:hover, .newTimelinePost.offer.Closed:hover{
    background-color: #c8e6c9;
}

.newTimelinePost.offer.Denied:hover{
    background-color: #ffcdd2;
}

.newTimelinePost.offer.Open .dT, .newTimelinePost.followUp.Open .dT{
    color: #f3e5f5;
    border-color: #6a1b9a;
    background-color: #6a1b9a;
}

.newTimelinePost.offer:not(.Open, .Completed, .Denied, .Closed) .dT, .newTimelinePost.followUp:not(.Open, .Completed, .Denied, .Closed) .dT{
    color: dimgray;
    border-color: #E2E2E2;
    background-color: #E2E2E2;
}

.newTimelinePost.offer.Completed .dT, .newTimelinePost.offer.Closed .dT,
.newTimelinePost.followUp.Completed .dT{
    color: #e8f5e9;
    border-color: #43a047;
    background-color: #43a047;
}

.newTimelinePost.offer.Denied .dT, .newTimelinePost.request.Denied .dT,
.newTimelinePost.followUp.Missed .dT{
    color: #ffebee;
    border-color: #ef5350;
    background-color: #ef5350;
}

.newTimelinePost.offer .dataPoint, .newTimelinePost.request .dataPoint,
.newTimelinePost.voc .dataPoint, .newTimelinePost.payment .dataPoint,
.newTimelinePost.followUp .dataPoint{
    padding:4px 8px;
}

.newTimelinePost.followUp .dataPoint{
    grid-template-columns: 1fr 24px;
    padding-right: 2px;
    width: 136px;
    outline: none;
    box-shadow: inset 0 0 0 2px transparent;
}

.newTimelinePost.followUp .dataPoint .switch{
    background-color: #F3F4F6;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 2px transparent;
}
.newTimelinePost.followUp.detailed .dataPoint .switch{
    background-color: white;
}

.newTimelinePost.followUp.Completed .dataPoint .switch{
    border-color: #43a047;
}

.newTimelinePost.followUp.Open .dataPoint .switch{
    border-color: #6a1b9a;
}

.newTimelinePost.followUp.Missed .dataPoint .switch{
    border-color: #ef5350;
}

.newTimelinePost.followUp .dataPoint .switch svg{
    height: 24px;
    width: 24px;
    position: absolute;
}

.newTimelinePost.followUp .dataPoint .switch:hover .placeholder{
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

/* .newTimelinePost.followUp.Open .dataPoint:hover{
    background-color:#f3e5f5;
    box-shadow: inset 0 0 0 2px #6a1b9a;
    color:#6a1b9a;
} */
.newTimelinePost.followUp.Open .dataPoint:hover .switch .placeholder{
    background-color: #6a1b9a;
}
/* .newTimelinePost.followUp.Missed .dataPoint:hover{
    box-shadow: inset 0 0 0 2px #ef5350;
    background-color: #ffebee;
    color:#ef5350;
} */
.newTimelinePost.followUp.Missed .dataPoint:hover .switch .placeholder{
    background-color: transparent;
}
/* .newTimelinePost.followUp.Completed .dataPoint:hover{
    box-shadow: inset 0 0 0 2px #43a047;
    background-color: #e8f5e9;
    color:#43a047;
} */
.newTimelinePost.followUp.Completed .dataPoint:hover .switch .placeholder{
    background-color: #43a047;
}

.newTimelinePost.followUp.Completed .dataPoint .switch svg{
    color: #43a047;
}
.newTimelinePost.followUp.Open .dataPoint .switch svg{
    color: #6a1b9a;
}
.newTimelinePost.followUp.Missed .dataPoint .switch svg{
    color: #ef5350;
}

.newTimelinePost.inactive{
    height:74px;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    background-color: white;
    border: solid 1px gainsboro;
}

.newTimelinePost.inactive:hover{
    background-color: white;
    border-color: gainsboro;
}

.newTimelinePost.request.Open{
    border: 2px solid #01579b;
    color: #01579b;
    background-color: #e3f2fd;
}

.newTimelinePost.request.Open:hover{
    background-color: #bbdefb;
}

.newTimelinePost.request.Open .dT{
    color:#e3f2fd;
    background-color: #01579b;
}

.newTimelinePost.request.Completed, .newTimelinePost.request.Closed{
    border: 2px solid #81c784;
    color: #43a047;
    background-color: #e8f5e9;
}

.newTimelinePost.request.Completed:hover, .newTimelinePost.request.Closed:hover{
    background-color: #c8e6c9;
}

.newTimelinePost.request.Completed .dT, .newTimelinePost.request.Closed .dT{
    color: #e8f5e9;
    background-color: #43a047;
}

.newTimelinePost.bids.offer > .content .offerData{
    grid-template-columns: auto auto auto;
}

.newTimelinePost.servicing.payment{
    border-color: #4caf50;
    color:#4caf50;
    background-color: #e8f5e9;
}

.newTimelinePost.servicing.payment .divider, .newTimelinePost.servicing.payment .dataPoint{
    background-color: #4caf50;
    color: #e8f5e9;
}

.newTimelinePost.servicing.payment svg{
    color: #4caf50;
}

.newTimelinePost.servicing.voc{
    border-color: #29b6f6;
    color:#29b6f6;
    background-color: #e1f5fe;
}

.newTimelinePost.servicing.voc .divider, .newTimelinePost.servicing.voc .dataPoint{
    background-color: #29b6f6;
    color:#e1f5fe;
}

.newTimelinePost.servicing.voc svg{
    color: #29b6f6;
}

.newTimelinePost.detailed .date{
    gap:0px;
    padding-top:4px;
    padding-bottom:2px;
}

.newTimelinePost.followUp .details{
    grid-template-columns: 1fr auto;
}

.newTimelinePost.followUp .showMore{
    background-color: #f3f4f6;
    border:2px solid #42a5f5;
    color: #0d47a1;
}

.newTimelinePost.followUp .showMore:hover{
    background-color: #e3f2fd;
}

.newTimelinePost.followUp.detailed .details{
    grid-template-columns: 1fr;
}