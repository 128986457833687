.profilePage{
    text-align: center;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
    padding: 1.25%;
    height: calc(100vh - 90px);
}
.profileModuleTitle{
    display:inline-block;
    width:100%;
}

.profileWrap{
    width:100%;
    height:100%;
    grid-template-rows: 48px calc(100% - 48px);
}

/* Profile Overview */

.profileOverview{
    width:100%;
    height:100%;
}

.confirmationModule{
    display: inline-block;
    width: 500px;
    height: auto;
    position: absolute;
    background-color: white;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding:15px;
}
.confirmationModule .profileModuleTitle{
    font-weight: 500;
    margin-bottom:30px;
}
.confirmationModule .inputField{
    margin-top:15px;
    max-width: 80%;
}
.confirmationModulePrompt{
    width:80%;
    height:auto;
    color:gray;
    text-align: left;
    display: inline-block;
}

.profileModule{
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.profileDetails{
    width:100%;
    height:min-content;
    vertical-align:top;
    overflow: auto;
    max-height: 100%;
}

.profileAlterSection{
    width: 100%;
    height: 100%;
    vertical-align: top;
    max-height: 100%;
    text-align: left;
    overflow-y: auto;
    grid-auto-rows: 1fr auto;
}

.profileHub{
    display:inline-block;
    width:100%;
    height:100%;
    min-height:250px;
    vertical-align:top;
    margin-left:5%;
    text-align: right;
}

.profilePicture{
    display:inline-block;
    position:relative;
    width:100px;
    height:100px;
    border-radius:50%;
    background-color:#EFEFEF;
    box-shadow: inset 0 0 4px #ccc;
    text-align:center;
    line-height:100px;
    color:gray;
}
.profilePicture:hover{
    cursor:pointer;
    color:darkgray;
}
.profileFilePrompt{
    cursor:pointer;
}

.profilePicture .addPhotoIcon{
    width: 40px;
    height: 40px;
    position: relative;
    vertical-align: middle;
}

.profileFilePrompt{
    opacity: 0.0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height:100%;
    z-index: 2;
}

.profileDetailsHeader{
    display: inline-block;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-weight: 100;
    color: dimgray;
}

.profileDtailsSection{
    height: auto;
    width: 100%;
}

.profile .profileDtailsSection > div{
    grid-template-columns: repeat(2, 1fr);
}

.admin .profileDtailsSection > div{
    grid-template-rows: repeat(2, 1fr);
}

.profileToggles{
    display:inline-block;
    width:100%;
    height:auto;
    text-align: left;
}

.changeProfileData{
    color:white;
    background-color:#00BFFF;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
    width: 47.5%;
    position: relative;
    float:left;
    box-shadow:inset 0 0 0 2px #00bfff;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}
.saveProfileData{
    float:right;
    background-color:#EFEFEF;
    box-shadow:inset 0 0 0 2px #EFEFEF;
    color:gray;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
    width: 47.5%;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}
.saveProfileData.active{
    color:#00BFFF;
    background-color:white;
    box-shadow:inset 0 0 0 2px #00bfff;
}
.saveProfileData:hover{
    cursor:auto;
    background-color:#EFEFEF;
    box-shadow:inset 0 0 0 2px #EFEFEF;
    color:gray;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
}
.saveProfileData.active:hover{
    color:white !important;
    background-color:#00BFFF !important;
    box-shadow:inset 0 0 0 2px #00bfff;
}
.changeProfileData:hover, .saveProfileData.active:hover{
    cursor:pointer;
    color:#00BFFF;
    background-color:white;
    box-shadow:inset 0 0 0 2px #00bfff;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
}

.changeProfileData.selected:hover{
    color:white;
    background-color:#00BFFF;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    box-shadow:inset 0 0 0 2px #00bfff;
    text-align: center;
    margin-top:24px;
}
.changeProfileData.selected{
    cursor:pointer;
    color:#00BFFF;
    background-color:white;
    box-shadow:inset 0 0 0 2px #00bfff;
    border-radius:5px;
    width:150px !important;
    height:32px;
    line-height:32px;
    text-align: center;
    margin-top:24px;
}

.changePassword{
    float: right;
    text-align: left;
    position: relative;
    height: 16px;
    width: auto;
    font-weight: 100;
    font-size:14px;
    cursor:pointer;
    padding-left:10px;
    color:dimgray;
    margin-top:24px;
    line-height: 16px;
    vertical-align: middle;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.changePassword svg{
    position:relative;
    width: 16px;
    height:16px;
    vertical-align: middle;
}

.changePassword:hover{
    color:#00BFFF;
}
.profileCurrentView{
    grid-template-rows: 36px calc(100% - 36px);
}
.profileCurrentView.admin{
    grid-template-rows: 100%;
    grid-template-columns: 384px 1fr;

}

.profileCurrentView .content{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}
.profileCurrentView.admin .content{
    grid-template-columns: 100%;
}
.profileAdminSelectionOption{
    width:200px;
    height:48px;
    line-height:48px;
    outline:solid 1px gainsboro;
    margin-bottom:8px;
    border-radius: 5px;
    color:dimgray;
    cursor:pointer;
    display:grid;
    grid-template-columns: calc(100% - 48px) 48px;
}
.profileAdminSelectionOption.selected{
    font-weight:500;
    outline:solid 2px #00BFFF;
    color:#00BFFF;
}

.manageHandlerContainer{
    height: 100%;
    overflow: hidden;
    grid-template-rows: 64px 1fr;
}

.manageHandlerContainer > :first-child{
    z-index: 2;
}
.manageHandler{
    overflow-y: auto;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    row-gap: 8px;
    display: grid;
    align-content: start;
    border: solid 2px gainsboro;
    height: 100%;
    width:calc(100% - 16px);
    background-color: #f3f4f6;
}
.manageHandler .g{
    /* width:100%; */
}
.managedAccountBlock, .managedUserBlock{
    width:calc(100% - 16px);
    margin:auto;
    display:inline-grid;
    outline:solid 1px gainsboro;
    box-sizing: border-box;
    border-radius:5px;
    grid-template-rows:36px auto;
    grid-template-columns: 100%;
    color:dimgray;
    background-color:white;
}
.managedAccountBlock.active{
    outline:solid 2px #c8e6c9;
}
.managedAccountBlock.inactive{
    outline:solid 2px #e57373;
    background-color: #ffebee;
    color:#f44336;
}
.managedAccountBlock.active .managedName, .managedAccountBlock.active svg{
    color:#4caf50;
}
.managedTopBar{
    display:grid;
    grid-template-columns: auto auto;
    width: 100%;
    height:100%;
    font-weight: 500;
}
.managedBody{
    display: grid;
    grid-template-columns:100%;
    width:100%;
}
.managedAccountBlockLeft{
    width:100%;
    height:auto;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
    row-gap: 8px;
    display:inline-grid;
}
.managedAccountBlockLeft > .gR1, .managedAccountBlockLeft > .gR3 > .gR2, .managedAccountBlockLeft > .gR5 > .gR2{
    grid-template-columns: repeat(3, calc(100% / 3));
    width: 100%;
}
.managedAccountBlockLeft > .gR2, .managedAccountBlockLeft > .gR4{
    grid-template-columns: 100%;
    width: 100%;
}
.managedAccountBlockLeft > .gR3, .managedAccountBlockLeft .gR5{
    grid-template-columns: 100%;
    row-gap: 8px;
    width: 100%;
}
.managedAccountBlockLeft .g, .managedAccountBlockLeft .g > div:not(.divider){
    width:100%;
}
.managedAccountBlockLeft .inactive.g{
    background-color: #EFEFEF;
}

.inactive .managedAccountBlockLeft .inactive.g{
    background-color: #ffebee;
}
.inactive .managedAccountBlockLeft .inactive.g svg{
    color: #f44336;
}

.managedAccountBlockRight svg{
    cursor:pointer;
}
.manageMakeChanges{
    cursor:pointer;
    font-weight:400;
}
.manageMakeChanges:hover{
    color:#00BFFF;
}
.manageActionBar{
    display: grid;
    grid-template-columns: 1fr repeat(2, 160px);
    background-color: white;
    outline:solid 2px gainsboro;
    width: calc(100% - 16px);
}
.managedBody .inactive svg{
    color:gray;
}

.managedUserBlock{
    row-gap: 8px;
}
.managedUserBlock .g{
    width:100%;
}
.managedUserBlock .g:nth-child(2){
    grid-template-rows: auto;
    grid-template-columns:100%;
    row-gap: 8px;
}
.managedUserBlock .g:nth-child(2) .g{
    grid-template-columns:repeat(3, calc(100% / 3));
}

.moreBtn *{
    color:gray;
}

.profileTab{
    width: 50%;
    margin:auto;
    height: 48px;
    grid-template-columns: repeat(2, 50%);
}

.profilePage .singlePolicyBlockOptionBar{
    width:50%;
    margin:auto;
}

.profilePage .singlePolicyBlockOptionBar .switchViewBtnOption{
    max-width: unset;
}

.profileAccBlock{
    grid-template-rows: repeat(2, auto);
    background-color: white;
    border:2px solid gainsboro;
    height: auto;
    width:calc(100% - 16px);
    gap:8px;
}

.profileAccBlock .header{
    grid-template-rows: repeat(2, 24px);
    gap:8px;
}
.profileAccBlock .header .data{
    grid-template-columns: repeat(3, 1fr);
    gap:8px;
}

.profileAccBlock .body{
    grid-template-columns: repeat(3, 1fr);
    height: 270px;
    gap: 8px;
    background-color: #f3f4f6;
    border:solid 1px gainsboro;
    display: flex;
}
.pageBlockWrap{
    grid-template-rows: 48px auto;
    gap:8px;
    color:dimgray;
}

.pageBlockWrap.inactive .actionBar, .pageBlockWrap.inactive .pageBlock{
    background-color: #EFEFEF;
    color:gray;
}

.pageBlockWrap.inactive svg{
    color:gray;
}

.pageBlockWrap .actionBar{
    grid-template-columns: repeat(2, 1fr);
    border:solid 2px gainsboro;
    background-color: white;
    overflow: hidden;
}

.pageBlockWrap .option .on{
    color:#4caf50;
}


.pageBlock{
    border:solid 2px gainsboro;
    grid-template-rows: 64px repeat(4, 1fr);
    background-color: white;
}

.actionBar .option{
    color:gray;
}
.actionBar .option:hover{
    background-color: #E2E2E2;
    color:dimgray;
}

.pageBlock .icon svg{
    width:48px;
    height:48px;
}

.pageBlock .stat{
    grid-template-columns: 36px 1fr 36px 1fr;
}

.manageActionBar .searchBar{
    width:100%;
    height:100%;
}

.profilePage .inlineActionBar{
    grid-template-columns: 200px 1fr 200px;
}

.profileAlterSection .preferences{
    height: min-content;
}