@media all and (max-width: 768px), (max-height: 600px)
{
    .sizeMobile{
        display:block;
    }
    html {
        scroll-behavior: smooth;
    }

    /* App */
    .appBoard{
        overflow-x: hidden !important;
        height: auto !important;
        min-height:calc(100vh - 90px);
    }
    .singlePolicyBlockOptionBar{
        grid-template-columns: 48px calc(100% - 48px) !important;
    }
    .singleLeadOptionBar{
        grid-template-columns: repeat(3, calc(100% / 3)) !important;
    }
    .leadsPage .switchViewBtnContainer {
        grid-template-columns: repeat(2, 50%) !important;
    }
    .switchViewBtn{
        grid-template-columns: 100% !important;
    }
    .inlineNotificationIcon{
        height: 10px !important;
        width: 10px !important;
        left: -6px !important;
    }
    .inlineNotificationIcon svg{
        height: 10px !important;
        width: 10px !important;
    }
    .singlePolicyBlockOptionBar .inlineNav{
        position:relative !important;
    }
    /* Login */
    .loginBoard{
        grid-template-columns: 100% !important;
    }
    .loginBoardInnerWrap{
        position:fixed !important;
        top:50% !important;
        left:50% !important;
        transform: translate(-50%, -50%) !important;
        width:95vw !important;
        height: auto !important;
        max-width: 450px !important;
        min-width: unset !important;
    }
    .abacusLifeBannerInnerWrap img{
        left:0 !important;
    }
    .abacusLifeBannerTitle{
        padding-left:90px !important;
    }
    .TOSInnerWrap{
        width:95% !important;
        height:95% !important;
    }
    .splashBulletins{
        display:none !important;
    }

    /* Home Page */
    .homePage{
        height:auto !important;
        min-height: 100% !important;
        width:100% !important;
    }
    .homePageInfoPanel{
        width:100% !important;
    }
    
    .homePageInfoPanel{
        position:relative !important;
    }
    .chartBlock.moduleBlock{
        position:relative !important;
        top:auto !important;
    }
    .policyPositionBlock.moduleBlock{
        position:relative !important;
        top:auto !important;
        margin-top:5px !important;
    }
    .homePageTimeline.moduleBlock{
        position: relative !important;
        width:100% !important;
        margin-top:5px !important;
    }
    .homePageTimelineBlock .timeline{
        width:calc(100% - 22px) !important;
        margin-bottom:0 !important;
    }
    .timelinePost{
        width:calc(100% - 10px) !important;
    }
    .statusBody:not(.condense){
        min-height:46px;
        display: grid;
        grid-template-columns: 50px calc(50% - 75px) calc(50% - 75px) 50px 50px !important;
        grid-template-rows: auto;
    }
    .statusBody div:last-child{
        text-align: center !important;
        padding-right:unset !important;
    }
    .noTimelineResults{
        height:300px !important;
        width:calc(100% - 16px) !important;
        position:unset !important;
    }
    .overViewDataBottomSection.loading{
        height:200px !important;
    }
    .homePageColumnHeader.originationView{
        grid-template-columns:  calc(100% / 3 + 15px) calc(100% / 3 - 30px) calc(100% / 3 - 70px) 75px !important;
        width:100% !important;
    }
      
    .homePageColumnHeader.fundView{
        grid-template-columns: calc(20% + 40px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px) calc(20% - 10px);
        width: 100% !important;
    }

    .statusBody:not(.condense){
        grid-template-columns: 45px calc(100% / 3 - 30px) calc(100% / 3 - 30px) calc(100% / 3 - 70px) 75px !important;
    }
    /* Template */

    .notificationBoard{
        width: 100% !important;
        position:fixed !important;
    }
    .notificationBoardInner.moduleBlock{
        width:95%;
    }

    .sideBar p{
        margin:auto;
    }
    .mobileAltOptions{
        display: inline-block;
        width: 55px;
        height: 55px;
        position: relative;
        color:#104735;
    }
    .mobileMoreOptions{
        z-index: 2;
        background-color: inherit;
        width: calc(100% - 90px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }


    /* NavBar */
    .appHeader{
        position:sticky;
    }
    
    .navBar{
        grid-template-columns: auto auto 64 !important;  
    }
    .currentPageHandler{
        line-height: unset !important;
        padding-left: 0 !important;
        padding-top: 20px !important;
        position:relative !important;
    }
    .abacusHeader{
        font-size:20px !important;
    }
    .currentPageHandlerPath{
        height: 16px !important;
        padding: unset !important;
        line-height: 16px !important;
        display: block !important;
        font-size: 16px !important;
        display:inline-block !important;
        margin-left:10px;
    }
    .appHeaderSpacer{
        position:fixed;
    }
    .appHeader{
        box-shadow: 0 0 8px grey;
    }
    .darkMode .appHeader, .darkMode .sideBar{
        box-shadow: 0 0 8px black;
    }
    .logo{
        max-width: none !important;
    }

    /* Policies */
    .policiesDetailsBlock{
        height: auto !important;
        min-height: 100%;
    }
    .policiesPage{
        width:100% !important;
        height:auto !important;
        min-height: unset !important;
        padding-bottom:0 !important;
    }

    .policiesDetailsBoard{
        width:calc(100% - 16px) !important;
    }
    .policyRow{
        width:calc(100% - 38px) !important;
        margin-top:5px !important;
        min-height: 55px !important;
        height: auto !important;
    }
    .policiesDetailsBoardWrap{
        height: auto !important;
        padding-bottom:8px;
        position: unset !important;
    }
    .noPolicyResults{
        top:108px !important;
        left:8px !important;
        right:8px !important;
    }
    .policyResultsData{
        display: none !important;
    }
    .policiesFiltersModule{
        height:calc(100vh - 190px) !important;
        width: 95% !important;
        position:fixed !important;
        z-index: 4 !important;
        top:10px !important;
        bottom: 0 !important;
    }
    .policiesFiltersModuleInner{
        overflow:auto !important;
    }
    .policiesFiltersToggleSection{
        width:calc(100% - 10px) !important;
    }
    .singlePolicysDetailsBoardWrap{
        height:auto !important;
        width: 100% !important;
        grid-template-columns: 100% !important;
        min-height: 100%;
    }
    .singlePolicyTimelineTrackerInnerWrap{
        margin-bottom:10px;
    }
    .policyDetailsCard{
        width: 100% !important;
        left:0 !important;
    }
    .inlineNav{
        width:40px !important;
        overflow: hidden !important;
    }
    .inlineNavDirection{
        display: none !important;
    }
    .switchViewBtnOption{
        display: none !important;
    }
    .activityTimelineHeaderBtn{
        outline: none !important;
    }
    .listAltercationOption{
        outline: none !important;
    }
    .singlePolicyCurrentActivity{
        width:100% !important;
    }
    .policiesDetailsBlock .listBar{
        display: none !important;
    }
    .policyDetailsCardFaceInner{
        height:auto !important;
        overflow: hidden !important;
    }
    .singlePolicyTimelineTracker{
        position: relative !important;
    }
    .documentsBlock{
        height:calc(100vh - 219px) !important;
    }
    .communicationWrapper{
        height:calc(100vh - 226px) !important;
    }
    .closingTabSection, .closingTabSectionHeader{
        width:calc(100% - 24px) !important;
    }
    .closingTabSectionHeader{
        padding-left:5px !important;
    }
    .bidPanel{
        min-height: calc(100vh - 238px) !important;
    }
    .policiesFiltersRangeSliderSection{
        width: calc(100% - 10px) !important;
        left: 5px !important;
    }
    .policyRowInner{
        position:relative;
        width:100%;
        height:100%;
        text-align: center;
        display: grid;
        grid-template-columns: 40px calc(50% - 45px) 50px calc(50% - 45px) !important;
    }
    .activityTimelineHeader{
        width:100% !important;
        left:0 !important;
    }
    .requestCounterPrompt{
        float:unset !important;
        text-align: center !important;
    }

    .statusDetailBlock{
        position:fixed !important;
        width:90% !important;
        height: auto !important;
    }
    .statusDetailInnerWrap{
        position:relative !important;
    }
    .bidPanelDetailValue.date{
        font-size:14px;
        font-weight: 100;
    }
    .bidPanelDownloadBtn{
        position:relative !important;
        bottom: unset !important;
        right: unset !important;
        margin:2px auto !important;
    }
    .statusDefinition{
        display:block !important;
        position:relative !important;
        height:auto !important;
    }
    .statusDefinitionTitle{
        top: 0 !important;
        font-size: 18px !important;
    }
    .statusBodyIcon svg{
        width:75px !important;
        height:75px !important;
    }
    .emptyRequestsMessage{
        margin-top:10px;
        padding:10px;
    }
    .emptyRequests{
        min-height: calc(100vh - 440px) !important;
    }

    .timeStampSeparator{
        margin-bottom:20px !important;
    }

    .summaryFunderStatement{
        grid-template-rows: 50% 50%;
        grid-template-columns: 100% !important;
        row-gap: 8px;
    }
    /* Profile */
    .profilePage{
        width:100% !important;
        height:auto !important;
        padding:1.25% !important;
    }
    .profileDetails{
        width:100% !important;
    }
    .profileAlterSection, .profileToggles{
        width:100% !important;
        margin-left:0 !important;
    }

    /* Upload */
    .uploadOverview{
        height:auto !important;
    }
    .uploadPage{
        width:100% !important;
        height:auto !important;
    }
    .uploadModule{
        overflow: hidden !important;
        height:auto !important;
    }
    .secondaryTitle{
        margin-top:15px;
    }

    /* Leads */
    .leadInlineTimelineBtn{
        width:calc(100% - 16px);
    }
    .leadsPage .activityTimelineHeader{
        height:36px !important;
    }
    .leadsPage .leadDetailsCardFace{
        margin-top:0;
        height:auto !important;
    }
    .leadDetailsCardFace{
        overflow:hidden !important;
        height:auto !important;
    }
    .leadInlineTimelineBtn{
        width:calc(100% - 8px) !important;
    }
    .leadOptionsBoard{
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        position:fixed !important;
    }
    .leadOptionsBoardInnerWrap{
        width:95% !important;
    }
    .leadQuickChangeBtn{
        line-height:48px !important;
    }
    .timelineFollowUp{
        width:97.5% !important;
        left:unset !important;
        display:inline-grid !important;
    }
    .singleLeadActionBar{
        min-width:unset !important;
    }
    .leadsPage{
        min-height:unset !important;
    }
    .leadsKanbanBoard{
        height:auto !important;
        overflow: hidden !important;
    }
    .leadsDeatilsBoard{
        grid-template-columns: unset !important;
        gap:unset !important;
        height:auto !important;
    }
    .leadsOverview {
        height: auto !important;
    }
    .leadBlockColumn {
        margin-top:6px !important;
        margin-bottom: 6px !important;
        max-height:unset !important;
    }
    .leadsHeaderBar{
        grid-template-columns: calc(100% - 144px) 48px 48px 48px !important;  
    }
    .leadsCategoryHeaderInnerWrap{
        grid-template-columns: 100% 0 0 0 !important;
    }
    .leadsTopSection{
        grid-template-columns: unset !important;
        height:auto !important;
        min-height: 112px;
    }
    .leadsSearchBarContainer{
        width:calc(100% - 16px) !important;
        margin:auto;
        height:48px !important;
    }
    .leadsFilterModule{
        width:90% !important;
    }
    .leadsViewFilters{
        display:none !important;
    }
    .kanban{
        grid-template-columns: calc(100% - 16px) !important;
    }
    .missedFollowUp svg{
        display: none !important;
    }
    .missedFollowUpCount span{
        display:none;
    }
    .followUpRemainingTime svg{
        display:none !important;
    }
    .leadsListBoard{
        height: calc(100vh - 256px) !important;
        width: calc(100% - 16px) !important;
    }
    .leadsListResults{
        width:100% !important;
        grid-template-columns: 100% !important;
        margin:auto !important;
        justify-content: unset !important;
        gap:8px !important;
    }
    .leadsListFilterPanelWrap{
        position: absolute !important;
        display: none !important;
    }
}

