.loginBoard{
    background: url("../Images/8.jpeg") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    grid-template-columns: auto auto;
    width: 100vw;
    height: 100vh;
    gap:8px;    
}
.loginBoard.alt{
    grid-template-columns: auto;
}
.splashBlock{
    float:left;
    width:calc(100% - 450px - 15vh);
    height:100%;
}

.splashBlockInnerWrap{
    position: relative;
    width:100%;
    height:100%;
}

.splashMessage{
    width:auto;
    height:auto;
    min-height: 325px;
    background-color: rgba(256,256,256,.85);
    z-index: 1;
    border-radius: 5px;
    transition: 0s !important;
    grid-template-rows: auto;
    gap:16px;
    padding:16px;
}
.splashMessage .divider{
    background-color: gray;;
}

.splashMessage *{
    transition: 0s !important;
}
.splashWelcomeMessage{
    height:auto;
    width:100%;
    grid-template-rows: 58px 37px 48px 52px;
    gap:8px;
}
.splashStatusData{
    height: auto;
    min-height: 100px;
    text-align: center;
    width: 100%;
    grid-template-rows: repeat(2, auto);
    gap: 8px;
}

.splashStatusData .header{
    color:dimgray;
    font-size:18px;
}

.splashStatusData .data{
    grid-template-columns: repeat(2, 1fr);
    gap:16px;
}

.statusTotalActive, .statusTotalClosed{
    height: auto;
    color: dimgray;
    border-radius: 5px;
}
.statusTotalValue{
    display:block;
    width: 100%;
    height: 72px;
}
.splashMessageBlur{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 5px;
    display: grid;
    z-index: -1;
}
.splashMessageBlurInner{
    position: relative;
    width:100%;
    height:100%;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    backdrop-filter: blur(8px);
}

.splashMessageHeadline{
    font-size: 58px;
    font-weight:700;
    color:#104735;
}
.splashMessageSmall{
    font-size: 31px;
    font-weight:100;
    color:dimgray;
}
.splashMessageSmallAlt{
    font-size: 37px;
    font-weight:100;
    color:dimgray;
}
.splashMessageMedium{
    font-size: 52px;
    font-weight:100;
    color:#104735;
}
.splashMessagMediumAlt{
    font-size: 48px;
    font-weight:100;
    color:dimgray;
}
.statusValueBlock{
    font-size: 28px;
    font-weight: 700;
    color:#104735
}
.statusValueBlock::before{
    content: "$";
    font-weight: 100;
}
.loginBoard .fieldName{
    color:gray;
}

.splashBulletins{
    text-align: left;
    width: 100%;
    color: dimgray;
    font-size: 28px;
    font-weight: 100;
}

.TOSWrapper{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index: 3;
}
.TOSInnerWrap{
    display: inline-block;
    width:85%;
    height:85%;
    overflow:auto;
    background-color: white;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    text-align: left;
    border-radius: 5px;
    overflow: hidden;
}
.TOSContainer{
    position:relative;
    height:calc(100% - 100px);
    overflow:auto;
}
.TOSTitle{
    position:sticky;
    top:0;
    width:100%;
    height:50px;
    line-height:50px;
    text-align: center;
    font-size:24px;
    font-weight:100;
    background-color: #EFEFEF;
    border-bottom:solid 1px #E2E2E2;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.TOSContent{
    padding:10px;
    height:auto;
    overflow-x:hidden;
}
.TOSConfirmation{
    position:relative;
    bottom:0;
    width:100%;
    height:50px;
    line-height:50px;
    font-weight:100;
    background-color: #EFEFEF;
    text-align:center;
    border-top:solid 1px #E2E2E2;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.TOSConfirmationBtnContainer{
    position:absolute;
    top:50%;
    right:25px;
    transform: translate(0, -50%);
    width:auto;
    min-width:200px;
    height:36px;
    line-height: 36px;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    grid-template-columns: 50px calc(100% - 50px);
    grid-template-rows: auto;
    cursor:pointer;
    border:solid 1px #E2E2E2;
    border-radius: 36px;
    background-color: white;
    color:gray;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.TOSConfirmationBtn{
    width:24px;
    height:24px;
    line-height: 24px;
    display: inline-block;
    border:solid 2px dimgray;
    border-radius: 5px;
    position: relative;
}
.TOSConfirmationBtn svg{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color:white;
}
.TOSConfirmationPrompt{
    width:auto;
    height:36px;
    line-height:36px;
}
.TOSCloseBtn{
    position:absolute;
    top:50%;
    right:7px;
    transform: translate(0, -50%);
    width:36px;
    height:36px;
    line-height: 36px;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    grid-template-columns: 50px calc(100% - 50px);
    grid-template-rows: auto;
    cursor:pointer;
    color:dimgray;
    border-radius: 50%;
}
.TOSCloseBtn:hover{
    color:#E2E2E2;
    background-color:gray;
}
.TOSConfirmationBtnContainer:hover{
    border:solid 2px #00BFFF;
}
.TOSConfirmationBtnContainer:hover .TOSConfirmationBtn{
    background-color:#00BFFF;
    border-color:transparent;
}
.TOSConfirmationBtnContainer:hover .TOSConfirmationPrompt{
    transition: 0s;
    color:#00BFFF;
}
.TOSContinueBtn{
    height:36px;
    line-height: 36px;
    padding:0 10px;
    border-radius: 36px;
    width: 80px;
    position: relative;
    top:50%;
    left:25px;
    transform: translate(0, -50%);
    color:gray;
}
.TOSContinueBtn.active{
    cursor:pointer;
    border: unset;
    color:white;
    background-color:#00BFFF;
}
.TOSContinueBtn.active:hover{
    cursor:pointer;
    border: unset;
    color:#00BFFF;
    background-color:white;
}
.TOSContinueBtn div{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}

.selected .TOSConfirmationBtn{
    background-color:#00BFFF;
    border-color:transparent;
}

.TOSWrapper .selected:hover svg{
    color: #EFEFEF;
}
.selected:hover .TOSConfirmationBtn{
    border-color:#E2E2E2;
    background-color:gray;
}
.TOSConfirmationBtnContainer.selected:hover{
    border-color:dimgray;
    color:gray;
}
.TOSConfirmationBtnContainer.selected .TOSConfirmationPrompt{
    color:gray;
}
.TOSContinueBtn.active:hover{
    color:#00BFFF;
    border:solid 2px #00BFFF;
}
.loginModuleInnerWrap .TOSConfirmationBtnContainer{
    position: relative;
    transform: unset;
    top: unset;
    right: unset;
    width: 100%;
    min-width: unset;
    margin-top: 24px;
    grid-template-columns: 40px calc(100% - 40px);
}

.loginModuleInnerWrap{
    grid-template-rows: 1fr 36px 36px 60px;
    row-gap: 8px;
}
.hideLogin .loginModuleInnerWrap{
    grid-template-rows: 56px 1fr 60px;
    row-gap: 8px;
}
.create .loginModuleInnerWrap{
    grid-template-rows: 48px 1fr 60px;
    row-gap: 8px;
}

.TermsOfServiceBtn{
    height: 16px;
    line-height: 12px;
    margin-top: 12px;
    cursor: pointer;
    text-align: right;
}




/* .loginBoard{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height:100%;
    width:100%;
    background-image: url("../Images/8.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

.loginBlock{
    width:450px;
    max-width: calc(100vw - 16px);
    height:auto;
    background-color:rgba(256,256,256,.85);
    grid-template-rows: 96px auto 8px 1fr;
    gap:8px;
    max-height: calc(100vh - 16px);
}

.loginBlock .divider{
    background-color: dimgray;
}

.loginBlock .message{
    color:dimgray;
    font-weight: 100;
    font-size: 18px;
}

.loginBlock .wrap{
    overflow:auto;
}

.loginBlock .form{
    grid-template-rows: 1fr repeat(2, auto);
    font-size: 24px;
    gap:16px;
    height: auto;
}
.loginBlock.reset .form, .loginBlock.twoFactor .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.loginBlock .form .fields{
    grid-template-rows: repeat(auto-fill, 64px);
    gap: 8px;
}
.loginBlock .form .fields .field{
    grid-template-columns: 64px 1fr 64px;
}
.loginBlock .form .fields .field :first-child svg{
    width:36px;
    height:36px;
    color: #719085;
}

.loginBlock.login .form .options{
    grid-template-rows: repeat(2, 1fr);
    gap:8px;
}

.passwordReset{
    color:gray;
    font-size: 18px;
}

.passwordReset:hover{
    background-color: #EFEFEF;
    color:dimgray;
    outline:solid 2px gray;
}

.loginBoard input{
    border-radius: 5px;
    color:dimgray;
}

.loginBoard .inputField input:focus{
    outline:solid 2px gray;
    border-color: transparent;
    background-color: white;
}

.goBack{
    grid-template-columns: 48px auto;
    color:gray;
    gap:8px;
    border-bottom:solid 2px transparent;
}

.goBack:hover{
    color:dimgray;
    background-color: whitesmoke;
    border-color: #104735;
}

.loginBlock .field .active svg{
    color:#104735 !important;
}

.loginBlock .btnWIcon{
    border-color:#104735;
    color:#104735;
    grid-template-columns: 24px 1fr 24px;
}

.loginBlock .active.btnWIcon{
    background-color:#104735;
    color:white;
}

.loginBlock .active.btnWIcon:hover{
    background-color: white;
    color:#104735;
}

.loginBlock .header{
    grid-template-columns: 96px 1fr;
    font-size: 24px;
    color:#104735;
}

.lockIcon{
    height:48px;
    width:48px;
    border-radius: 50%;
    outline:solid 2px #104735;
}

.lockIcon svg{
    color:#104735;
    width:36px;
    height:auto;
}

.loginBlock .prompt{
    color:dimgray;
    font-weight: 100;
}

.loginBlock .prompt.alt{
    grid-template-rows: 36px repeat(6, 24px);
}

.loginBlock .prompt.alt > div{
    grid-template-columns: auto auto;
}

.loginBlock .prompt.alt .complete{
    color:#66bb6a;
}
.loginBtn{
    height:48px;
}

.loginBlock.create .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.errorStatus{
    background-color: #ffebee;
    color:#e53935;
    font-weight: 100;
    border-radius: 5px;
}