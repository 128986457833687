.currentActivity.bids{
    grid-template-rows: 1fr;
}

.currentActivity .bidsTable{
    grid-template-rows: auto 1fr;
}

.currentActivity .bidsTable.inactive{
    grid-template-rows: 1fr;
}


.currentActivity .bidsTable .header{
    height: auto;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 2;
    border: solid #dcdcdc 1px;
    box-shadow: 0 0 8px #E2E2E2;
}

.currentActivity .bidsTable .header .data{
    grid-template-columns: repeat(2, 1fr);
}

.currentActivity .bidsTable .header .data .point{
    grid-template-columns: 24px 1fr;
}

.currentActivity .bidsTable .header .data .download.btn{
    width: min-content;
}

.currentActivity .bidsTable .header .actionBar{
    grid-template-columns: repeat(3, 1fr);
}

.currentActivity .bidsTable .header.inactive{
    background-color: #f3f4f6;
    border-color: #E2E2E2;
}

.currentActivity .bidsTable .header.inactive svg{
    color:gray !important;
}

.currentActivity .bidsTable .header .columnHeaders{
    grid-template-columns: repeat(3, 1fr);
}

.currentActivity .bidsTable .header .columnHeaders .column{
    grid-template-columns: 32px 1fr;
}

.reportedBids{
    height: min-content;
}

.reportedBids .table{
    grid-auto-rows: auto;
}

.reportedBids .table .row{
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: 1fr;
    height: auto;
    border: solid #dcdcdc;
    border-width: 1px 1px 2px;
    color: dimgrey;
    background-color: #fff;
}

.reportedBids .table .row.highest{
    border:solid 2px #4caf50;
    background-color: #e8f5e9;
    color:#4caf50;
}

.reportedBids .table .row .bidDetails{
    grid-template-rows: auto auto;
    grid-template-columns: repeat(3, 1fr);
}

.reportedBids .table .row .bidDetails .cell{
    /* border: solid #dcdcdc;
    border-width: 1px 1px 2px;
    color: dimgrey;
    background-color: #fff; */
}

.reportedBids .table .row .prompt{
    grid-template-columns: auto 1fr auto;
}