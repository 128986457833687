/*

.notificationBoard{
    width: 500px;
    height:500px;
    overflow: hidden;
    background-color: white;
    z-index: 999;
    grid-template-rows: 48px 1fr 48px;
}

.notificationBoard .header{
    grid-template-columns: 48px 1fr 48px;
}

*/

.notificationBoard{
    z-index: 999;
    position: absolute;
    text-align: center;
    right:0;
    top:auto;
    width: calc(100% - 90px);
    min-width: calc(100% - 12vh);
    display: inline-block;
    padding: 1.25%;
    height: calc(100vh - 90px);
    min-height: calc(100vh - 12vh);
}
.notificationBoardInner{
    position: relative;
    width: 500px;
    height:50vh;
    max-height: 648px;
    border-radius: 5px;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}
.notificationBoardBlock{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
}
.notificationBoardBlockInner{
    position: relative;
    width:100%;
    height:100%;
    grid-template-rows: 48px calc(100% - 96px) 48px;
    display: grid;
}
.notificationBoardBlockInner .listBar{
    width:85%;
    left:50%;
    top:48px;
    transform: translate(-50%, 0);
}
.notificationBoardBlockInner .listBar.bottom{
    width: 85%;
    left:50%;
    top:auto;
    bottom:48px;
    transform: translate(-50%, 0);
}
.notificationHeader{
    position: sticky;
    height: 48px;
    line-height: 24px;
    text-align: center;
    top: 0;
    z-index: 2;
    background-color: transparent;
}
.notificationHeaderTitle{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color:gray;
}
.notificationDisplayBubble{
    position:absolute;
    left:15px;
    top:50%;
    transform: translate(0,-50%);
    width:40px;
    height:40px;
}
.notificationDisplayBubble svg{
    width:40px;
    height:40px;
    color:#d2ab66;
}
.notificationHeader .closeBtn{
    right:5px;
}

.notificationTimelineWrap{
    width: 100%;
    overflow: auto;
}
.notificationTimeline{
    position: relative;
    background-color:#F3F4F6;
    border:solid 1px gainsboro;
    overflow: auto;
}
.notificationRow{
    width: 97.5%;
    height: 55px;
    border-radius: 5px;
    background-color: white;
    display: inline-block;
    margin-top: 5px;
    line-height: 32px;
    border: solid 1px gainsboro;
    position: relative;
    color:gray;
}
.notificationRow:hover{
    cursor:pointer;
    background-color:#E2E2E2;
    color:gray;
}
.notificationIcon{
    position:absolute;
    left:5px;
    top:50%;
    transform: translate(0, -50%);
    height:24px;
    width:24px;
}
.notificationStatus{
    position:absolute;
    top:50%;
    left:calc(39px);
    transform: translate(0, -50%);
}
.notificationPolicyNumber{
    position:absolute;
    top:5px;
    right:5px;
    transform: translate(0, 0);
    width:auto;
    height:18px;
    line-height:18px;
    text-align: center;
    color:gray;
}
.notificationName{
    position:absolute;
    bottom:5px;
    right:5px;
    transform: translate(0, 0);
    width:auto;
    height:18px;
    line-height:18px;
    text-align: center;
    color:dimgray;
}

.noNotifications{
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: gray;
    font-size: 24px;
    font-weight: 100;
}