.statusDetails{
    height: auto 36px;
}

.statusDetails .topBar{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition{
    grid-template-columns: auto 1fr;
}

.statusDetails .definition .icon{
    width: 64px;
    height: 64px;
}

.statusDetails .definition.Open.Request{
    color: #01579b;
    background-color: #e3f2fd;
    outline:solid 2px #01579b;
}

.statusDetails .definition.Closed.Request{
    color: #43a047;
    background-color: #e8f5e9;
    outline:solid 2px #43a047;
}

.statusDetails .definition.Open.Bid{
    color: #6a1b9a;
    background-color: #f3e5f5;
    outline:solid 2px #6a1b9a;
}

.statusDetails .definition.Denied.Bid, .statusDetails .definition.Denied.Request{
    color: #ef5350;
    outline: solid 2px #ef5350;
    background-color: #ffebee;
}

.statusDetails .definition.Completed.Bid, .statusDetails .definition.Completed.Request{
    color: #43a047;
    background-color: #e8f5e9;
    outline:solid 2px #43a047;
}

.statusDetails .definition .icon svg{
    width: 56px;
    height: 56px;
}

.statusDetails .definition .format{
    grid-template-rows: 32px auto;
}

.statusDetails .definition .format.response{
    grid-template-rows: 32px 24px auto;
}

.statusDetails .definition .format.request{
    grid-template-rows: 32px auto 32px;
}

.statusDetails .definition .format.bid{
    grid-template-rows: 32px 32px auto 32px;
}

.statusDetails .definition .format .header{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition .format .header .name{
    grid-template-columns: auto 1fr;
}

.statusDetails .definition.Closed.Request .format .checkStatus{
    padding:4px 8px;
    color: #e8f5e9;
    background-color:#43a047;
}

.statusDetails .definition.Open.Request .format .checkStatus{
    padding:4px 8px;
    color: #e3f2fd;
    background-color: #01579b;
}

.statusDetails .definition.Bid .offerData{
    grid-template-columns: auto auto;
}

.statusDetails .definition.Bid .offerData .offer{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition.Bid .offerData .offer .dataPoint{
    padding:4px 8px;
}

.statusDetails .definition.Completed.Bid .format .checkStatus, .statusDetails .definition.Bid.Completed .offerData .offer .dataPoint,
.statusDetails .definition.Completed.Request .format .checkStatus, .statusDetails .definition.Request.Completed .offerData .offer .dataPoint{
    padding:4px 8px;
    color: #e8f5e9;
    background-color:#43a047;
}

.statusDetails .definition.Open.Bid .format .checkStatus, .statusDetails .definition.Bid.Open .offerData .offer .dataPoint{
    padding:4px 8px;
    color: #f3e5f5;
    background-color: #6a1b9a;
}

.statusDetails .definition.Denied.Bid .format .checkStatus, .statusDetails .definition.Bid.Denied .offerData .offer .dataPoint,
.statusDetails .definition.Denied.Request .format .checkStatus, .statusDetails .definition.Request.Denied .offerData .offer .dataPoint
{
    padding:4px 8px;
    color: #ffebee;
    background-color: #ef5350;
}

.statusDetails .definition .subHeader{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition .format .header .date{
    grid-template-columns: auto auto;
}

.statusDetails .read.btn, .statusDetails .unread.btn{
    height: 36px;
    border:solid 2px transparent;
}

.statusDetails .unread.btn{
    border-color: #2196f3;
    color:white;
    background-color: #2196f3;
}

.statusDetails .read.btn{
    border-color: #2196f3;
    color:#2196f3;
}

.statusDetails .unread.btn:hover{
    background-color: white;
    color:#2196f3;
}

.statusDetails .read.btn:hover{
    background-color: #e3f2fd;
}