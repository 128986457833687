.messages{
    grid-template-rows: 1fr;
    overflow-x: hidden;
}

.messages.inactive{
    grid-template-rows: 1fr auto;
}

/* .messages .conversation{
    grid-template-rows: 48px auto;
} */
.messages .details{
    position: sticky;
    top:0;
    height: 48px;
    width: 100%;
    background-color:#EFEFEF;
    z-index: 2;
}

.messages .header{
    position:sticky;
    top:8px;
    z-index: 2;
}

.messages .header > div{
    background-color: #f3f4f6;
    border: solid #dcdcdc 1px;
    box-shadow: 0 0 8px #E2E2E2;
}

.messages .transcript{
    grid-template-rows: min-content;
    height: min-content;
    /* overflow-x:hidden; */
}

.messages .actionBar{
    position: sticky;
    bottom:8px;
    grid-template-columns: 1fr 41px;
    height: auto;
    /* background-color: #EFEFEF; */
    background-color: white;
    border:solid 1px gainsboro;
}

.messages .transcript .message{
    height: min-content;
}
.messages .transcript .message:hover{
    z-index: 2;
}
.messages .transcript .message .context{
    display: inline-grid;
    grid-template-rows: auto;
    width: auto;
}

.messages .transcript .message:not(.failed) .context{
    max-width: calc(100% - 120px);
}

.messages .transcript .message .data{
    grid-template-rows: auto auto;
}

.messages .transcript .message .data .timeStamp{
    /*  */
}

.messages .transcript .message.internal .data .timeStamp{
    animation: showTimeStamp-internal ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    position:relative;
}

.messages .transcript .message.failed{
    grid-template-columns: auto auto;
}

.messages .transcript .message.external .data .timeStamp{
    animation: showTimeStamp-external ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    position:relative;
}

.messages .transcript .message .content{
    text-align: left;
}

.messages .transcript .message.internal .content{
    background-color: white;
    color:dimgray;
    border:solid 1px #EFEFEF;
}

.messages .transcript .message.external .content{
    background-color: #104735;
    color:white;
}

.messages .transcript .message.external.sending .content{
    animation: sendingMessage 3s ease-in-out infinite;
}

.messages .transcript .message.external.failed .content{
    background-color: #ffebee;
    color: #f44336;
    border:solid 1px #ef9a9a;
}

.messages .transcript .message .error{
    display: inline-flex
}

.messages .transcript .message .retry{
    grid-template-columns: auto 36px;
    height: 36px;
    color:#42a5f5;
    width: max-content;
}

.messages .transcript .message .retry:hover{
    color:#1769aa;
}

.sendingMessage{
    letter-spacing: inherit;
    border:none;
    border: solid 2px transparent;
    border-bottom: solid 2px gainsboro;
    font-size: 16px;
    height: auto;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    display: block;
    animation-duration: 10ms;
    resize: none;
    font-family: inherit;
    /* border-radius: 5px; */
    min-height: fit-content;
    max-height: '120px';
}

.sendingMessage:focus, .sendingMessage:active{
    /* outline: solid 2px #104735; */
    outline:none;
    border: solid 2px #104735;
    border-bottom: solid 2px #104735;
    border-radius: 5px;
    /* border-color: transparent; */
}

.messages .actionBar .send.btn{
    aspect-ratio: 1;
    height: 41px;
    width: 41px;
    border-radius: 50%;
}

.messages .actionBar.eligible .send.btn{
    background-color: #104735;
    color:white;
}

.messages .actionBar.eligible .send.btn:hover{
    background-color: white;
    color:#104735;
    outline:solid 2px #104735;
}

@keyframes showTimeStamp-external{
    0% {
        right:-100%;
    }
    100% {
        right:0px;
    }
}

@keyframes showTimeStamp-internal{
    0% {
        left:-100%;
    }
    100% {
        left:0px;
    }
}

@keyframes sendingMessage {
    0% {
        background-color: #E2E2E2;
        border:solid 1px gray;
        color:gray;
    }
    50% {
        background-color: #EFEFEF;
        border:solid 2px #E2E2E2;
        color:#E2E2E2;
    }
    100% {
        background-color: #E2E2E2;
        border:solid 1px gray;
        color:gray;
    }
}
