.dashboard{

}

.dashboardHub{
    grid-template-columns: 72px 1fr;
    background-color: white;
}

.menuBar{
    z-index: 99999999;
    background-color: white;
    border-right: solid 2px gainsboro;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 8px #E2E2E2;
    grid-auto-rows: 48px;
    grid-template-rows: 1fr 48px;
}

.menuBar .menu{
    grid-auto-rows: 48px;
}
.menuBar:not(.default) .menu{
    grid-template-rows: 48px 1fr;
}

.menuBar.expanded{
    width: 300px;
}

.menuBar .option svg, .menuBar .actionBar svg{
    width: 28px;
    height: 28px;
}

.menuBar .slideMenuBar{
    grid-template-columns: 64px 1fr 64px;
}

.menuBar .option{
    grid-template-columns: 64px 1fr;
    border:solid 2px transparent;
}

.menuBar .option .hoverBlock{
    position:absolute;
    width: calc(100% - 16px);
    height: 48px;
    border:solid 2px transparent;
}

.menuBar .option:hover .hoverBlock{
    border-color:#E2E2E2;
}

.menuBar .actionBar{
    grid-template-columns: 48px 1fr;
}