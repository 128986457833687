.lineGraph{
    grid-template-rows: 56px 1fr;
}

.lineGraph .actionBar{
    grid-template-columns: repeat(3, 1fr);
    height: 56px;
}

.chartBubble{
    position: absolute;
    border: solid 1px #DCDCDC;
    background-color: white;
    box-shadow: 0 0 8px #E2E2E2;
    width: max-content;
}

.chartBubble .point{
    grid-template-columns: auto 1fr;
}

.chartBubble .point.date svg{
    color: #607d8b;
}

.chartBubble .point.currency svg{
    color: #4caf50;
}