.kanbanBoard{
    border-radius: 5px;
    overflow: auto;
    background-color: inherit;
    grid-template-rows: repeat(2, 36px) 1fr;
    gap:8px;
  }
  
  .kanbanChart{
    display: inline-grid;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    text-align: left;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    overflow: hidden;
  }

  .headerBar{
    height: 36px;
    line-height: 36px;
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    gap:8px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: inherit;
  }
  .headerBar > div{
    display:inline-grid;
    font-weight: 500;
    position: relative;
    grid-template-columns: 84px 1fr 84px;
  }
  .headerBar > :nth-child(1){
    color:#1b5e20;
  }
  .headerBar > :nth-child(2){
    color: #01579b;
  }
  .headerBar > :nth-child(3){
    color: #6a1b9a;
  }
  .headerBar > :nth-child(4){
    color:gray;
  }
  
  .categoryBar{
    width: 100%;
    height: 100%;
    display: inline-grid;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 2;
    background-color: inherit;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
  .categoryBar .columnAttr{
    grid-template-columns: 1fr auto;
    gap: 8px;
  }
  .categoryBar .total.db div{
    background-color: #e8f5e9;
    color:#1b5e20;
  }
  .categoryBar .total.bids div{
    background-color: #f3e5f5;
    color:#6a1b9a;
  }

  .kanbanBlock .blockAttr{
    grid-template-columns: 1fr auto;
    gap:8px;
  }
  .kanbanBlock .blockAttr div{
    grid-template-columns: 24px 1fr;
    gap:8px;
  }
  .kanbanBlock .blockAttr div:last-of-type{
    grid-template-columns: 24px auto;
    gap:8px;
  }


  .kanbanChart .column{
    padding: 16px 0;
    gap:16px;
    height: fit-content;
    width: 100%;
    display: inline-grid;
    border-radius: 5px;
    overflow: overlay;
    overflow-x: hidden;
    max-height: 100%;
    border:dashed 2px gray;
  }

  .column.alt{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  .kanbanChart .column.alt .kanban{
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
    width: calc(100% - 16px);
  }

  /* .kanbanChart .column:nth-of-type(1) .kanbanBlock{
    border: solid 2px #2e7d32;
  }
  .kanbanChart .column:nth-of-type(1) .kanbanBlock:hover{
    background-color: #e8f5e9;
  }

  .kanbanChart .column:nth-of-type(2) .kanbanBlock{
    border: solid 2px #0277bd;
  }
  .kanbanChart .column:nth-of-type(2) .kanbanBlock:hover{
    background-color: #e3f2fd;
  }

  .kanbanChart .column:nth-of-type(3) .kanbanBlock{
    border: solid 2px #6a1b9a;
  }
  .kanbanChart .column:nth-of-type(3) .kanbanBlock:hover{
    background-color: #f3e5f5;
  } */

  .kanbanChart .column.stage1{
    border-color: #c8e6c9;
  }
  .kanbanChart .column.stage2{
    border-color: #b3e5fc;
  }
  .kanbanChart .column.stage3{
    border-color: #f3e5f5;
  }
  .kanbanChart .column.closed{
    border-color: #e2e2e2;
    border-style: solid;
  }

  .categoryBar > div{
    height: 100%;
    width: 100%;
    border-radius: 5px;
    display: inline-grid;
    font-weight: 500;
    position: relative;
    grid-template-columns: 1fr repeat(2, auto);
    text-overflow: ellipsis;
    white-space: nowrap;
    border: solid 2px gray;
    color:gray;
  }

  .categoryBar > div.stage1{
    border-color:#1b5e20;
  }
  .categoryBar > div.stage2{
    border-color: #01579b;
  }
  .categoryBar > div.stage3{
    border-color: #6a1b9a;
  }
  
  .kanbanNotifications{
    text-align: left;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  
  .kanban{
    gap:8px;
    width: 100%;
    display: inline-grid;
    grid-template-columns: calc(100% - 16px);
    grid-template-rows: 36px 1fr;
  }
  .kanban.noTopBar{
    grid-template-rows: 1fr;
  }
  .column > .kanban{
    transition: 0s;
  }
  .column.closed > .kanban{
    grid-template-columns: calc(100% - 32px);
  }

  .listAllResults.alt{
    padding-right:8px;
  }
  .listAllResults .kanban{
    grid-template-columns: 100%;
  }

  .kanban:hover .action.topBar{
    border-color: #e2e2e2;
  }
  .kanban .action.topBar > :nth-child(1):hover{
    background-color: #ffebee;
    color: #ef5350;
  }
  .kanban .action.topBar > :nth-child(2):hover{
    background-color: #e3f2fd;
    color:#0277bd;
  }
  .kanban .action.topBar > :nth-child(3):hover{
    background-color: #f3e5f5;
    color:#6a1b9a;
  }

  .kanban .spaceHolder.topBar{
    display: grid;
    overflow: hidden;
    font-weight: 500;
    border: solid 2px #E2E2E2;
    color:#E2E2E2;
    gap:8px;
    border-radius: 5px;
  }
  .kanban .spaceHolder.topBar.accepted{
    border: 2px solid #43a047;
    color: #43a047;
    background-color: #e8f5e9;
  }
  .kanban .action.topBar{
    display: grid;
    border-radius: 5px;
    grid-template-columns: repeat(3, 1fr);
    color: gray;
    font-weight: 500;
    overflow: hidden;
    border: solid 2px transparent;
    gap:8px;
  }
  
  .kanban .alert.topBar{
    display: grid;
    border-radius: 5px;
    grid-template-columns: repeat(2, 1fr);
    color: gray;
    font-weight: 500;
    overflow: hidden;
    gap:8px;
  }
  .kanban .alert.topBar.unread{
    grid-template-columns: 36px repeat(2, 1fr);
  }

  .bidsPage .topBar .data{
    grid-template-columns: 1fr auto;
  }

  .bidsPage .topBar .data .split{
    grid-template-columns: 36px 1fr;
  }
  .bidsPage .topBar .data .cover{
    gap:8px;
  }

  .bidsPage .topBar .data .inactive:not(.cover){
    border: solid 2px #E2E2E2 !important;
    color:#E2E2E2 !important;
  }

  .bidsPage .topBar .data .cover.request{
    background-color: #ef5350;
    color:white;
  }
  .bidsPage .topBar .data .open.request{
    border: solid 2px #ef5350;
    color:#ef5350;
    box-sizing: border-box;
  }

  .bidsPage .topBar .data .cover.bid{
    background-color: #6a1b9a;
    color:white;
  }
  .bidsPage .topBar .data .open.bid{
    border: solid 2px #6a1b9a;
    color:#6a1b9a;
    box-sizing: border-box;
  }
  .bidsPage .topBar .data .open.bid.accepted{
    border: 2px solid #43a047;
    color: #43a047;
    background-color: #e8f5e9;
  }

  .kanbanBlock{
    width: 100%;
    background: white;
    border-radius: 5px;
    margin: auto;
    vertical-align: middle;
    border: solid 1px gainsboro;
    position: relative;
    padding: 8px;
    font-weight: 500;
    text-align: center;
    color: dimgray;
    grid-template-rows: 24px 8px repeat(7, 24px);
    grid-template-columns: 100%;
    gap: 4px;
  }

  .closed .kanbanBlock{
    grid-template-rows: repeat(2, 24px);
  }

  .leadsPage .kanbanBlock{
    grid-template-rows: 24px 8px repeat(5, 24px);
  }

  .kanbanBlock .data{
    grid-template-columns: 24px 1fr;
    gap:8px;
  }
  .kanbanBlock .data input{
    background-color: inherit;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    font-weight: 500;
  }
  
  .kanbanBlock .header{
    grid-template-columns: 24px repeat(2, 1fr);
    gap: 8px;
  }
  
  .kanbanListAll{
    border-radius: 5px;
    background-color: #F3F4F6;
    text-align: left;
    overflow: auto;
    border: solid 1px gainsboro;
    overflow-x: hidden;
    grid-template-columns: 300px 1fr;
    gap: 8px;
  }

  .listAllResults.block{
    overflow-y: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 16px;
  }

  .listAllResults.list{
    overflow-y: auto;
    grid-template-columns: 1fr;
    gap:8px;
    padding-right:8px;
    grid-auto-rows: min-content;
  }

  .listAllResults.medium{
    grid-template-columns: repeat(2, 1fr);
  }

  .listAllResults.mobile{
    grid-template-columns: repeat(1, 1fr);
  }

  .listAllToggles{
    width: 100%;
    height: 100%;
    background-color: white;
    outline: solid 1px gainsboro;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    color: gray;
    z-index: 2;
    overflow-y: hidden;
    grid-template-rows: 48px 1fr 48px;
  }

  .toggleOptions .options{
    overflow:auto;
    text-align: left;
    gap:16px;
  }

  .toggleOptions .options .category{
    height: auto;
    grid-template-columns: 100%;
    gap:8px;
  }

  .toggleOptions .options .category *{
    grid-template-rows: 36px;
  }

  .toggleOptions .options .category.inactive *,.toggleOptions .options .category .inactive *{
    color:#E2E2E2;
  }

  .toggleOptions .options .category.inactive .divider{
    background-color:#E2E2E2 !important;
  }

  .toggleOptions .options .category:nth-child(1) .header{
    color:#00BFFF;
  }
  .toggleOptions .options .category:nth-child(2) .header, .toggleOptions .options .category:nth-child(2) svg{
    color:#2e7d32;
  }
  .toggleOptions .options .category:nth-child(3) .header, .toggleOptions .options .category:nth-child(3) svg{
    color:#0277bd;
  }
  .toggleOptions .options .category:nth-child(4) .header, .toggleOptions .options .category:nth-child(4) svg{
    color:#6a1b9a;
  }
  .toggleOptions .options .category:nth-child(5) .header, .toggleOptions .options .category:nth-child(5) svg{
    color:#ef5350;
  }



  .toggleOptions .options .category .header{
    display: grid;
    grid-template-columns: 36px 1fr 36px;
  }

  .toggleOptions .options .category .header .small{
    line-height: 12px;
    font-size: 12px;
  }

  .toggleOptions .options .categoryGroup{
    gap:8px;
  }
  .toggleOptions .options .subCategory{
    display: grid;
    grid-template-columns: 36px 1fr 72px;
    cursor:pointer;
  }

  .toggleOptions .options .subCategory .count{
    font-size: 12px;
  }

  .toggleOptions .options .category:nth-of-type(1) .divider{
    background-color: #00BFFF;
  }

  .listAllToggles .bottomBar{
    grid-template-columns: repeat(2, 1fr);
  }

  .kanban .accountName{
    color:dimgray;
  }

  .kanbanBlock.stage1, .kanbanRow.stage1{
    border: solid 2px #2e7d32;
  }
  .kanbanBlock.stage1 .divider, .kanbanRow.stage1 .divider{
    border: solid 1px #2e7d32;
  }
  .kanbanBlock.stage1:hover, .kanbanRow.stage1:hover{
    background-color: #e8f5e9;
  }

  .kanbanBlock.stage2,  .kanbanRow.stage2{
    border: solid 2px #0277bd;
  }
  .kanbanBlock.stage2 .divider,  .kanbanRow.stage2 .divider{
    border: solid 1px #0277bd;
  }
  .kanbanBlock.stage2:hover,   .kanbanRow.stage2:hover{
    background-color: #e3f2fd;
  }

  .kanbanBlock.stage3, .kanbanRow.stage3{
    border: solid 2px #6a1b9a;
  }
  .kanbanBlock.stage3 .divider,  .kanbanRow.stage3 .divider{
    border: solid 1px #6a1b9a;
  }
  .kanbanBlock.stage3:hover,  .kanbanRow.stage3:hover{
    background-color: #f3e5f5;
  }

  .kanbanBlock.closing, .kanbanRow.closing{
    border: solid 2px #ff9800;
  }
  .kanbanBlock.closing:not(.result),  .kanbanRow.closing:not(.result){
    background-color: #fff3e0;
    color:#ff9800;
  }
  .kanbanBlock.closing .divider,  .kanbanRow.closing .divider{
    border: solid 1px #ff9800;
  }
  .kanbanBlock.closing:hover,  .kanbanRow.closing:hover{
    background-color: #fff3e0;
  }

  .kanbanBlock.won,  .kanbanRow.won{
    border: solid 2px #004d40;
  }
  .kanbanBlock.won:not(.result),  .kanbanRow.won:not(.result){
    background-color: #e0f2f1;
    color:#004d40;
  }
  .kanbanBlock.won .divider,  .kanbanRow.won .divider{
    border: solid 1px #004d40;
  }
  .kanbanBlock.won:hover,  .kanbanRow.won:hover{
    background-color: #e0f2f1;
  }
  
  .kanbanBlock.closed, .kanbanRow.closed{
    border: solid 2px #ef5350;
  }
  .kanbanBlock.closed:not(.result), .kanbanRow.closed:not(.result){
    background-color: #ffebee;
    color:#ef5350;
  }
  .kanbanBlock.closed:not(.result) .subGroup,   .kanbanRow.closed:not(.result) .subGroup{
    color:#ef5350;
  }
  .kanbanBlock.closed .divider, .kanbanBlock.closed .vDivider,   .kanbanRow.closed .divider, .kanbanRow.closed .vDivider{
    background-color: #ef5350;
  }
  .kanbanBlock.closed:hover,   .kanbanRow.closed:hover{
    background-color: #ffebee;
  }
  .column.closed .kanbanBlock:hover, .column.closed .kanbanBlock:hover .subGroup{
    background-color: white;
    color:dimgray;
    border-color: gray;
  }
  .column.closed .kanbanBlock:hover .divider, .column.closed .kanbanBlock:hover .vDivider{
    background-color: dimgray !important;
  }

  .kanbanBlock .subGroups{
    grid-template-columns: 16px 1fr 16px;
    gap:8px;
    color:inherit;
  }

  

.kanbanBlock .container{
    gap: 8px;
}

.kanbanBlock .subGroup svg{
    color:#ef5350 !important;
}
.kanbanBlock:hover .subGroup svg{
  color:dimgray !important;
}

.kanbanBlock .subGroup{
    grid-template-columns: 24px 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap:8px;
    /* border: 2px solid #ef5350; */
    color:#ef5350;
}

.kanbanBlock .subGroup svg{
    color:gray;
}

.kanbanBlock .subGroup{
    color:gray;
}

.stage1 .emptyKanbanMessage{
  color: #1b5e20;
}
.stage2 .emptyKanbanMessage{
  color: #01579b;
}
.stage3 .emptyKanbanMessage{
  color: #6a1b9a;
}

.kanbanPage > .actionBar {
  grid-template-columns: minmax(auto, 300px) 1fr minmax(auto, 300px);
  gap: 8px;
}

.kanbanPage > .actionBar .navOptions, .kanbanPage > .actionBar .filterOptions{
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  /* max-width: 350px; */
}

.kanbanPage .searchBar{
  grid-template-columns: minmax(112px, auto) 1fr;
  gap: 8px;
  position: relative;
}

.filterBubbles{
  position: absolute;
  right: 4px;
  width: auto;
  max-width: calc(100% - 128px);
  display: grid;
  gap:4px;
}
.filterBubbles.count1{
  grid-template-columns: auto;
}
.filterBubbles.count2{
  grid-template-columns: repeat(2, auto);
}
.filterBubbles.count3{
  grid-template-columns: repeat(3, auto);
}
.filterBubbles.count4{
  grid-template-columns: repeat(4, auto);
}
.filterBubbles.count5{
  grid-template-columns: repeat(5, auto);
}
.filterBubbles.count6{
  grid-template-columns: repeat(6, auto);
}
.filterBubbles.count7{
  grid-template-columns: repeat(7, auto);
}
.filterBubbles.count8{
  grid-template-columns: repeat(8, auto);
}

.filterBubbles .filter{
  display: inline-grid;
  background-color: #00BFFF;
  font-weight: 500;
  color:white;
  border-radius: 5px;
  grid-template-columns: auto 16px;
  gap: 8px;
  padding:0 4px;
}



.miniKanban{
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.miniKanban .catInfo{
  grid-template-columns: 48px 1fr 48px;
  border-radius: 5px;
}

.catInfo.stage1{
  border:solid 2px #1b5e20;
  color: #1b5e20;
}

.catInfo.stage2{
  border:solid 2px #01579b;
  color: #01579b;
}

.catInfo.stage3{
  border:solid 2px #6a1b9a;
  color: #6a1b9a;
}

.filterSection .list .option{
  height: 48px;
  width: 100%;
  grid-template-columns: 1fr 72px 36px;
  border: 2px solid #E2E2E2;
  background-color: white;
  color: gray;
}

.filterSection .list .option.on:hover{
  background-color: #E2E2E2;
  color: dimgray;
}
.filterSection .list .option:hover svg{
  color: gray;
}

.filterSection .list .option.off{
  background-color: whitesmoke;
  color: dimgray;
}

.filterSection .list .option.off:hover{
  background-color: transparent;
  color: dimgray;
}

.kanbanRow{
  width: 100%;
  background: white;
  margin: auto;
  vertical-align: middle;
  border: solid 1px gainsboro;
  position: relative;
  padding: 8px;
  font-weight: 500;
  text-align: center;
  color: dimgray;
}

.kanbanRow .header{
  grid-template-columns: auto auto 1fr min-content
}

.kanbanRow .profile{
  grid-template-columns: repeat(4, 1fr);
}

.kanbanRow .profile .data{
  grid-template-columns: auto min-content;
}

.kanbanRow .details{
  grid-template-columns: repeat(2, 1fr);
}

.kanbanRow .details .assignmenet{
  grid-template-columns: auto auto;
}