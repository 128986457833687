.currentActivity .timelineWrap{
    grid-template-rows: min-content auto;
    background-color: #F3F4F6;
    border-radius: 5px;
    height: auto;
    width: 100%;
    min-height: 100%;
}

.currentActivity .timelineWrap.inactive{
    background-color: transparent;
}


.currentActivity .timelineWrap.empty{
    grid-template-rows: 1fr;
}

.currentActivity .timelineWrap > .header{
    background-color: white;
    grid-template-rows: 24px 36px;
    border: solid 1px gainsboro;
}

.currentActivity .timelineWrap > .header.alt{
    grid-template-rows: 24px 36px auto;
}

.currentActivity .timelineWrap > .header .topbar{
    grid-template-columns: repeat(3, 1fr);
}