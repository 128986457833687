
.chartBlock{
  height:100%;
  grid-template-rows:36px 1fr;
}

.lineChartContainer{
  height: auto;
  background-color: inherit;
  width: 100%;
  border-radius: 5px;
  min-height: calc(50% - 4px);
  overflow: hidden;
  max-height:100%;
  padding-bottom:36px;
}

.lineChart {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 145px);
  max-width: 100%;
}

.lineChartContainer .loading{
  height:100%;
  width:100%;
}
.graphHeaderBar{
  height:36px;
  position:relative;
}
.graphHeader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
}